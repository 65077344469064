import * as request from 'superagent';
import { IMessageModel } from '../../Models';

export const api = {
    getMessages: () => (
        request
            .get('/api/messagecenter/messages')
    ),
    readMessage: (message: IMessageModel) => (
        request
            .post('/api/messagecenter/messagesread')
            .send([message])
    ) 
}