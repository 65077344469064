import * as React from 'react'
import { Col, Row } from 'react-bootstrap'; 
import * as _ from 'lodash';
import { FormattedMessage, injectIntl, InjectedIntlProps, defineMessages } from "react-intl";
import { Link } from 'react-router-dom';

import { isBushnell } from '../../constants';

interface ITCPageFooterProps {}

interface ITCPageFooterState {
    footerHeight: string
}

const appStrings = defineMessages({
    text: {
        id: "TCPageFooter.text.label",
        defaultMessage: "text"
    }
});

const defaultMessage = isBushnell
    ? '© Bushnell Outdoor Products. All Rights Reserved.'
    : '© Primos Hunting. All Rights Reserved.'

class TCPageFooter extends React.Component<ITCPageFooterProps & InjectedIntlProps, ITCPageFooterState> {
    private resizeListener: EventListener & _.Cancelable;

    constructor(props: InjectedIntlProps) {
        super(props);

        this.state = {
            footerHeight: '96px'
        }

        this.resizeListener = _.throttle(this.updateDimensions, 200);
    }

    componentWillMount() {
        this.updateDimensions();
    }

    componentDidMount() {
        window.addEventListener("resize", this.resizeListener);
    }

    componentWillUnmount() {
        this.resizeListener.cancel();
        window.removeEventListener("resize", this.resizeListener);
    }

    updateState(values: Object) {
        var newState = _.assign(this.state, values);
        this.setState(newState);
    }

    updateDimensions = () => {
        if (typeof window === 'undefined') {
            // Skip if we are rendering on the server
            return;
        }

        if (window.innerWidth <= 400) {
            if (this.state.footerHeight != '80x') {
                this.updateState({footerHeight: '80px' });
            }
        } else if (this.state.footerHeight != '50px') {
            this.updateState({ footerHeight: '50px' });
        }
    }

    render(): JSX.Element {
		const {formatMessage} = this.props.intl;
        return (
            <div className='footer' style={{backgroundColor: isBushnell ? '#6B625E' : "#62BB46"}} >
                <Col className='footer-logo' sm={6} xs={12} style={{marginTop: '5px', marginBottom: 'auto'}}>
                    {isBushnell && <img src='/images/bushnell-round-gray.png' />}
                    <span>{defaultMessage}</span>
                </Col>
                <Col className='footer-links' sm={6} xs={12} style={{marginBottom: 'auto', paddingLeft: 0}} >
                    <a href='https://www.bushnell.com/bu-product-faq-wireless-trail-cameras.html'><FormattedMessage id='TCAppBar.faq.label' defaultMessage='FAQ' /></a>
                    <a href='https://www.bushnell.com/bushnell-footer-assets/bu-contact-us.html'><FormattedMessage id='TCAppBar.support.label' defaultMessage='Support' /></a>
                    <a href='https://vistaoutdoor.com/privacy-policy/'><FormattedMessage id='TCAppBar.privacy.label' defaultMessage='Privacy Policy' /></a>
                    <a href='https://s3.amazonaws.com/tc-support-docs/WIRELESS_TROPHY_CAM_EULA.pdf'><FormattedMessage id='TCAppBar.eula.label' defaultMessage='EULA' /></a>
                    <a href='https://s3.amazonaws.com/tc-support-docs/BUSHNELL_DATA_PLAN.pdf'><FormattedMessage id='TCAppBar.subscriptions.label' defaultMessage='Subscription Plans' /></a>
                </Col>
            </div>
        );
    }
}

export default injectIntl(TCPageFooter);