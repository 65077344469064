import { PayloadAction } from '../../../Types';
import * as update from 'immutability-helper';

export interface IUIState {
    loading: {
        background: boolean,
        button: boolean,
    }
    modal: {
        loading?: boolean;
        title: string;
        message: string;
        onConfirm: (x?: any) => void;
    };
    showModal: boolean;
    showCCVModal: boolean;
    showLegalModal: boolean;
}

const initialState: IUIState = {
    showModal: false,
    showCCVModal: false,
    showLegalModal: false,
    modal: {
        loading: false,
        title: '',
        message: '',
        onConfirm: () => {}
    },
    loading: {
        background: false,
        button: false,
    },
    
}

export const Actions = {
    SET_MODAL_CONTENT: 'ui/SET_MODAL_CONTENT',
    TOGGLE_BACKGROUND_LOADING: 'ui/TOGGLE_BACKGROUND_LOADING',
    TOGGLE_CCV_MODAL: 'ui/TOGGLE_CCV_MODAL',
    TOGGLE_LEGAL_MODAL: 'ui/TOGGLE_LEGAL_MODAL',
    TOGGLE_LOADING: 'ui/TOGGLE_LOADING',
    TOGGLE_MODAL: 'ui/TOGGLE_MODAL',
    TOGGLE_MODAL_LOADING: 'ui/TOGGLE_MODAL_LOADING',
}

export const setModalContent = ({title, message, onConfirm}: IUIState["modal"]) => ({type: Actions.SET_MODAL_CONTENT, payload: {title, message, onConfirm}})
export const toggleBackgroundLoading = () => ({type: Actions.TOGGLE_LOADING});
export const toggleCCVModal = () => ({ type: Actions.TOGGLE_CCV_MODAL });
export const toggleLoading = ( types: Array<'button' | 'background'> ) => ({type: Actions.TOGGLE_LOADING, payload: types});
export const toggleModal = () => ({type: Actions.TOGGLE_MODAL});
export const toggleModalLoading = () => ({type: Actions.TOGGLE_MODAL_LOADING}); 

export default (state: IUIState=initialState, {type, payload}: PayloadAction<any>) => {
    switch(type) {
        case Actions.SET_MODAL_CONTENT:
            return update(state, {
                modal: {
                    title: {$set: payload.title},
                    message: {$set: payload.message},
                    onConfirm: {$set: payload.onConfirm}
                }
            });
        
        case Actions.TOGGLE_CCV_MODAL:
            return update(state, { $toggle: ['showCCVModal'] });
        case Actions.TOGGLE_LEGAL_MODAL:
            return update(state, { $toggle: ['showLegalModal'] });
        case Actions.TOGGLE_LOADING:
            return update(state, {loading: {$toggle: payload}});
        case Actions.TOGGLE_MODAL:
            return update(state, {$toggle: ['showModal']});
        case Actions.TOGGLE_MODAL_LOADING:
            return update (state, {modal: {$toggle: ['loading']}})
        default:
            return state;
    }
}