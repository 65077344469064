import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Overlay, Popover } from 'react-bootstrap';

import { ShareFacebook } from './index';

interface TCShareBarProps {
    url: string
    isPicture: boolean
}

interface TCShareBarState {
    show: boolean
    all: boolean
    facebook: boolean,
    instagram: boolean,
    twitter: boolean,
}

const initialState = {
    show: false,
    all: true,
    facebook: false,
    instagram: false,
    twitter: false,
}

export class TCShareBar extends React.Component<TCShareBarProps, TCShareBarState> {
    target: HTMLImageElement;
    constructor(props: TCShareBarProps) {
        super(props);

        this.state = initialState;
    }

    private onShare(type: string) {
        this.setState({...this.state,
            all: false, 
            [type]: true,
        })
    }

    private clearState() {
        this.setState(initialState)
    }
    
    render() {
        const { isPicture, url } = this.props;
        const { all, facebook, instagram, twitter } = this.state;
        return (
            <div style={{display: 'inline-block', position: 'relative'}}>
                <img 
                    onClick={() => this.setState({...this.state, show: true})}
                    ref={ref => this.target = ref} 
                    className='clickable' 
                    src='/images/share.svg' 
                    width='51px' 
                    height='50px' />
                <Overlay 
                    rootClose 
                    placement='bottom' 
                    container={this}
                    show={this.state.show} 
                    onHide={() => this.setState({...this.state, show: false})}
                    onExited={() => this.clearState()}
                    target={() => ReactDOM.findDOMNode(this.target)}>
                    <div className='popup-dialog'>
                        {/* {facebook &&
                            } */}
                            <div className='share-bar'>
                                {(all || facebook) && 
                                    <ShareFacebook
                                        isPicture={isPicture} 
                                        selected={facebook}
                                        url={url} 
                                        clearState={this.clearState.bind(this)}
                                        onShare={() => this.onShare('facebook')}/>}
                            </div>
                    </div>
                </Overlay>
            </div>
        )
    }
}