import * as React from 'react';
import { withRouter, Link, RouteComponentProps } from 'react-router-dom';
import { Col, Glyphicon, Row } from 'react-bootstrap';
import { Form } from 'react-form';
import { defineMessages, injectIntl, InjectedIntlProps } from 'react-intl'
import { toast } from 'react-toastify';
import { connect, Dispatch } from 'react-redux';
import * as request from 'superagent';

import { TextInput, TChr } from '../Elements/';
import { State } from '../../../Types';
import { error } from '../../reducers/sagas';


const appStrings = defineMessages({
    password: {
        id: "TCResetPassword.password.label",
        defaultMessage: "Password"
    },
    confirmPassword: {
        id: "TCResetPassword.confirmPassword.label",
        defaultMessage: "Confirm Password"
    },
    passwordError: {
        id: "TCResetPassword.password.error",
        defaultMessage: "A password of 6 or more characters is required"
    },
    passwordRequired: {
        id: 'TCResetPassword.passwordRequired.error',
        defaultMessage: 'Password is required'
    },
    confirmPasswordError: {
        id: "TCResetPassword.confirmPassword.error",
        defaultMessage: "Passwords do not match"
    },
}) 

type ITCResetPassword = In & Out & RouteComponentProps<{token: string}> & InjectedIntlProps;

class TCResetPassword extends React.Component<ITCResetPassword, any> {
    validate = (values: any) => {
        const { formatMessage } = this.props.intl;
        const { password, confirmPassword } = values;
        return {
            password: password 
                ? password.length < 6 ? formatMessage(appStrings.passwordError) : null
                : formatMessage(appStrings.passwordRequired),
            confirmPassword: confirmPassword 
                ? !(password === confirmPassword) ? formatMessage(appStrings.confirmPasswordError) : null
                : formatMessage(appStrings.passwordRequired),
        }
    }
    submit = async ({ password }: any) => {
        const { token } = this.props.match.params
        const body = { password, token };
        try {
            await request
                .post('/api/account/passwordtokenreset')
                .send(body)

            this.props.history.push('/Login');
            toast('Password successfully reset');
        }
        catch (err) {
            this.props.dError(err);
        }
    }
    render() {
        const { formatMessage } = this.props.intl;
        return (
            <div className='container page-container'>
                <Row>
                    <Col xs={12} style={{marginBottom: '10px'}}>
                        <div className='textHeader'>Reset Password</div>
                        <TChr />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} >
                        <div className='box'>
                            <Form
                                onSubmit={this.submit} 
                                validate={this.validate}>
                                {(formApi: any) => {
                                    return <form>
                                        <TextInput field='password' label={formatMessage(appStrings.password)} type='password' />
                                        <TextInput field='confirmPassword' label={formatMessage(appStrings.confirmPassword)} type='password' />
                                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <Link to='/Login' className='whiteButton' style={{display: 'flex', flexWrap: 'nowrap', flex: 1, justifyContent: 'center'}}>
                                                <Glyphicon glyph='arrow-left' style={{marginRight: '10px'}} />
                                                <div style={{display: 'inline', whiteSpace: 'nowrap'}}>Go back to login</div>
                                            </Link>
                                            <div className='orangeButton' onClick={formApi.submitForm} style={{flex: 1}}>Submit</div>
                                        </div>
                                    </form>
                                }}
                            </Form>
                        </div>
                   </Col>
                </Row>
            </div>
        )
    }
}

interface In {}

const stateToProps = (state: State):In => ({});

interface Out {
    dError: (err: any) => void;
}

const dToProps = (d: Dispatch<State>): Out => ({
    dError: e => d(error(e))
})

export default connect(stateToProps, dToProps)(withRouter(injectIntl(TCResetPassword)));