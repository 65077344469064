import * as _ from 'lodash';
import { all, call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import * as update from 'immutability-helper';
import { push } from 'react-router-redux';
import { find, get, has, includes, set } from 'lodash';
import { toast } from 'react-toastify';
import * as moment from 'moment';
import { UnregisterCallback } from 'history';
import * as request from 'superagent';
import { PayloadAction } from '../../../Types';
import { State } from '../index';
import billing, { 
    Actions, 
    activateFirstFreePlan, 
    api, 
    BillingState, 
    getDefaultBilling,
    setCameraUpgradeNow,
    setCartID,
    setCurrentAutoOrder, 
    setCustomerProfile,
    setDefaultBilling,
    setItems, 
    setModifiedAutoOrder, 
    setOrders,  
    setPhotoPack,
    setCouponCode,
} from './index';
import { Cart, IAutoOrderModel, IAutoOrderItemModel, ICameraModel, CartBilling, CartPaymentCreditCard, IItem, ICameraDataPlan, IDataPlanModel, IAutoOrderItemBillingModel, CustomerBilling, CameraHardwareType } from '../../Models';
import { toggleCCVModal, toggleLoading } from '../ui';
import { getCameras } from '../cameras';
import { dataPlans } from '../../Components/Checkout/TCManageAutoOrder/plans';
import { getCameraDataPlans, selectCurrentAutoOrder } from './selectors';
import { error } from '../sagas';

const billingStateSelector = (state: State): BillingState => state.billing;
const cameraSelector = (state: State): ICameraModel[] => state.cameras.cameras
const cartIDSelector = (state: State): string => state.billing.cartID;
const currentAutoOrderSelector = (state: State): IAutoOrderModel => state.billing.currentAutoOrder;
const modifiedAutoOrderSelector = (state: State): IAutoOrderModel => state.billing.modifiedAutoOrder;
const itemSelector = (state: State): IItem[] => state.billing.items
const upgradeCamerasSelector = (state: State): number[] => state.billing.cameraUpgradeNow;


// Worker saga will fetch AutoOrder
function* fetchAutoOrderAsync() {
    try {
        const response = yield request.get('/api/Billing/AutoOrder/') 
        const autoOrderResponse = response.body;

        if (autoOrderResponse) {
            // Modify any legacy item ids to new item ids
            for (let item of autoOrderResponse.items) {
                let newTNPlan = _.find(dataPlans.plans, (p) => {
                    if (p.altId == item.ultracartItemID) {
                        return true;
                    }
                    return false;
                });
    
                if (newTNPlan) {
                    item.ultracartItemID = newTNPlan.id;
                }
            }
        }
    
        yield put(setCurrentAutoOrder(autoOrderResponse));
        return autoOrderResponse;
    }
    catch (err) {
        yield put(error(err))
    }
}

function* fetchItemsAsync() {
    try {
        let response = yield api.getAllItems();

        yield put(setItems(response.body));
    }
    catch(err) {
        console.log('Error in fetchItemsAsync: ' + err);
        yield put(error(err));
    }
    
}

function* finishCheckout(cart: Cart) {
    const modifiedAutoOrder = yield select(modifiedAutoOrderSelector)
    let updateResp = null;
    let finalizeOrderResp = null;
    try {
        updateResp = yield api.updateAutoOrder(modifiedAutoOrder);
    } catch (err) {
        yield put(error(err));
        console.log('Error in finishCheckout: ', err)
    }
    try {
        finalizeOrderResp = yield api.finalizeOrder(cart);
        const errors = get(finalizeOrderResp, 'body.errors', []);
        if (errors.length > 0) {
            errors.forEach(error => toast.error(error));
        }
    } catch (err) {
        put(error(err));
        console.log('Error in finalizeOrder: ', err);
    }

    return [updateResp.body, finalizeOrderResp.body];
}

function* finishOnetimeCheckout(cart: Cart) {
    try {
        const res = yield api.finalizeOrder(cart);
        const errors = get(res, 'body.errors', []);
        if (errors.length > 0) {
            errors.forEach(error => toast.error(error))
        }
        else return res.body;
    }
    catch (err) {
        console.log("Error in finishOnetimeCheckout: ", err);
        put(error(err));
    }
}

function* addCameraUpgradeNow({ type, payload }: PayloadAction<number>) {
    const upgradeCameras = yield select(upgradeCamerasSelector);
    let upgradeCamerasUpdate: number[] = null;
    if (upgradeCameras == null) {
        upgradeCamerasUpdate = [payload];
    } else {
        upgradeCamerasUpdate = update(upgradeCameras, { $push: [payload] });
    }

    yield put(setCameraUpgradeNow(upgradeCamerasUpdate));
}

function* removeCameraUpgradeNow({ type, payload }: PayloadAction<number>) {
    const upgradeCameras: number[] = yield select(upgradeCamerasSelector);
    if (upgradeCameras != null && upgradeCameras.length > 0) {
        let upgradeCamerasUpdate = upgradeCameras.filter(c => c != payload);
        yield put(setCameraUpgradeNow(upgradeCamerasUpdate));
    }
}

function* reviewModifiedAutoOrder() {
    const modifiedAutoOrder: IAutoOrderModel = yield select(modifiedAutoOrderSelector);
    yield put(toggleLoading(['button']))
    try {
        let response = yield api.reviewOrder(modifiedAutoOrder); 
        
        let reviewResponse = response.body;
        yield put(setModifiedAutoOrder(reviewResponse));
        yield put(push('/Subscription/Review'))
    } 
    catch (err) {
        yield put(error(err));
    }
    finally {
        yield put(toggleLoading(['button']))
    }
}

interface UpdateCameraValuePayload {
    cameraID: number;
    value: string;
    free?: boolean;
} 
function* updateCameraValue ({ cameraID, value, free=false }: UpdateCameraValuePayload) {
    const items: IItem[] = yield select(itemSelector);
    const cameras: ICameraModel[] = yield select(cameraSelector);
    const currentAutoOrder: IAutoOrderModel = yield select(selectCurrentAutoOrder);
    let modifiedAutoOrder: IAutoOrderModel = yield select(modifiedAutoOrderSelector);
    const cameraDataPlans: ICameraDataPlan[] = yield select(getCameraDataPlans);

    const cameraHasCurrentDataPlan = (cameraID: number): boolean => {
        const camera = find(cameraDataPlans, c => c.cameraID === cameraID);
        if (!camera) return false;
        const dataPlans: IDataPlanModel[] = get(camera, 'dataPlans', []);
        let result = false;
        dataPlans.forEach(dp => {
            const startMoment = moment.utc(dp.startDate);
            const endMoment = moment.utc(dp.endDate);
            if (moment().isBetween(startMoment, endMoment)) {
                result = true;
            }
        })
        return result;
    }

    const getNextBillingDate = (date: moment.Moment): Date => {
        let nextBillingDate = moment(date);
        while(nextBillingDate.isAfter(moment().add(1, 'month'))) {
            nextBillingDate.subtract(1, 'month');
        }
        return nextBillingDate.toDate();
    }

    const nextBillingDate = yield select(getNextBillingDate);

    const isMonthlyItem = (itemID: string) => {
        const item = find(items, i => i.ultracartItemID == itemID);
        return get(item, 'durationMonths') == 12;
    }
    // let { activateFirstFreePlan, cameras, currentAutoOrder, modifiedAutoOrder, nextBillingDate } = this.props;
    
    if (free && currentAutoOrder == null) return yield put(activateFirstFreePlan());

    if (modifiedAutoOrder == null && currentAutoOrder != null) {
        modifiedAutoOrder = _.cloneDeep(currentAutoOrder);
    } else if (modifiedAutoOrder != null) {
        // Deep clone of object required to ensure redux updates properly
        modifiedAutoOrder = _.cloneDeep(modifiedAutoOrder);
    }

    let ao = modifiedAutoOrder, co = currentAutoOrder;

    let cameraItem: IAutoOrderItemModel = null;
    if (ao != null && ao.items != null) {
        cameraItem = find(ao.items, (item: IAutoOrderItemModel) => {
            return (item.cameraID == cameraID);
        });
    }

    if (cameraItem == null) {
        const camera = find(cameras, camera => camera.cameraID == cameraID);
        let dataPlanEnd: moment.Moment = null;
        if (camera.dataPlans.length > 0) {
            let dataPlanEndString = camera.dataPlans[camera.dataPlans.length - 1].endDate;
            dataPlanEnd = moment(dataPlanEndString);
        }
        cameraItem = {
            cameraID: cameraID,
            ultracartItemID: value,
            freeMonthPlan: free ? free : false,
            nextShipment: isMonthlyItem(value)
                ?   moment().add(12, 'month').toDate()
                :   _.isNull(dataPlanEnd)
                    ? _.isNull(nextBillingDate) 
                        ? moment().add(1, 'month').toDate()
                        : moment(nextBillingDate).isAfter(moment().add(1, 'month'))
                            ? getNextBillingDate(nextBillingDate)
                            : nextBillingDate.toDate()
                    : dataPlanEnd.toDate()
                    
        };

        if (ao == null) ao = {};

        if (ao.items == null) ao.items = [];

        ao.items.push(cameraItem);
    } else {
        let currentItem: IAutoOrderItemModel = null;
        if (co != null && co.items != null) {
            currentItem = find(co.items, item => item.cameraID == cameraID);
        }

        //Set "noOrderAfter" to now for cancel order
        if (value == 'cancel') {
            cameraItem.ultracartItemID = currentItem.ultracartItemID;
            if (has(currentItem, 'nextShipment')) cameraItem.nextShipment = moment().toDate();
            cameraItem.noOrdersAfter = moment().toDate();
        }

        //Set "nextShipment" to ${delay} months later
        else if (_.startsWith(value, 'delay')) {
            const delay = value.split(' ')[1];
            cameraItem.ultracartItemID = get(currentItem, 'ultracartItemID');
            cameraItem.nextShipment = moment(currentItem.nextShipment).add(delay, 'month').toDate();
            _.unset(cameraItem, 'noOrdersAfter')
        }
        else if (value == 'clear') {
            cameraItem.nextShipment = currentItem.nextShipment;
            _.unset(cameraItem, 'noOrdersAfter');
        }
        else if (value == 'resume') {
            const cam = cameras.find(c => c.cameraID == cameraID);
            if (cameraHasCurrentDataPlan(cameraID)) {
                set(cameraItem, 'nextShipment', moment(cam.dataPlans[0].endDate).toDate());
            }
            else cameraItem.resumeNow = true;
           
            _.unset(cameraItem, 'noOrdersAfter');
        }
        else {
            if (!currentItem && cameraItem.ultracartItemID == value) {
                _.pull(ao.items, cameraItem)
            } else {
                cameraItem.ultracartItemID = value;
                if (free) set(cameraItem, 'freeMonthPlan', free)
                
                if (isMonthlyItem(value)) {
                    cameraItem.nextShipment = has(currentItem, 'nextShipment') ? currentItem.nextShipment : moment().add(12, 'months').toDate();
                }
                else {
                    if (has(currentItem, 'nextShipment')) {
                        cameraItem.nextShipment = currentItem.nextShipment;
                    } else {
                        cameraItem.nextShipment = nextBillingDate ? getNextBillingDate(nextBillingDate) : moment().add(1, 'months').toDate();
                    }
                }
                if (!has(currentItem, 'noOrdersAfter')) _.unset(cameraItem, 'noOrdersAfter');
            }
        }
    }

    //If current and modified autoorders are equal, remove modified autoorder;
    if (_.isEqual(co, ao)) yield put(setModifiedAutoOrder(null));

    yield put(setModifiedAutoOrder(ao));
}

function* onActivateFirstFreePlan() {
    try {
        const cameras: ICameraModel[] = yield select(cameraSelector);
        const freePlanAvailable = cameras.filter(camera => camera.freePlanAvailable);
        if (freePlanAvailable.length == 0) return toast.error('No cameras with free plan available');

        let newAutoOrder: IAutoOrderModel = {
            items: freePlanAvailable.map(camera => ({
                cameraID: camera.cameraID,
                ultracartItemID: camera.hardwareType == CameraHardwareType.CellucoreLive ? '500MB' : 'UNLIMITED',
                freeMonthPlan: true
            }))
        };

        let reviewedAutoOrder = (yield api.reviewOrder(newAutoOrder)).body;
        
        const res = yield api.createOrder(reviewedAutoOrder);

        const cart = res.body.cart;
        
        yield put(setModifiedAutoOrder(reviewedAutoOrder));
        yield put(setCartID(get(cart, 'cart_id')));
        yield put(setCustomerProfile(get(cart, 'customer_profile')));        
        if (cart.items) yield put(push(`/activate`));
    }
    catch(err) {
        console.log('Error in onActivateFirstFreePlan: ' + err);
        put(error(err));
    }
        
}

function* onActivateFreePlan({type, payload}: PayloadAction<number>) {
    let currentAutoOrder: IAutoOrderModel = yield* fetchAutoOrderAsync();
    if (currentAutoOrder == null) return yield put(activateFirstFreePlan());

    put.resolve(setCurrentAutoOrder(currentAutoOrder));

    currentAutoOrder = yield select(selectCurrentAutoOrder);

    const cameras: ICameraModel[] = yield select(cameraSelector);
    const freePlanAvailable = cameras.filter(camera => camera.freePlanAvailable);
    if (freePlanAvailable.length == 0) return toast.error('No cameras with free plan available');

    const modifiedAutoOrder: IAutoOrderModel = update(currentAutoOrder, {
        items: {
            $push: freePlanAvailable.map(camera => ({
                cameraID: camera.cameraID,
                ultracartItemID: camera.hardwareType == CameraHardwareType.CellucoreLive ? '500MB' : 'UNLIMITED',
                freeMonthPlan: true
            }))
        }
    })

    yield put(setModifiedAutoOrder(modifiedAutoOrder));
    yield fetchItemsAsync();
    yield reviewModifiedAutoOrder();
}

interface CreateOneTimePurchasePayload {
    credits?: string;
    data?: string;
    thumbnails?: string;
}
function* onCreateOneTimePurchase({ type, payload:  { credits, data, thumbnails } }: PayloadAction<CreateOneTimePurchasePayload>) {
    yield put(toggleLoading(['button']))
    try {
        let res = yield api.createOneTimePurchase({ credits, data, thumbnails});
        const cart = res.body;
        yield put(setCartID(cart.cart_id));
        res = yield api.getCustomerProfile();
        const profile = res.body;
        yield put(setCustomerProfile(profile));
        yield put(push(`/billing?onetime=true`))
    }
    catch (err) {
        yield put(error(err));
        console.log('ERROR in onCreateOneTimePurchase: ' + err);
    }
    finally {
        yield put(toggleLoading(['button']))
    }
}
interface DelayOrCancelPayload {
    cameraID: number;
    value: string;
    cb: () => void;
}
function* onDelayOrCancel({ payload: { cameraID, value, cb } }: PayloadAction<DelayOrCancelPayload>) {
    try {
        yield* updateCameraValue({ cameraID, value });
        const modifiedAutoOrder = yield select(modifiedAutoOrderSelector);
        let res = yield api.reviewOrder(modifiedAutoOrder); 
        const reviewedOrder = res.body;

        yield api.updateAutoOrder(reviewedOrder);
        cb();
        yield put(setModifiedAutoOrder(null));
        yield* fetchAutoOrderAsync();
    }
    catch (err) {
        yield put(error(err));
        console.log("ERROR in onDelayOrCancel: ", err);
    }
}

function* onDeleteBilling({ type, payload }: PayloadAction<number>) {
    try {
        const res = yield api.deleteBilling(payload);
        yield put(setCustomerProfile(res.body));
    }
    catch (err) {
        yield put({ type: 'ERROR', payload: err  });
        console.log('ERROR in onDeleteCard: ' + err);
    }
}

function* onDeleteCard({ payload }: PayloadAction<number>) {
    try {
        const res = yield api.deleteCard(payload);
        yield put(setCustomerProfile(res.body));
    }
    catch (err) {
        yield put(error(err));
        console.log('ERROR in onDeleteCard: ' + err);
    }
}

function* onGetCurrentAutoOrder() {
    yield put(toggleLoading(['background']))
    try {
        yield* fetchAutoOrderAsync();
    } finally {
        yield put(toggleLoading(['background']))
    }
}

function* onGetDefaultBilling() {
    yield put(toggleLoading(['background']));
    try {
        const res = yield api.getDefaultBilling();
        if (res.body != null) {
            yield put(setDefaultBilling({ payment: res.body.payment.credit_card, billing: res.body.billing }));
        }
    }
    catch (err) {
        yield put(error(err));
        console.log("ERROR in onGetCusomterProfile: " + err);
    }
    finally {
        yield put(toggleLoading(['background']));
    }
}

function* onFinishActivateFirstFreePlan({ type, payload }: PayloadAction<{ billing: CartBilling, card: CartPaymentCreditCard }>) {
    yield put(toggleLoading(['button']));
    const cartID = yield select(cartIDSelector);

    try {
        let cart: Cart = {
            cart_id: cartID,
            billing: payload.billing,
            payment: {
                paymentMethod: 'Credit Card',
                creditCard: payload.card
            },
        }

        const res = yield api.updateCartBilling(cart);
        if (res.body.errors && res.body.errors.length > 0) {
            return res.body.errors.forEach((error: string) => toast.error(error));
        }

        const cartResponse = res.body.cart;
        const finishRes = yield* finishCheckout(cartResponse);
        if (finishRes) {
            yield put(setModifiedAutoOrder(null));
            yield put(push('/Subscription'));
            toast("Cameras have been activated with free plan");
        }
    }

    catch(err) {
        yield put(error(err));
        console.log('Error in "onFinishActivateFirstFreePlan": ' + err);
    }

    finally {
        yield put(toggleLoading(['button']));
    }
}

function* onFinishBilling({ type, payload: { billing, card, storeCard, onetime=false, unblock } }: PayloadAction<CartBilling & any>) {
    yield put(toggleLoading(['button']));
    const cartID = yield select(cartIDSelector);

    try {
        let cart: Cart = {
            cart_id: cartID,
            billing: billing,
            payment: {
                paymentMethod: 'Credit Card',
                creditCard: card
            },
        }

        if (has(card, 'customer_profile_credit_card_id')) {
            set(cart, 'payment.creditCard.customerProfileCreditCardId', card.customer_profile_credit_card_id)
        }

        if (storeCard) {
            set(cart, 'payment.creditCard.storeCreditCard', storeCard);
        }

        const res = yield api.updateCartBilling(cart);
        if (res.body.errors && res.body.errors.length > 0) {
            if (find(res.body.errors, error => error === 'Please enter the card verification number.')) {
                return yield put(toggleCCVModal())
            }
            else {
                return res.body.errors.forEach((error: string) => toast.error(error));
            }
            
        }

        const cartResponse: Cart = res.body.cart;
        if (cartResponse.cart_id != cartID) throw 'CartID has changed' 
        unblock();
        yield put(push(`/checkout?onetime=${onetime}`));
        
    }

    catch(err) {
        yield put(error(err));
        console.log('Error in "onFinishBilling": ' + err);
    }

    finally {
        yield put(toggleLoading(['button']));
    }
}

interface FinishCheckoutPayload {
    cart: Cart;
    onetime: "true" | "false";
    unblock: UnregisterCallback;
}
function* onFinishCheckout({ type, payload: { cart, onetime, unblock } }: PayloadAction<FinishCheckoutPayload>) {
    yield put(toggleLoading(['button']))
    try {
        const finishRes = yield* finishCheckout(cart);

        if (finishRes) {
 			unblock();
            if (onetime == 'false') yield put(setModifiedAutoOrder(null));
            if (onetime == 'false') yield put(push('/Subscription'));            
            toast("Your order has successfully been processed");
        }
    }
    catch(err) {
        yield put(error(err));
        console.log('Error in "onFinishCheckout: ' + err);
    } 
    finally {
        yield put(toggleLoading(['button']))
    }
}

function* onFinishReview({ payload }: PayloadAction<UnregisterCallback>) {
    yield put(toggleLoading(['button']))
    try {
        const billingState: BillingState = yield select(billingStateSelector);
        const currentAutoOrder = billingState.currentAutoOrder;
        const modifiedAutoOrder: IAutoOrderModel = update(billingState.modifiedAutoOrder, {
            items: {
                $set: billingState.modifiedAutoOrder.items.map(mItem => {
                    if (includes(billingState.cameraUpgradeNow, mItem.cameraID)) {
                        return update(mItem, {
                            $toggle: ['upgradeNow'],
                        })
                    }
                    return mItem;
                })
            }
        });

        yield put(setModifiedAutoOrder(modifiedAutoOrder));

        

        if (!currentAutoOrder) {
            let res = yield api.createOrder(modifiedAutoOrder);
            
            const cart = res.body.cart;
            yield put(setCartID(cart.cart_id));
            const profileRes = yield api.getCustomerProfile();
            yield put(setCustomerProfile(profileRes.body));
            payload();
            yield put(push(`/billing`))
        }
        else {
            const res = yield api.updateOrder(modifiedAutoOrder);
            const cart = res.body;
            if (cart.items) {
                yield put(setCartID(cart.cart_id));
                const profileRes = yield api.getCustomerProfile();
                yield put(setCustomerProfile(profileRes.body));
                payload();
                yield put(push(`/billing`))
            }
            else {
                let allCanceled = true;

                modifiedAutoOrder.items.forEach(i => {
                    if (get(i, 'noOrdersAfter', null) === null) allCanceled = false;
                })

                if (allCanceled) {
                    yield api.cancelOrder();
                    toast("Your subscription has been canceled");
                }
                else toast("Your items have been updated") 
                payload();
                yield all([
                    put(setModifiedAutoOrder(null)),
                    put(push('/Subscription'))
                ])
                
            }
        }
    }
    catch(err) {
        yield put(error(err));
        console.log("Error in onFinishReview: ", err);
    }
    finally {
        yield put(toggleLoading(['button']))
    }
}

function* onApplyCoupon({ payload }: PayloadAction<any>) {
    yield put(toggleLoading(['button']))
    const cartID = yield select(cartIDSelector);
    const modifiedAutoOrder = yield select(modifiedAutoOrderSelector)
    try {
        let res = yield api.applyCoupon(payload, cartID, modifiedAutoOrder);
        yield put(setCouponCode(''));
        const autoOrder = _.get(res, 'body.autoOrder') as IAutoOrderModel;
        const invalidCoupon = _.get(res, 'body.invalidCoupon');
        if (autoOrder) {
            yield put(setModifiedAutoOrder(autoOrder));
        }
        if (invalidCoupon) {
            toast.error("Coupon is invalid or expired");
        } else {
            autoOrder.enteredCouponCode = payload;
            let res = yield api.reviewOrder(autoOrder);
            const reviewedOrder = res.body;
            yield put(setModifiedAutoOrder(reviewedOrder));
        }
    }
    catch (err) {
        yield put(error(err));
        console.log('Error in "onApplyCoupon: ' + err);

    }
    finally {
        yield put(toggleLoading(['button']))
    }
}

function* onInitialize() {
    yield put(toggleLoading(['background']))
    try {
        const modifiedAutoOrder = yield select(modifiedAutoOrderSelector);
        if (modifiedAutoOrder) {
            yield put(setModifiedAutoOrder(update(
                modifiedAutoOrder, {
                    items: {
                        $set: modifiedAutoOrder.items.map((item: IAutoOrderItemModel) => update(item, {
                            $unset: ['reviewType'],
                            billings: {
                                $set: item.billings.map((billing: IAutoOrderItemBillingModel) => update(billing, { $unset: ['coupon', 'couponType', 'couponDiscount'] }))
                            }
                        }))
                    },
                    $unset: ['enteredCouponCode', 'supplementalDataCoupon', 'supplementalThumbnailCoupon', 'photoPackCoupon', 'orderCoupon']
                }
            ) as IAutoOrderModel))
        }

        yield* fetchAutoOrderAsync();
        yield  put(getCameras());
        yield* fetchItemsAsync();
    }
    finally {
        yield put(toggleLoading(['background']));
    }
    
}

interface RenewPayload {
    cameraID: number;
    cb: () => void;
}
function* onRenew({ payload: { cameraID, cb } }: PayloadAction<RenewPayload>) {
    try {
        yield* updateCameraValue({ cameraID, value: 'resume' });
        const modifiedAutoOrder = yield select(modifiedAutoOrderSelector);
        let res = yield api.reviewOrder(modifiedAutoOrder);
        const reviewedOrder = res.body; 
        cb()
        yield put(setModifiedAutoOrder(reviewedOrder));
        yield put(push('/Subscription/Review'));
    }
    catch(err) {
        yield put(error(err));
        console.log("ERROR in onRenew: " + err);
    }

}

function* onPurchasePhotoPack({ type, payload }: PayloadAction<string>) {
    yield put(toggleLoading(['background']))
    try {
        yield* fetchAutoOrderAsync();
        yield* fetchItemsAsync();
        yield put(setPhotoPack(payload));
        yield* reviewModifiedAutoOrder();
    }
    finally {
        yield put(toggleLoading(['background']));
    }
}

function* onGetCustomerProfile() {
    try {
        const res = yield api.getCustomerProfile();
        yield put(setCustomerProfile(res.body));
    }
    catch (err) {
        yield put(error(err));
        console.log("ERROR in onGetCusomterProfile: " + err);
    }
}

function* onGetOrders() {
    yield put(toggleLoading(['background']))
    try {
        const res = yield all([
            api.getOrders(),
            api.getAllItems(),
        ])
        yield put(setOrders(res[0].body));
        yield put(setItems(res[1].body));
    }
    catch (err) {
        yield put(error(err));
        console.log('ERROR in onGetOrder: ' + err);
    }
    finally {
        yield put(toggleLoading(['background']))
    }
}

function* onSaveCard({ payload }: PayloadAction<{card: CartPaymentCreditCard, callback: () => void}>) {
    yield put(toggleLoading(['button']))
    try {
        const res = yield api.saveCard(payload.card);
        yield put(setCustomerProfile(res.body));
        if (payload.callback) {
            yield call(payload.callback, [res.body.cards[0].customer_profile_credit_card_id]);
        }
    }
    catch (err) {
        yield put(error(err));
        console.log('ERROR in onSaveCard: ' + err);
    }
    finally {
        yield put(toggleLoading(['button']))
    }
}

function* onSaveBilling({ payload }: PayloadAction<{billing: CartBilling, callback: () => void}>) {
    yield put(toggleLoading(['button']))
    try {
        const res = yield api.saveBilling(payload.billing);
        yield put(setCustomerProfile(res.body));

        if (payload.callback) {
            const billings = get(res, 'body.billing', [] as Array<CustomerBilling>);
            const savedBilling = billings.length > 0 ? billings[billings.length - 1] : null;
            const billingOID = get(savedBilling, 'customer_billing_oid');
            yield call(payload.callback, [billingOID]);
        }
    }
    catch (err) {
        yield put(error(err));
        console.log('ERROR in onSaveBilling: ' + err);
    }
    finally {
        yield put(toggleLoading(['button']))
    }
}

function* onSetNewDefaultBilling({ type, payload }: PayloadAction<CartBilling>) {
    try {
        yield api.setDefaultBilling(payload);
        yield put(getDefaultBilling());
    }
    catch (err) {
        yield put(error(err));
        console.log('ERROR in onSetNewDefaultBilling: ' + err);
    }
}

function* onSetNewDefaultBillingWithCallback({ type, payload }: PayloadAction<{billing: CartBilling, callback: () => void}>) {
    yield* onSetNewDefaultBilling({ type, payload: payload.billing });
    if (payload.callback) {
        yield call(payload.callback);
    }
}

function* onSetNewDefaultPayment({ type, payload }: PayloadAction<CartPaymentCreditCard>) {
    try {
        yield api.setNewDefaultPayment(payload);
        yield put(getDefaultBilling());
    }
    catch (err) {
        yield put(error(err));
        console.log('ERROR in onSetNewDefaultPayment: ' + err);
    }
}

function* onSetNewDefaultPaymentWithCallback({ type, payload}: PayloadAction<{card: CartPaymentCreditCard, callback?: () => void}>) {
    yield* onSetNewDefaultPayment({ type, payload: payload.card });
    if (payload.callback) {
        yield call(payload.callback);
    }
}

interface IUpdateCameraValuePayload {
    cameraID: number;
    value: string;
    free?: boolean;
}

function* onUpdateCameraValue({ type, payload: { cameraID, value, free } }: PayloadAction<IUpdateCameraValuePayload>) {
    yield* updateCameraValue({ cameraID, value, free });
}

// Our watcher Saga: spawn a new getAutoOrder task
export function* watchBilling() {
    yield takeLatest(Actions.GET_CURRENT_AUTO_ORDER, onGetCurrentAutoOrder);
    yield takeLatest(Actions.GET_CUSTOMER_PROFILE, onGetCustomerProfile);
    yield takeLatest(Actions.FETCH_ITEMS, fetchItemsAsync);
    yield takeEvery(Actions.ACTIVATE_FIRST_FREE_PLAN, onActivateFirstFreePlan);
    yield takeEvery(Actions.ACTIVATE_FREE_PLAN, onActivateFreePlan);
    yield takeEvery(Actions.ADD_CAMERA_UPGRADE_NOW, addCameraUpgradeNow);
    yield takeEvery(Actions.CREATE_ONE_TIME_PURCHASE, onCreateOneTimePurchase);
    yield takeEvery(Actions.DELAY_OR_CANCEL, onDelayOrCancel);
    yield takeEvery(Actions.DELETE_BILLING, onDeleteBilling);
    yield takeEvery(Actions.DELETE_CARD, onDeleteCard);
    yield takeEvery(Actions.FINISH_ACTIVATE_FIRST_FREE_PLAN, onFinishActivateFirstFreePlan);
    yield takeEvery(Actions.FINISH_BILLING, onFinishBilling);
    yield takeEvery(Actions.FINISH_CHECKOUT, onFinishCheckout);
    yield takeEvery(Actions.FINISH_REVIEW, onFinishReview);
    yield takeEvery(Actions.APPLY_COUPON, onApplyCoupon);
    yield takeEvery(Actions.INITIALIZE, onInitialize);
    yield takeEvery(Actions.PURCHASE_PHOTO_PACK, onPurchasePhotoPack);
    yield takeEvery(Actions.REMOVE_CAMERA_UPGRADE_NOW, removeCameraUpgradeNow);
    yield takeEvery(Actions.RENEW, onRenew);
    yield takeEvery(Actions.REVIEW_MODIFIED_AUTO_ORDER, reviewModifiedAutoOrder);
    yield takeEvery(Actions.GET_ORDERS, onGetOrders);
    yield takeEvery(Actions.SAVE_CARD, onSaveCard);
    yield takeEvery(Actions.SAVE_BILLING, onSaveBilling);
    yield takeEvery(Actions.GET_DEFAULT_BILLING, onGetDefaultBilling);
    yield takeEvery(Actions.SET_NEW_DEFAULT_PAYMENT, onSetNewDefaultPayment);
    yield takeEvery(Actions.SET_NEW_DEFAULT_PAYMENT_WITH_CALLBACK, onSetNewDefaultPaymentWithCallback);
    yield takeEvery(Actions.SET_NEW_DEFAULT_BILLING, onSetNewDefaultBilling);
    yield takeEvery(Actions.SET_NEW_DEFAULT_BILLING_WITH_CALLBACK, onSetNewDefaultBillingWithCallback);
    yield takeEvery(Actions.UPDATE_CAMERA_VALUE, onUpdateCameraValue);
}