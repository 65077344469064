import * as React from "react";
import {
    BrowserRouter as Router,
} from 'react-router-dom'
import { addLocaleData, IntlProvider } from 'react-intl';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../css/BMuiTheme'
import 'typeface-roboto';

import * as en from 'react-intl/locale-data/en';
import * as fr from 'react-intl/locale-data/fr';
import { LoginRoutes } from "./LoginRoutes";
import { Store } from 'redux';
import { Provider } from 'react-redux';
import { initStore } from '../../store';

import { State } from '../../../Types';

addLocaleData(en);
addLocaleData(fr);

interface ITCLoginAppProps {
    locale: string;
}

interface ITCLoginAppState {
    messages: any;
}

export class TCLoginApp extends React.Component<ITCLoginAppProps, ITCLoginAppState> {
    private store: Store<State>;

    constructor(props: ITCLoginAppProps) {
        super(props);
        this.state = {
            messages: null
        };
    }

    componentWillMount() {
        if (!this.props.locale.startsWith("en")) {
            this.getTranslation();
        }

        const { store } = initStore();
        this.store = store;
    }

    async getTranslation() {
        let fetchUrl = "/lang/" + this.props.locale + ".json";
        let response = await fetch(fetchUrl);
        let messages = JSON.parse(await response.text());
        this.setState({
            messages: messages
        });
    }

    render(): JSX.Element {
        if (this.state.messages == null && !this.props.locale.startsWith("en"))
            return (<div>"Loading..."</div>);

        return (
            <Provider store={this.store}>
                <IntlProvider locale={this.props.locale} messages={this.state.messages} defaultLocale={'en-US'}  >
                    <MuiThemeProvider theme={theme}>
                        <Router>
                            {LoginRoutes}
                        </Router>
                    </MuiThemeProvider>
                </IntlProvider>        
            </Provider>
        );
    }
}