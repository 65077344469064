import * as React from "react";
import { Route, Redirect, RouteComponentProps, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import TCLoginContainer from './TCLoginContainer';
import TCPageHeader from '../TCHeader/TCPageHeader';
import TCPageFooter from '../TCFooter';
import TCConfirmEmail, { ITCConfirmEmailProps } from './TCConfirmEmail';
import TCResetPassword from './TCResetPassword';
import TCAdminLogin from './TCAdminLogin';

import { isBushnell } from '../../constants'; 

const backgroundImage = isBushnell ? 'url("/images/web-background.jpg")' : 'white';
const justifyContent = isBushnell ? 'flex-end' : 'flex-start'

let LoginRoutes: JSX.Element = (
    <div style={{ flex: '1', minWidth: '100%', minHeight: '100%', flexDirection: 'column', display: 'flex'}}>
        <ToastContainer position='bottom-right' autoClose={5000} progressClassName='toastify-progress-bar'/>
        <TCPageHeader loggedIn={false}/>
        <Switch>
            <Route key={'HomeLogin'} path='/' exact render={(props: RouteComponentProps<any>) => (<Redirect to='/Login' />)} />
            <Route key={'Login'} path='/Login' exact render={(props: RouteComponentProps<any>) => (
                <div className='login-main' style={{backgroundImage, justifyContent}}>
                    <TCLoginContainer />
                </div>
            )} />
            <Route
                key={'ConfirmEmail'} 
                path='/ConfirmEmail/:token' 
                render={(props: RouteComponentProps<ITCConfirmEmailProps>) => (<TCConfirmEmail token={props.match.params.token} />) }/>
            <Route 
                key='ResetPassWord' 
                path='/ResetPassword/:token' 
                render={(props: RouteComponentProps<{ token: string }>) => <TCResetPassword {...props} />} />
            <Route
                key={'AdminLogin'}
                path='/Login/Admin/:token'
                render={(props: RouteComponentProps<{ token: string }>) => <TCAdminLogin {...props} />} />
            <Redirect to='/Login' />
        </Switch>
        <TCPageFooter />
    </div>
);

export { LoginRoutes };