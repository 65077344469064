import * as React from 'react'
import * as _ from 'lodash';
import { FormattedMessage, injectIntl, InjectedIntlProps, defineMessages } from "react-intl";

import { TCIcon } from './index';

export interface ITCPopupProps {
    closePopup: () => any
}

const appStrings = defineMessages({
    text: {
        id: "TCPopup.text.label",
        defaultMessage: "text"
    }
});

class TCPopupWrapper extends React.Component<ITCPopupProps & InjectedIntlProps, {}> {

    constructor(props: ITCPopupProps & InjectedIntlProps) {
        super(props);
    }
	
    componentDidMount() {
        document.body.style.overflow = 'hidden';
        window.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto';
        window.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (event: KeyboardEvent) => {
        const ESC_KEY = 27;
        const key = event.keyCode;

        switch (key) {
            case ESC_KEY:
                this.props.closePopup();
                break;
        }
    }

    render(): JSX.Element {
		const {formatMessage} = this.props.intl;
        return (
            <div className='dimScreen'>
                <div className='flex-container' style={{ height: '100%', marginTop: 'auto', marginBottom: 'auto', justifyContent: 'center', alignItems: 'center' }} >
                    <div className='roundCorners' style={{ backgroundColor: 'white', position: 'relative', padding: '15px', marginLeft: '10px', marginRight: '10px' }}>
                        <a onClick={this.props.closePopup} className='orangeXButton' style={{ cursor: 'pointer', position: 'absolute', top: '-8px', right: '-8px'}} />
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export const TCPopup = injectIntl(TCPopupWrapper);