import * as request from 'superagent';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { ICameraConfigModel } from '../../Models';

let authRejectHandler = (req: any) => {
    req.on('error', (err:any) => {
        //if (err.status === 401) {
        //    window.location.replace('/');
        //}
    });
    return req;
}

export const api = {
    addCamera: (body: {cameraName: string, cameraSerial: string}) => (
        request
            .post('/api/camera/add/')
            .send({ Name: body.cameraName, Serial: body.cameraSerial })
            .catch(err => {
                if (err.status == 403) {
                    let responseText = get(err, 'response.text', '');
                    if (responseText.startsWith("Aggressor")) {
                        err.response = responseText;
                    } else {
                        err.response = 'Cannot find serial number';
                    }
                    throw err;
                } 
                if (err.status == 409) {
                    err.response = 'Camera has already been claimed';
                    throw err;
                } 
                throw err; 
            })
    ),
    getCameraDetails: (id: number) => (
        authRejectHandler(request
            .get(`/api/webcamera/detail/${id}`))
    ),
    getCameras: () => (
        request
            .get('/api/WebCamera/Camera/')
    ),
    removeCamera: (id: number) => (
        request
            .delete(`/api/camera/${id}`)
    ),
    saveCameraDetails: (id: number, config: ICameraConfigModel) => (
        request
            .post(`/api/webcamera/config/${id}`)
            .send(config)
    ),
    setTrackingMode: (id: number, enabled: boolean) => (
        request
            .post(`/api/webcamera/trackingmode/${id}?enabled=${enabled}`)
    ),
    setConnected: (id: number, connected: boolean) => (
        request
            .post(`/api/webcamera/setconnected/${id}?connected=${connected}`)
    ),
    setDeployed: (id: number, deployed: boolean) => (
        request
            .post(`/api/webcamera/setdeployed/${id}?deployed=${deployed}`)
    ),
    startLiveStream: (id: number) => (
        request
            .post(`/api/Live/StreamStart/${id}`)
    ),
    continueLiveStream: (id: number) => (
        request
            .post(`/api/Live/StreamContinue/${id}`)
    ),
    stopLiveStream: (id: number) => (
        request
            .post(`/api/Live/StreamStop/${id}`)
    ),
    statusLiveStream: (id: number) => (
        request
            .get(`/api/Live/StreamStatus/${id}`)
    )
}
