import * as request from 'superagent';
import { toast } from 'react-toastify';
import { IAutoOrderModel, Cart, CartPaymentCreditCard, CartBilling } from '../../Models';

export const api = {
    cancelOrder: () => (
        request
            .post('/api/Billing/CancelAutoOrder')
    ),
    createOrder: (order: IAutoOrderModel) => (
        request
            .post('/api/Billing/CreateOrder')
            .send(order)
            .then(res => {
                return res;
            }, err => {
                if (err.status == 400) {
                    toast.error(err.rawResponse)
                }
                else throw err;
            })
    ),
    createOneTimePurchase: (supplemental: { credits: string, data: string, thumbnails: string}) => (
        request
            .post('/api/Billing/CreateOneTimePurchase')
            .send(supplemental)
    ),
    orderSummary: (cartID: string) => (
        request
            .get('/api/Billing/OrderSummary')
            .query({ cartID })
    ),
    deleteBilling: (id: number) => (
        request
            .delete('/api/Billing/RemoveBillingInformation')
            .query({ billingID: id })
    ),
    deleteCard: (id: number) => (
        request
            .delete('/api/Billing/RemovePaymentInformation')
            .query({ cardID: id })
    ),
    finalizeOrder: (cart: Cart) => (
        request
            .post('/api/Billing/FinalizeOrder/')
            .send(cart)
    ),
    getCustomerProfile: () => (
        request
            .get('/api/Billing/GetProfile') 
    ),
    getDefaultBilling: () => (
        request
            .get('/api/Billing/GetDefaultPayment')
    ),
    getOrders: () => (
        request
            .get('/api/Billing/GetOrders')
    ),
    getItems: () => (
        request
            .get('/api/Billing/Items/')
    ),
    getAllItems: () => (
        request
            .get('/api/Billing/AllItems/')
    ),
    reviewOrder: (order: IAutoOrderModel) => (
        request
            .post('/api/Billing/ReviewOrder')
            .send(order)
    ),
    saveBilling: (billing: CartBilling) => (
        request
            .post('/api/Billing/SaveBillingInformation')
            .send(billing)
    ),
    saveCard: (card: CartPaymentCreditCard) => (
        request
            .post('/api/Billing/SavePaymentInformation')
            .send(card)
    ),
    setDefaultBilling: (billing: CartBilling) => (
        request
            .post('/api/Billing/SetDefaultBilling')
            .send(billing)
    ),
    setDefaultPayment: () => (
        request
            .post('/api/Billing/SetDefaultPayment')
    ),
    setNewDefaultPayment: (card: CartPaymentCreditCard) => (
        request
            .post('/api/Billing/SetNewDefaultPayment')
            .send(card)
    ),
    updateAutoOrder: (order: IAutoOrderModel) => (
        request
            .post('/api/Billing/UpdateAutoOrder')
            .send(order)
    ),
    updateOrder: (order: IAutoOrderModel) => (
        request
            .post('/api/Billing/UpdateOrder')
            .send(order)
            .then(res => {
                return res;
            }, err => {
                if (err.status == 400) {
                    toast.error(err.rawResponse)
                }
                else throw err;
            })
    ),
    updateCartBilling: (cart: Cart) => (
        request
            .post('/api/Billing/UpdateCartBilling/')
            .accept('application/json')
            .send(cart)
    ),
    applyCoupon: (coupon: string, cartId: string, autoOrder: IAutoOrderModel) => (
        request
            .post('/api/Billing/ApplyCouponToOrder/')
            .accept('application/json')
            .send({ coupon, cartId, autoOrder })
    )
}