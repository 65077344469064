import { defineMessages } from 'react-intl';

export default defineMessages({
    english: {
        id: "elements.languagePicker.language.english",
        defaultMessage: "English"
    },
    frenchCanadian: {
        id: "elements.languagePicker.language.frenchCanadian",
        defaultMessage: "French Canadian"
    },
    select: {
        id: "elements.languagePicker.select",
        defaultMessage: "Select Language"
    },
    firstName: {
        id: "elements.billingForm.firstName.label",
        defaultMessage: "First Name"
    },
    lastName: {
        id: "elements.billingForm.lastName.label",
        defaultMessage: "Last Name"
    },
    street1: {
        id: "elements.billingForm.street1.label",
        defaultMessage: "Street Address"
    },
    street2: {
        id: "elements.billingForm.street2.label",
        defaultMessage: "Street Address 2"
    },
    city: {
        id: "elements.billingForm.city.label",
        defaultMessage: "City"
    },
    state: {
        id: "elements.billingForm.state.label",
        defaultMessage: "State/Territory"
    },
    zip: {
        id: "elements.billingForm.zip.label",
        defaultMessage: "Zip Code"
    },
    country: {
        id: "elements.billingForm.country.label",
        defaultMessage: "Country"
    },
    cardNumber: {
        id: "elements.paymentForm.cardNumber.label",
        defaultMessage: "Card Number"
    },
    cvc: {
        id: "elements.paymentForm.cvc.label",
        defaultMessage: "cvc"
    },
    expirationMonth: {
        id: "elements.paymentForm.expirationMonth.label",
        defaultMessage: "Expiration Month"
    },
    expirationYear: {
        id: "elements.paymentForm.expirationYear.label",
        defaultMessage: "Expiration Year"
    },
});