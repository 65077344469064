import * as React from 'react';
import { Field, GenericField, reduxForm, InjectedFormProps } from 'redux-form';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { Col, Row } from 'reactstrap';
import Typography from '@material-ui/core/Typography';
import { ArrowBack } from '@material-ui/icons';

import { IReduxTextInputProps, ISimpleSelectProps, LoadingButton, ReduxCheckbox, ReduxSimpleSelect, ReduxTextInput, IReduxCheckboxProps } from '.';
import appStrings from './appStrings';
import { monthOptions, yearOptions } from './options';
import { divIcon } from 'leaflet';
import { CartPaymentCreditCard } from '../../Models';

declare var UltraCartHostedFields: any;
declare var jQueryHostedFields: any;
declare var jsonHostedFields: any;
declare var window: any;
declare var ULTRACART_MERCH_ID: string;

interface IPaymentFormProps {
    cartID?: string;
    loading?: boolean;
    saveButton?: boolean;
    showBack?: boolean;
    onBackClick?: () => void;
    onSave?: () => void;
    style?: React.CSSProperties,
    //updateCardInfo: (card: { cardType: string, maskedCreditCardNumber: string, token?: string }) => void;
    onCartCardChange?: (cartCard: CartPaymentCreditCard) => void;
} 

const TextInputField = Field as new() => GenericField<IReduxTextInputProps>;
const SelectField = Field as new() => GenericField<ISimpleSelectProps>; 
const CheckboxField = Field as new() => GenericField<IReduxCheckboxProps>

class PaymentFormWrapper extends React.Component<IPaymentFormProps & InjectedFormProps & InjectedIntlProps, {}> {
    componentDidMount() {
        this.setupHostedFields();
    }

    updateCard = (card: {cardType: string, maskedCreditCardNumber: string, maskedCVV: string, token: string}) => {
        const newCard: CartPaymentCreditCard = {
            cardNumber: card.maskedCreditCardNumber,
            cardNumberToken: card.token,
            cardType: card.cardType,
        }

        this.props.onCartCardChange(newCard);
    }

    setupHostedFields = () => {
        window.ultraCartHostedFieldsDebugMode = false;
        let hostedFields = UltraCartHostedFields.setup(jQueryHostedFields, jsonHostedFields, {
            'sessionCredentials': {
                /* TODO: merchantId is a javascript variable? Does it exist in your page already?  Is it set? */
                /* TODO: app.data.cart is the cart (backbone model) variable for the responsive checkout. ok? */
                'merchantId': ULTRACART_MERCH_ID, 
                'shoppingCartId': this.props.cartID
                /* TODO: Do yourself a favor.  Check the CaSe on the fields above. The last 'd' on shoppingCartId is lowercase... */
            },
            /* TODO: Do you have any css files you wish the hosted fields iframes to reference?  */
            /* TODO: (cont.) If so, uncomment these lines and supply. */
            'cssUrls':[
                'https://dev2.wirelesstrophycam.com/css/site.css'
            ],
            'overlayZIndex': 10,

            /* TODO: Make sure the two html inputs referenced below (by the selectors) have type="text" */
            /* TODO (cont.) and not type="number" */
            'hostedFields': {
                'creditCardNumber': {
                    'selector': '#creditCardNumber', /* TODO: Is this the html id of your credit card number field? */
                    'callback': this.updateCard
                },
                'creditCardCvv2': {
                    'selector': '#creditCardVerificationNumber', /* TODO: Is this the html id of your cvv field? */
                }
            }
        });
    } 

    

    render() {
        const { intl: { formatMessage }, loading, saveButton, showBack=false, onBackClick, onSave, style } = this.props;
        return (
            <form style={style}>
                <Row>
                    <Col xs={12} sm={6}>
                        <TextInputField 
                            id='creditCardNumber'
                            name='credit_card_number'
                            component={ReduxTextInput}
                            label={formatMessage(appStrings.cardNumber)}
                            shrink/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <TextInputField 
                            id='creditCardVerificationNumber'
                            name='credit_card_verification_number'
                            component={ReduxTextInput}
                            label={formatMessage(appStrings.cvc)}
                            shrink/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6}>
                        <SelectField 
                            id='cardExpirationMonth'
                            name='card_expiration_month'
                            label={formatMessage(appStrings.expirationMonth)}
                            component={ReduxSimpleSelect}
                            options={monthOptions}
                            shrink/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <SelectField 
                            id='cardExpirationYear'
                            name='card_expiration_year'
                            label={formatMessage(appStrings.expirationYear)}
                            component={ReduxSimpleSelect}
                            options={yearOptions}
                            shrink/>
                    </Col>
                </Row>

                <Row>
                    {saveButton &&
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='whiteButton' style={{ marginRight: '10px' }} onClick={onBackClick}>Cancel</div>
                        <LoadingButton 
                            label='Save Card'
                            loading={loading}
                            type='orange' 
                            onClick={onSave}/>
                    </div>}
                </Row>
                
                {showBack && 
                <Row>
                    <Col xs={12} sm={12} className='clickable' style={{ display: 'flex' }} onClick={onBackClick}>
                        <ArrowBack />
                        <Typography variant="body2">Back</Typography>
                    </Col>
                </Row>}
            </form>
        )
    }
}

export const PaymentForm = injectIntl<IPaymentFormProps>(reduxForm<{}, IPaymentFormProps & InjectedIntlProps>({ form: 'payment' })(PaymentFormWrapper as any));