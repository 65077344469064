import * as React from 'react';
import { Motion, presets, spring } from 'react-motion';

interface GrowOnHoverProps {
    scale?: number
    timeout?: number,
    children: JSX.Element,
}

interface GrowOnHoverState {
    hovering: boolean
}

export class GrowOnHover extends React.Component<GrowOnHoverProps, GrowOnHoverState> {
    state = {
        hovering: false
    }

    render() {
        const { scale=1.1, timeout=150, children } = this.props;
        return (
            <Motion style={{x: this.state.hovering ? spring(scale, presets.noWobble) : spring(1, presets.noWobble)}} >
                {({x}: {x: number} ) => 
                    <div 
                        style={{transform: `scale(${x})`}}
                        onMouseEnter={() => this.setState({hovering: true})}
                        onMouseLeave={() => this.setState({hovering: false})}>
                        {children}
                    </div>}
            </Motion>
        )
    }
}