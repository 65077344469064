import * as React from "react";
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import { injectIntl, InjectedIntlProps, defineMessages } from "react-intl";
import { connect, Dispatch } from 'react-redux';
import * as request from 'superagent';

import { StyledTextInput as TextField } from '../Elements'
 
import { isBushnell } from '../../constants'
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { State } from "../../../Types";
import { error } from "../../reducers/sagas";

interface TCLoginFormProps  {
    goTo?: (route: string)=>void
}

interface ITCLoginFormState {
    email: string,
    password: string,
    showPassword: boolean
}

const messages = defineMessages({
    email: {
        id: "login.email.label",
        defaultMessage: "E-mail Address"
    },
    password: {
        id: "login.password.label",
        defaultMessage: "Password"
    },
    login: {
        id: "login.login.label",
        defaultMessage: "Login"
    },
    remember: {
        id: "login.remember.label",
        defaultMessage: "Remember me"
    },
    forgot: {
        id: "login.forgot.label",
        defaultMessage: "Forgot Password?"
    },
    createAccount: {
        id: "login.createAccount.label",
        defaultMessage: "Create new account"
    },
    createBenefitsBushnell: {
        id: "login.createBenefitsBushnell.label",
        defaultMessage: "Manage your Wireless Trophy Camera, purchase wireless data and view, edit and share photos."
    },
    createBenefitsPrimos: {
        id: "login.createBenefitsPrimos.label",
        defaultMessage: "Manage your Primos Cellular Camera, purchase wireless data and view and share photos."
    }
});

type ILoginFormAllProps = In & Out & TCLoginFormProps & InjectedIntlProps;

class TCLoginForm extends React.Component<ILoginFormAllProps, ITCLoginFormState> {

    constructor(props: ILoginFormAllProps) {
        super(props);

        this.state = {
            email: '',
            password: '',
            showPassword: false
        };
    }

    updateState(values: Object) {
        var newState = _.assign(this.state, values);
        this.setState(newState);
    }

    login = async (event: React.FormEvent<any>) => {
        event.preventDefault();
        event.stopPropagation();

        let form = new FormData();
        form.append('email', this.state.email);
        form.append('password', this.state.password);
        try {
            await request
                .post('/home/index')
                .send(form)

            window.location.replace('/');
        }
        catch (err) {
            if (err.status == 401) {
                toast.error("Unable to login. Check your username and password");
            }
            else this.props.dError(err);
        }
    }

    handleChange:any = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.updateState({
            [name]: value
        });
    }

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    render(): JSX.Element {
        const { formatMessage } = this.props.intl;
        const { showPassword } = this.state;
        const { goTo } = this.props;
        let benefits = isBushnell ? messages.createBenefitsBushnell : messages.createBenefitsPrimos;
        return (
            <div className='flex-container-column roundCorners'>
                <form onSubmit={this.login} className="flex-container-column" style={{ marginBottom: '10px' }}>
                    <TextField name='email' onChange={this.handleChange} label={formatMessage(messages.email)} />
                    <TextField name='password' onChange={this.handleChange} type={showPassword ? 'text' : 'password'} label={formatMessage(messages.password)} endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="Toggle password visibility"
                                onClick={this.handleClickShowPassword}
                            >
                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }/>
                    <div className='flex-container' style={{ justifyContent: 'space-between', alignItems: 'center', margin: '10px 0' }}>
                        <div className='flex-container' style={{ alignItems: 'center' }}>
                            <input type='checkbox' name='select' value='valuable' id={"remember-checkbox"} />
                            <label style={{ margin: '0px 5px 0px 0px' }} htmlFor={"remember-checkbox"}></label>
                            <div  style={{ fontSize: '12px', fontWeight: 'bold', color: isBushnell ?  '#333333' : '#FFF'}}>{formatMessage(messages.remember)}</div>
                        </div>
                        <div 
                            className='forgot-password' 
                            style={{ fontSize: '12px', fontWeight: 'bold', color: isBushnell ? 'rgba(0,0,0,0.54)' : 'rgba(255,255,255,0.8)' }}
                            onClick={() => goTo('forgotPassword')}>
                            {formatMessage(messages.forgot)}
                        </div>
                    </div>
                    <button type='submit' className='orangeButton' >{formatMessage(messages.login)}</button>
                    <hr style={{ width: '100%', marginLeft: '-5px', paddingRight: '10px' }} />
                    <a onClick={() => goTo('createAccount')} className='greenButton' style={{ marginBottom: '10px' }} >{formatMessage(messages.createAccount)}</a>
                    <div style={{ textAlign: 'center', fontSize: '12px', color: isBushnell ? '#333333' : '#FFF' }}>{formatMessage(benefits)}</div>
                </form>
            </div>
        );
    }
}

interface In {}

const stateToProps = (state: State):In => ({});

interface Out {
    dError: (err: any) => void;
}

const dToProps = (d: Dispatch<State>): Out => ({
    dError: e => d(error(e))
})

export default connect(stateToProps, dToProps)(injectIntl(TCLoginForm));