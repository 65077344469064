import * as React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { ITCSelectOption } from './TCSelect';
import { WrappedFieldProps } from 'redux-form';

export interface ISimpleSelectProps {
    id: string;
    label: string;
    options: ITCSelectOption[];
    shrink?: boolean;
    disabled?: boolean;
}

export const ReduxSimpleSelect = ({ id, input: { value, onChange }, label, options, shrink, disabled }: ISimpleSelectProps & WrappedFieldProps) => {
    return (
        <div className='flex-container-column create-account-input'> 
            <FormControl> 
                <InputLabel html-for={id} shrink={shrink} >{label}</InputLabel>
                <Select
                    inputProps={{id, name: id}} 
                    value={value}
                    disabled={disabled}
                    onChange={onChange}>
                    {options.map(option => <MenuItem key={option.label} value={option.value}>{option.label}</MenuItem>)}
                </Select>
            </FormControl>
        </div>
    )
}
