import { ITCSelectOption } from '.';
import * as moment from 'moment';

export let stateOptions: ITCSelectOption[] = [];
stateOptions.push({ value: 'AL', label: 'Alabama' });
stateOptions.push({ value: 'AK', label: 'Alaska' });
stateOptions.push({ value: 'AZ', label: 'Arizona' });
stateOptions.push({ value: 'AR', label: 'Arkansas' });
stateOptions.push({ value: 'CA', label: 'California' });
stateOptions.push({ value: 'CO', label: 'Colorado' });
stateOptions.push({ value: 'CT', label: 'Connecticut' });
stateOptions.push({ value: 'DE', label: 'Delaware' });
stateOptions.push({ value: 'DC', label: 'District Of Columbia' });
stateOptions.push({ value: 'FL', label: 'Florida' });
stateOptions.push({ value: 'GA', label: 'Georgia' });
stateOptions.push({ value: 'HI', label: 'Hawaii' });
stateOptions.push({ value: 'ID', label: 'Idaho' });
stateOptions.push({ value: 'IL', label: 'Illinois' });
stateOptions.push({ value: 'IN', label: 'Indiana' });
stateOptions.push({ value: 'IA', label: 'Iowa' });
stateOptions.push({ value: 'KS', label: 'Kansas' });
stateOptions.push({ value: 'KY', label: 'Kentucky' });
stateOptions.push({ value: 'LA', label: 'Louisiana' });
stateOptions.push({ value: 'ME', label: 'Maine' });
stateOptions.push({ value: 'MD', label: 'Maryland' });
stateOptions.push({ value: 'MA', label: 'Massachusetts' });
stateOptions.push({ value: 'MI', label: 'Michigan' });
stateOptions.push({ value: 'MN', label: 'Minnesota' });
stateOptions.push({ value: 'MS', label: 'Mississippi' });
stateOptions.push({ value: 'MO', label: 'Missouri' });
stateOptions.push({ value: 'MT', label: 'Montana' });
stateOptions.push({ value: 'NE', label: 'Nebraska' });
stateOptions.push({ value: 'NV', label: 'Nevada' });
stateOptions.push({ value: 'NH', label: 'New Hampshire' });
stateOptions.push({ value: 'NJ', label: 'New Jersey' });
stateOptions.push({ value: 'NM', label: 'New Mexico' });
stateOptions.push({ value: 'NY', label: 'New York' });
stateOptions.push({ value: 'NC', label: 'North Carolina' });
stateOptions.push({ value: 'ND', label: 'North Dakota' });
stateOptions.push({ value: 'OH', label: 'Ohio' });
stateOptions.push({ value: 'OK', label: 'Oklahoma' });
stateOptions.push({ value: 'OR', label: 'Oregon' });
stateOptions.push({ value: 'PA', label: 'Pennsylvania' });
stateOptions.push({ value: 'RI', label: 'Rhode Island' });
stateOptions.push({ value: 'SC', label: 'South Carolina' });
stateOptions.push({ value: 'SD', label: 'South Dakota' });
stateOptions.push({ value: 'TN', label: 'Tennessee' });
stateOptions.push({ value: 'TX', label: 'Texas' });
stateOptions.push({ value: 'UT', label: 'Utah' });
stateOptions.push({ value: 'VT', label: 'Vermont' });
stateOptions.push({ value: 'VA', label: 'Virginia' });
stateOptions.push({ value: 'WA', label: 'Washington' });
stateOptions.push({ value: 'WV', label: 'West Virginia' });
stateOptions.push({ value: 'WI', label: 'Wisconsin' });
stateOptions.push({ value: 'WY', label: 'Wyoming' });

export const caStateOptions: ITCSelectOption[] = [];
caStateOptions.push({ value: 'AB', label: 'Alberta' });
caStateOptions.push({ value: 'BC', label: 'British Columbia' });
caStateOptions.push({ value: 'MB', label: 'Manitoba' });
caStateOptions.push({ value: 'NB', label: 'New Brunswick' });
caStateOptions.push({ value: 'NL', label: 'Newfoundland and Labrador' });
caStateOptions.push({ value: 'NT', label: 'Northwest Territories' });
caStateOptions.push({ value: 'NS', label: 'Nova Scotia' });
caStateOptions.push({ value: 'NU', label: 'Nunavut' });
caStateOptions.push({ value: 'ON', label: 'Ontario' });
caStateOptions.push({ value: 'PE', label: 'Prince Edward Island' });
caStateOptions.push({ value: 'QC', label: 'Quebec' });
caStateOptions.push({ value: 'SK', label: 'Saskatchewan' });
caStateOptions.push({ value: 'YT', label: 'Yukon' });


export let countryOptions: ITCSelectOption[] = [];
countryOptions.push({ value: 'US', label: 'United States' });
countryOptions.push({ value: 'CA', label: 'Canada' });

export let monthOptions: ITCSelectOption[] = [];
monthOptions.push({ value: 1, label: 'January - 01' });
monthOptions.push({ value: 2, label: 'February - 02' });
monthOptions.push({ value: 3, label: 'March - 03' });
monthOptions.push({ value: 4, label: 'April - 04' });
monthOptions.push({ value: 5, label: 'May - 05' });
monthOptions.push({ value: 6, label: 'June - 06' });
monthOptions.push({ value: 7, label: 'July - 07' });
monthOptions.push({ value: 8, label: 'August - 08' });
monthOptions.push({ value: 9, label: 'September - 09' });
monthOptions.push({ value: 10, label: 'October - 10' });
monthOptions.push({ value: 11, label: 'November - 11' });
monthOptions.push({ value: 12, label: 'December - 12' });

export let yearOptions: ITCSelectOption[] = [];
let year = moment().year();
for (let i = year; i <= year + 30; i++) {
    yearOptions.push({ value: i, label: String(i) });
}