import { combineReducers } from 'redux';
import { routerReducer, RouterState } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

import account, { AccountState } from './account';
import gallery, { GalleryState } from './gallery';
import cameras, { CamerasState } from './cameras';
import media, { MediaState } from './media';
import ui, { IUIState } from './ui';
import billing, { BillingState } from './billing';
import messages, { IMessagesState } from './messages';

export interface State {
    account: AccountState;
    gallery: GalleryState;
    cameras: CamerasState;
    media: MediaState;
    ui: IUIState;
    router: RouterState;
    billing: BillingState;
    messages: IMessagesState;
}

export default combineReducers<State>({
    account,
    gallery,
    cameras,
    media,
    ui,
    billing,
    messages,
    router: routerReducer,
    form: formReducer,
})