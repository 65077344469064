import * as request from 'superagent';

export const api = {
    getDetails: () => (
        request
            .get('/api/account/details/')
    ),
    getLegalData: () => (
        request
            .get('/api/account/LegalData/')
    ),
    getSupplementalData: () => (
        request
            .get('/api/account/SupplementalData')
    ),
    resendConfirmation: () => (
        request
            .post('/api/account/resendemailconfirmation')
    ),
    confirmEmail: (token: string) => (
        request.post('/Home/ConfirmEmail?token=' + token)),
    agreeLegalDocs: (documentIds: string[]) => (
        request
        .post('api/account/agreelegaldocs'))
        .send(documentIds)
}

