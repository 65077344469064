import { PayloadAction } from '../../../Types';
import * as update from 'immutability-helper';

import { ICameraModel, ICameraConfigModel, ICameraDetail, IStreamStatusModel } from '../../Models'

export interface CamerasState {
    cameras: ICameraModel[];
    cameraDetails: ICameraDetail;
    liveStreamStatus: IStreamStatusModel
}

const initialState: CamerasState = {
    cameras: [],
    cameraDetails: {
        camera: null,
        configuration: null
    },
    liveStreamStatus: null
}

export const Actions = {
    ACK_TRACKING: 'camera/ACK_TRACKING',
    ADD_CAMERA: 'camera/ADD_CAMERA',
    DISABLE_TRACKING: 'camera/DISABLE_TRACKING',
    GET_CAMERAS: 'camera/GET_CAMERAS',
    GET_CAMERA_DETAILS: 'camera/GET_CAMERA_DETAILS',
    REMOVE_CAMERA: 'camera/REMOVE_CAMERA',
    SAVE_CAMERA_DETAILS: 'camera/SAVE_CAMERA_DETAILS',
    SET_CAMERAS: 'camera/SET_CAMERAS',
    SET_CAMERA_DETAILS: 'camera/SET_CAMERA_DETAILS',
    START_LIVE_STREAM: 'camera/START_LIVE_STREAM',
    CONTINUE_LIVE_STREAM: 'camera/CONTINUE_LIVE_STREAM',
    STOP_LIVE_STREAM: 'camera/STOP_LIVE_STREAM',
    STATUS_LIVE_STREAM: 'camera/STATUS_LIVE_STREAM',
    SET_STATUS_LIVE_STREAM: 'camera/SET_STATUS_LIVE_STREAM'
}   

export let ackTracking = (payload: number) => ({ type: Actions.ACK_TRACKING, payload });
export let addCamera = (payload: { cameraName: string, cameraSerial: string }) => ({ type: Actions.ADD_CAMERA, payload });
export let disableTracking = (payload: number) => ({ type: Actions.DISABLE_TRACKING, payload });
export let getCameras = () => ({ type: Actions.GET_CAMERAS });
export let getCameraDetails = (payload: number) => ({ type: Actions.GET_CAMERA_DETAILS, payload });
export let removeCamera = (payload: number) => ({ type: Actions.REMOVE_CAMERA, payload });
export let saveCameraDetails = (payload: ICameraConfigModel) => ({ type: Actions.SAVE_CAMERA_DETAILS, payload})
export let setCameras = (payload: ICameraModel[]) => ({ type: Actions.SET_CAMERAS, payload });
export let setCameraDetails = (payload: ICameraDetail) => ({ type: Actions.SET_CAMERA_DETAILS, payload })
export let startLiveStream = (payload: number) => ({ type: Actions.START_LIVE_STREAM, payload })
export let continueLiveStream = (payload: number) => ({ type: Actions.CONTINUE_LIVE_STREAM, payload })
export let stopLiveStream = (payload: number) => ({ type: Actions.STOP_LIVE_STREAM, payload })
export let statusLiveStream = (payload: number) => ({ type: Actions.STATUS_LIVE_STREAM, payload })
export let setStatusLiveStream = (payload: IStreamStatusModel) => ({ type: Actions.SET_STATUS_LIVE_STREAM, payload })


export default (state: CamerasState = initialState, { type, payload }: PayloadAction<any>) => {
    switch (type) {
        case Actions.SET_CAMERAS:
            return update(state, { cameras: { $set: payload } })
        case Actions.SET_CAMERA_DETAILS:
            return update(state, { cameraDetails: { $set: payload } })
        case Actions.SET_STATUS_LIVE_STREAM:
            return update(state, { liveStreamStatus: { $set: payload } })
        default:
            return state;
    }
}

export * from './api';
export * from './sagas';
export * from './selectors';