import * as React from 'react';
import { withRouter, Link, RouteComponentProps } from 'react-router-dom';
import { Col, Glyphicon, Row } from 'react-bootstrap';
import { Form } from 'react-form';
import { defineMessages, injectIntl, InjectedIntlProps } from 'react-intl'
import { toast } from 'react-toastify';
import { connect, Dispatch } from 'react-redux';
import * as request from 'superagent';

import { TextInput, TChr } from '../Elements/';
import { State } from '../../../Types';
import { error } from '../../reducers/sagas';


const appStrings = defineMessages({
    userEmail: {
        id: "TCAdminLogin.useremail.label",
        defaultMessage: "User Email"
    },
    adminEmail: {
        id: "TCAdminLogin.adminemail.label",
        defaultMessage: "Admin Email"
    },
    adminPassword: {
        id: "TCAdminLogin.adminpassword.label",
        defaultMessage: "Admin Password"
    }
}) 

type ITCAdminLogin = In & Out & RouteComponentProps<{token: string}> & InjectedIntlProps;

class TCAdminLogin extends React.Component<ITCAdminLogin, any> {
    submit = async ({ adminPassword }: any) => {
        const { userEmail, adminEmail, userAccountID } = this.parseToken();
        let form = new FormData();
        form.append('userEmail', userEmail);
        form.append('userAccountID', userAccountID);
        form.append('adminEmail', adminEmail);
        form.append('adminPassword', adminPassword);
        
        try {
            let result = await request
                .post(`/Home/WebLoginFromAdmin`)
                .send(form);

            if (result.ok) {
                window.location.replace('/');
            }
        }
        catch (err) {
            console.log(err);
            toast.error("Unable to login. Check your password");
        }
    }

    parseToken = () => {
        const { token } = this.props.match.params;
        let decodedData = atob(token);
        let splitData = decodedData.split(':');

        return { adminEmail: splitData[0], userAccountID: splitData[1], userEmail: splitData[2]};
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { userEmail, adminEmail } = this.parseToken();

        return (
            <div className='container page-container'>
                <Row>
                    <Col xs={12} style={{marginBottom: '10px'}}>
                        <div className='textHeader'>Login as User</div>
                        <TChr />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} >
                        <div className='box'>
                            <Form
                                onSubmit={this.submit}>
                                {(formApi: any) => {
                                    return <form>
                                        <TextInput field='userEmail' label={formatMessage(appStrings.userEmail)} type='text' value={userEmail} disabled />
                                        <TextInput field='adminEmail' label={formatMessage(appStrings.adminEmail)} type='text' value={adminEmail} disabled />
                                        <TextInput field='adminPassword' label={formatMessage(appStrings.adminPassword)} type='password' />
                                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <div className='orangeButton' onClick={formApi.submitForm} style={{flex: 1}}>Login</div>
                                        </div>
                                    </form>
                                }}
                            </Form>
                        </div>
                   </Col>
                </Row>
            </div>
        )
    }
}

interface In {}

const stateToProps = (state: State):In => ({});

interface Out {
    dError: (err: any) => void;
}

const dToProps = (d: Dispatch<State>): Out => ({
    dError: e => d(error(e))
})

export default connect(stateToProps, dToProps)(withRouter(injectIntl(TCAdminLogin)));