import * as React from 'react';
import { fill, find, flatten, get, groupBy, map, orderBy, set, throttle, toPairs } from 'lodash';
import * as moment from 'moment';

import { Fade } from './index'; // For some reason importing just ./Fade results in a lowercase 'e' in Elements
import { TCMediaCount } from 'tcmediamanager';

const SCROLLBAR_TIMEOUT = 3000

interface ITCMediaCount extends TCMediaCount {
}

interface ScrollbarProps {
    count: number,
    monthCounts: TCMediaCount[]
    height: number
    scrollPosition: number
    onClick: (e: React.SyntheticEvent<any>)=>void
}

interface ScrollbarState {
    date: string
    show: boolean
    dragging: boolean
    hovering: boolean
    hoverPosition: number
}

const initialState: ScrollbarState = {
    date: 'Jan 1970',
    show: false,
    dragging: false,
    hovering: false,
    hoverPosition: 0
}

const scrollTransitionStyles = {
    entering: {opacity: 1},
    entered: {opacity: 1},
    exiting: {opacity: 0},
    exited: {opacty: 0}
}

class Scrollbar extends React.Component<ScrollbarProps, ScrollbarState> {
    private timeout: number;
    private dateLookup: string[];
    private throttledOnClick: (e: React.SyntheticEvent<any>) => void;

    constructor(props: ScrollbarProps) {
        super(props);

        this.state = initialState;

        this.throttledOnClick = throttle(props.onClick, 17)
    }

    componentWillUpdate(props: ScrollbarProps) {
        this.dateLookup = flatten(orderBy(props.monthCounts, ['year', 'month'], ['desc', 'desc']).map((count: any) => {
            return fill(new Array(count.count), moment(`${count.month} ${count.year}`, 'M YYYY').format('MMM YY'))
        }))
    }

    onMouseDown(e: React.MouseEvent<any>) {
        if (e.button !== 0) return;
        
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(e);
        
        let clickHeight = e.pageY - 72 - 30;
        if (clickHeight < 0) clickHeight = 0;

        let maxHeight = this.props.height - 30;
        if (clickHeight > maxHeight) clickHeight = maxHeight;
       
        let index = Math.floor((clickHeight/(this.props.height-30))*this.dateLookup.length);
        if (index >= this.dateLookup.length) index = this.dateLookup.length - 1;
        
        let date = this.dateLookup[index]
        this.setState({...this.state, dragging: true, date})
    }

    onMouseUp() {
        this.setState({...this.state, dragging: false})
    }

    onMouseMove(e: React.MouseEvent<any>) {
        e.persist();
        let hoverHeight = e.pageY - 72 - 15;
        if (hoverHeight < 15) hoverHeight = 15;
        
        let maxHeight = this.props.height - 30;
        if (hoverHeight > maxHeight) hoverHeight = maxHeight;
        
        let index = Math.floor((hoverHeight/maxHeight)*this.dateLookup.length);
        if (index >= this.dateLookup.length) index = this.dateLookup.length - 1;
       
        let date = this.dateLookup[index];
        this.setState({...this.state, hoverPosition: hoverHeight, date});
        if (!this.state.dragging) return;
        e.preventDefault();
        e.stopPropagation();

        this.throttledOnClick(e);
    }

    onMouseLeave() {
        clearTimeout(this.timeout)
        this.setState({...this.state, hovering: false}, () => {
            this.timeout = setTimeout(() => this.setState({...this.state, show: false}), SCROLLBAR_TIMEOUT) as any;
        })
    }

    onMouseEnter(e: React.MouseEvent<any>) {
        clearTimeout(this.timeout);
        let hoverHeight = e.pageY - 72 - 15;
        if (hoverHeight < 0) hoverHeight = 0
        this.setState({...this.state, show: true, hovering: true, hoverPosition: hoverHeight})
    }

    showScrollbar() {
        clearTimeout(this.timeout)
        this.setState({...this.state, show: true}, () => {
            if (!this.state.hovering) this.timeout = setTimeout(() => this.setState({...this.state, show: false}), SCROLLBAR_TIMEOUT) as any;
        })
    }

    scrollbarSections(monthCounts: TCMediaCount[], totalCount: number) {
        let months = orderBy(monthCounts, ['year', 'month'], ['desc', 'desc']);
        let groupedMonths = groupBy(months, (month) => month.year);
        let years = map(groupedMonths, (val, key) => {
            let count = 0;
            val.forEach((month) => count += month.count)
            return {
                year: key,
                months: val,
                count
            }
        });
        years = orderBy(years, ['year'], ['desc']);

        return map(years, year => {
            return <div key={year.year} style={{position: 'relative', flex: year.count, minHeight: '25px', width: '100%'}}>
                <h6 className='scrollbar-year-header'>{year.year.toString()}</h6>
            </div>
        })

    }

    render(): JSX.Element {
        const { count, monthCounts, height, scrollPosition } = this.props;
        const { date, show, hovering, hoverPosition, dragging } = this.state;   
        
        return (
            <div 
                className='scrollbar-container'
                style={{
                    left: dragging ? 0 : null,
                    width: dragging ? 'auto' : '50px',
                }}
                onMouseEnter={(e) => this.onMouseEnter(e)}
                onMouseMove={(e) => this.onMouseMove(e)}
                onMouseDown={(e) => this.onMouseDown(e)}
                onMouseUp={() => this.onMouseUp()}
                onMouseLeave={() => this.onMouseLeave()}>
                <Fade 
                    inProp={show} 
                    timeout={300}
                    defaultStyle={{
                        display: 'flex',
                        flex: 1
                    }}>
                    <div className='scrollbar' key='scrollbar'>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: '10px 0' }}>
                                {this.scrollbarSections(monthCounts, count)}
                                <div className='scrollbar-cursor' style={{ top: `${scrollPosition + 15}px` }} />
                                <Fade inProp={hovering} timeout={300} appear={true}>
                                    <div className='scrollbar-date' style={{ top: `${hoverPosition}px` }} >
                                        <h6 className='scrollbar-date_text'>{date}</h6>
                                    </div>
                                </Fade>
                                <Fade inProp={hovering} timeout={300}>
                                    <div className='scrollbar-cursor_hover' style={{ top: `${hoverPosition}px` }} />
                                </Fade>
                            </div>
                        </div>
                </Fade>
            </div>
        )
    }
}

export default Scrollbar;