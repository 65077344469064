import { PayloadAction } from '../../../Types';
import * as update from 'immutability-helper';

import { IMessageModel } from '../../Models';

export interface IMessagesState {
    messages: IMessageModel[];
}

const initialState: IMessagesState = {
    messages: []
}

export const Actions ={
    GET_MESSAGES: 'messages/GET_MESSAGES',
    READ_MESSAGE: 'messages/READ_MESSAGE',
    SET_MESSAGES: 'messages/SET_MESSAGES',
}

export let getMessages = () => ({ type: Actions.GET_MESSAGES });
export let readMessage= (payload: IMessageModel) => ({ type: Actions.READ_MESSAGE, payload});
export let setMessages = (payload: IMessageModel[]) => ({ type: Actions.SET_MESSAGES, payload });

export default (state: IMessagesState = initialState, { type, payload }: PayloadAction<any>) => {
    switch (type) {
        case Actions.SET_MESSAGES:
            return update(state, { messages: { $set: payload } })
        default:
            return state;
    }
}

export * from './api';
export * from './sagas';