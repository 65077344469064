﻿// All JavaScript in here will be loaded client/server -side.
// Expose components globally so ReactJS.NET can use them

// import './css/bootstrap.css';
// import './css/bootstrap-theme.css';
import 'react-toastify/dist/ReactToastify.min.css' 
import './css/main.scss';
import './css/klavika.css';
import './css/sheepman.css';

// Expose React and ReactDOM for use with ReactJS.NET
var ReactDom = require('expose-loader?exposes=ReactDOM!react-dom');
var React = require('expose-loader?exposes=React!react');

// Expose Login for bootstrapping page from bundle
var Login = require('expose-loader?exposes=Login!./Components/Login/index.tsx');