import * as React from 'react';
import * as request from 'superagent';
import { toast } from 'react-toastify';

interface ShareFacebookProps {
    isPicture: boolean
    selected: boolean
    url: string
    clearState: ()=>void
    onShare: ()=>void
}

// Facebook initialized through javascript on the page
declare var FB: any;


export class ShareFacebook extends React.Component<ShareFacebookProps, any> {
    constructor(props: ShareFacebookProps) {
        super(props);

        this.state = {
            at: null,
            userID: null
        }
    }
    clearState() {
        this.setState({at: null, userID: null});
        this.props.clearState();
    }

    share()  {
        const { onShare } = this.props;
        FB.login((loginRes: any) => {
            if (!loginRes.authResponse) return;
            let at = loginRes.authResponse.accessToken;
            let userID = loginRes.authResponse.userID;
            if (at && userID) {
                onShare();
                this.setState({at, userID})
            };
        }, {scope: 'publish_actions'})
    };

    postToFacebook() {
        const { isPicture, url, clearState } = this.props;
        const { at , userID } = this.state;
        if (isPicture) {
            FB.api(
                "me/photos",
                "POST",
                {"url": url},
                (response: any) => this.clearState()
            )
        } else {
            request
                .post(`https://graph-video.facebook.com/v2.3/${userID}/videos`)
                .query({ accessToken: at })
                .query({ file_url: encodeURIComponent(url) })
                .then(this.clearState)
                .catch(err => toast.error("Error posting to facebook"))
        }
    }
    render() {
        const {isPicture, selected, url, onShare, clearState} = this.props;
    
        return (
            <div>
                {!selected && <img className='clickable' src="/images/facebook.png" alt="" height='40px' width='40px' onClick={() => this.share()}/>}
                {selected && 
                    <div style={{width: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <h4>{`Post this ${isPicture ? 'picture' : 'video'} to Facebook?`}</h4>
                        <div style={{display: 'flex', alignSelf: 'stretch'}} >
                            <a style={{flex: 1}} className='orangeButton' onClick={this.postToFacebook.bind(this)}>Yes</a>
                            <a style={{flex: 1}} className='whiteButton' onClick={clearState}>No</a>
                        </div>
                    </div>}
            </div>
        )
    }
}