import * as React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { FormField } from 'react-form';
import { ITCSelectOption } from './TCSelect';

interface ISimpleSelectProps {
    field: string;
    id: string;
    label: string;
    options: ITCSelectOption[];
    shrink?: boolean;
    fieldApi?: any;
}

const SimpleSelectWrapper = ({ field, fieldApi,  id, label, options, shrink}: ISimpleSelectProps) => {
    const { getValue, setValue} = fieldApi;
    return (
        <div className='flex-container-column create-account-input'> 
            <FormControl> 
                <InputLabel html-for={id} shrink={shrink} >{label}</InputLabel>
                <Select
                    inputProps={{id, name: id}} 
                    value={getValue()}
                    onChange={(e: React.SyntheticEvent<any>) => setValue((e.target as any).value)}>
                    {options.map(option => <MenuItem key={option.label} value={option.value}>{option.label}</MenuItem>)}
                </Select>
            </FormControl>
        </div>
    )
}

export const SimpleSelect = FormField(SimpleSelectWrapper)