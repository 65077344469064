import * as update from 'immutability-helper';

import { IAccountDetailsModel } from "../../Models";
import { PayloadAction } from "../../../Types";
import { ILegalDocumentResult } from '../../Models/ILegalDocumentResult';

export interface AccountState extends IAccountDetailsModel {};

const initialState: AccountState = {
    email: '',
    firstName: '',
    lastName: '',
    mobileNumber: '',
    primaryContactNumber: '',
    emailConfirmed: true,
    totalSupplementalData: 0,
    remainingSupplementalData: 0,
    totalSupplementalThumbnails: 0,
    remainingSupplementalThumbnails: 0,
    totalDataPlanBytes: 0,
    totalUsedDataPlanBytes: 0,
    highResTokens: 0,
    legalDocumentData: null,
}

export const Actions = {
    GET_DETAILS: 'account/GET_DETAILS',
    GET_SUPPLEMENTAL_DATA: 'account/GET_SUPPLEMENTAL_DATA',
    GET_LEGAL_DATA: 'account/GET_LEGAL_DATA',
    RESEND_CONFIRMATION_EMAIL: 'account/RESEND_CONFIRMATION_EMAIL',
    CONFIRM_EMAIL: 'account/CONFIRM_EMAIL',
    SET_DETAILS: 'account/SET_DETAILS',
    SET_LEGAL_DATA: 'account/SET_LEGAL_DATA',
    SET_EMAIL_CONFIRMED: 'account/SET_EMAIL_CONFIRMED',
    SET_SUPPLEMENTAL_DATA: 'account/SET_SUPPLEMENTAL_DATA',
    AGREE_LEGAL_DOCS: 'account/AGREE_LEGAL_DOCS',
}

export const getDetails = () => ({ type: Actions.GET_DETAILS });
export const getSupplementalData = () => ({ type: Actions.GET_SUPPLEMENTAL_DATA });
export const getLegalData = () => ({ type: Actions.GET_LEGAL_DATA });
export const resendConfirmationEmail = () => ({ type: Actions.RESEND_CONFIRMATION_EMAIL });
export const confirmEmail = (token: string) => ({ type: Actions.CONFIRM_EMAIL, payload: token });
export const setEmailConfirmed = (confirmed: boolean) => ({ type: Actions.SET_EMAIL_CONFIRMED, payload: confirmed });
export const setDetails = (payload: IAccountDetailsModel) => ({ type: Actions.SET_DETAILS, payload });
export const setLegalData = (payload: ILegalDocumentResult[]) => ({ type: Actions.SET_LEGAL_DATA, payload });
export const setSupplementalData = (payload: { supplementalData: number, supplementalThumbnails: number }) => ({ type: Actions.SET_SUPPLEMENTAL_DATA, payload });
export const agreeLegalDocs = (payload: string[]) => ({ type: Actions.AGREE_LEGAL_DOCS, payload });

export default (state: AccountState = initialState, { type, payload }: PayloadAction<any>) => {
    switch (type) {
        case Actions.SET_DETAILS:
            return update(state, {
                email: { $set: payload.email },
                firstName: { $set: payload.firstName },
                lastName: { $set: payload.lastName },
                mobileNumber: { $set: payload.mobileNumber },
                primaryContactNumber: { $set: payload.primaryContactNumber },
                emailConfirmed: { $set: payload.emailConfirmed }
            })

        case Actions.SET_LEGAL_DATA:
            return update(state, {
                legalDocumentData: {$set: payload}
            })
        
        case Actions.SET_SUPPLEMENTAL_DATA:
            return update(state, {
                totalSupplementalData: { $set: payload.totalSupplementalData },
                remainingSupplementalData: { $set: payload.remainingSupplementalData },
                totalSupplementalThumbnails: { $set: payload.totalSupplementalThumbnails },
                remainingSupplementalThumbnails: { $set: payload.remainingSupplementalThumbnails },
                totalDataPlanBytes: { $set: payload.totalDataPlanBytes },
                totalUsedDataPlanBytes: { $set: payload.totalUsedDataPlanBytes },
                highResTokens: { $set: payload.highResTokens }
            })

        case Actions.SET_EMAIL_CONFIRMED:
            return update(state, {
                emailConfirmed: { $set: payload }
            })
            
        default:
            return state;
    }
}

export * from './api';
export * from './sagas';