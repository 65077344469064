import * as React from 'react';

import { Star } from './index';

interface StarRatingsProps {
    rating: number
    size?: number
    onRate: (rating: number)=>void
}

interface StarRatingsState {
    currentRating: number
    hovering: boolean;
}

export class StarRatings extends React.Component<StarRatingsProps, StarRatingsState> {
    constructor(props: StarRatingsProps) {
        super(props);

        this.state = {
            currentRating: props.rating,
            hovering: false
        }
    }

    componentWillReceiveProps(props: StarRatingsProps) {
        this.setState({...this.state, currentRating: props.rating})
    }

    onHoverEnter(rating: number) {
        this.setState({...this.state, currentRating: rating, hovering: true})
    }

    onHoverLeave() {
        this.setState({...this.state, currentRating: this.props.rating, hovering: false})
    }

    render() {
        const { onRate, size=14 } = this.props;
        const { currentRating, hovering } = this.state;
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Star 
                    rated={currentRating > 0} 
                    hovering={hovering}
                    size={size}
                    onClick={onRate.bind(this, 1)} 
                    onHoverEnter={this.onHoverEnter.bind(this, 1)} 
                    onHoverLeave={this.onHoverLeave.bind(this)}/>
                <Star 
                    rated={currentRating > 1} 
                    hovering={hovering}
                    size={size}
                    onClick={onRate.bind(this, 2)} 
                    onHoverEnter={this.onHoverEnter.bind(this, 2)} 
                    onHoverLeave={this.onHoverLeave.bind(this)}/>
                <Star 
                    rated={currentRating > 2} 
                    hovering={hovering}
                    size={size}
                    onClick={onRate.bind(this, 3)} 
                    onHoverEnter={this.onHoverEnter.bind(this, 3)} 
                    onHoverLeave={this.onHoverLeave.bind(this)}/>
                <Star 
                    rated={currentRating > 3} 
                    hovering={hovering}
                    size={size}
                    onClick={onRate.bind(this, 4)} 
                    onHoverEnter={this.onHoverEnter.bind(this, 4)} 
                    onHoverLeave={this.onHoverLeave.bind(this)}/>
                <Star 
                    rated={currentRating > 4} 
                    hovering={hovering}
                    size={size}
                    onClick={onRate.bind(this, 5)} 
                    onHoverEnter={this.onHoverEnter.bind(this, 5)} 
                    onHoverLeave={this.onHoverLeave.bind(this)}/>
            </div>
        )
    }
}