export interface IItem {
    itemID: number;
    active: boolean;
    name: string;
    description: string;
    price: number;
    itemType: ItemType;
    ultracartItemID: string;
    durationMonths?: number;
    thumbnails?: number;
    durationDays?: number;
    quantity?: number;
    isRecurring?: boolean;
    bytes?: number;
}

enum ItemType {
    HighResUpload = 1,
    PhotoPack = 2,
    DataPlan = 3,
    CancelDataPlan = 4,
    NoDataPlan = 5,
    UndoCancelDataPlan = 6,
    CustomDataPlan = 7,
    MaintenancePlan = 8,
    DataPlanUpgrade=9
}