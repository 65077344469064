
import { IDataPlanPricingModel } from '../../../Models';
import { IntlProvider } from 'react-intl';

const { intl } = new IntlProvider({ locale: 'en' }, {}).getChildContext();
const { formatNumber } = intl;

const currencyStyle = {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol'
}

export const dataPlans: IDataPlanPricingModel = {
    title: 'Data Plans',
    features: ['Thumbnails'],
    yearly: false,
    plans: [
        {
            id: '2000TN',
            altId: '37',
            amount: 2000,
            title: '2000 Thumbnails',
            monthlyPrice: 9.99,
            yearlyPrice: 99.00,
            features: ['2000']
        },
        {
            id: 'UNLIMITED',
            amount: 1000,
            title: 'Unlimited',
            monthlyPrice: 14.99,
            yearlyPrice: 120.00,
            features: ['Unlimited']
        }
    ]
}

export const mbDataPlans: IDataPlanPricingModel = {
    title: 'Data Plans',
    features: ['Approx. # of Images'],
    plans: [
        {
            id: '250MB',
            amount: 250,
            title: '250MB',
            monthlyPrice: 9.99,
            yearlyPrice: 99.00,
            features: [9800]
        },
        {
            id: '500MB',
            amount: 500,
            title: '500MB',
            monthlyPrice: 14.99,
            yearlyPrice: 120.00,
            features: [19600]
        },
        {
            id: '1GB',
            amount: 1000,
            title: '1GB',
            monthlyPrice: 19.99,
            yearlyPrice: 160.00,
            features: [39200]
        }
    ]
}