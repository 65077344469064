import { PayloadAction } from '../../../Types';
import * as update from 'immutability-helper';
import { without } from 'lodash';

export interface GalleryState {
    count: number
    grid: boolean;
    scrollbarPosition: number;
    scrollHeight: number;
    selectedMedia: number[];
    selecting: boolean;
}

const initialState: GalleryState = {
    count: 0,
    grid: true,
    scrollbarPosition: 0,
    scrollHeight: 0,
    selectedMedia: [],
    selecting: false
}

export const ADD_SELECTED = 'gallery/ADD_SELECTED';
export const CLEAR_SELECTED = 'gallery/CLEAR_SELECTED';
export const DELETE_SELECTED = 'gallery/DELETE_SELECTED';
export const REMOVE_UNSELECTED = 'gallery/REMOVE_UNSELECTED';
export const RESET_COUNT = 'gallery/RESET_COUNT';
export const RETRIEVE_COUNT = 'gallery/RETRIEVE_COUNT';
export const SET_COUNT = 'gallery/SET_COUNT';
export const SET_GRID = 'gallery/SET_GRID';
export const SET_SCROLLBAR_POSITION = 'gallery/SET_SCROLLBAR_POSITION';
export const SET_SCROLL_HEIGHT = 'gallery/SET_SCROLL_HEIGHT';
export const SELECT = 'gallery/SELECT';
export const SET_SELECTING = 'gallery/SET_SELECTING';
export const UNSELECT = 'gallery/UNSELECT';

export const addSelected = (id: number) => ({type: ADD_SELECTED, payload: id})
export const clearSelected = () => ({type: CLEAR_SELECTED})
export const deleteSelected = () => ({type: DELETE_SELECTED})
export const removeUnselected = (id: number) => ({type: REMOVE_UNSELECTED, payload: id})
export const resetCount = () => ({type: RESET_COUNT})
export const retrieveCount = () => ({type: RETRIEVE_COUNT})
export const setCount = (count: number) => ({type: SET_COUNT, payload: count})
export const setGrid = (grid: boolean) => ({type: SET_GRID, payload: grid});
export const setScrollbarPosition = (position: number) => ({type: SET_SCROLLBAR_POSITION, payload: position})
export const setScrollHeight = (height: number) => ({type: SET_SCROLL_HEIGHT, payload: height})
export const select = (id: number) => ({type: SELECT, payload: id})
export const setSelecting = (selecting: boolean) => ({type: SET_SELECTING, payload: selecting})
export const unselect = (id: number) => ({type: UNSELECT, payload: id})

export default (state: GalleryState = initialState, { type, payload }: PayloadAction<any>) => {
    switch(type) {
        case ADD_SELECTED:
            return update(state, {selectedMedia: {$push: [payload]}})
        case CLEAR_SELECTED:
            return update(state, {selectedMedia: {$set: []}, selecting: {$set: false}})
        case REMOVE_UNSELECTED:
            let { selectedMedia } = state;
            let newSelectedMedia = without(selectedMedia, payload);
            return update(state, {selectedMedia: {$set: newSelectedMedia}});
        case SET_COUNT:
            return update(state, {count: {$set: payload}});
        case SET_GRID:
            return update(state, {grid: {$set: payload}})
        case SET_SCROLLBAR_POSITION:
            return update(state, {scrollbarPosition: {$set: payload}})
        case SET_SCROLL_HEIGHT:
            return update(state, {scrollHeight: {$set: payload}})
        case SET_SELECTING:
            return update(state, {selecting: {$set: payload}})
        default:
            return state;
    }
}