import * as React from 'react';
import { Motion, spring } from 'react-motion';
import { CSSTransition, TransitionGroup, Transition } from 'react-transition-group';
import { get } from 'lodash';
import TCLoginForm from './TCLoginForm';
import TCCreateAccount from './TCCreateAccount';
import TCForgotPassword from './TCForgotPassword';

interface TCLoginContainerProps {}

interface TCLoginContainerState {
    height: number,
    width: number,
    route: {
        login: boolean,
        createAccount: boolean,
        forgotPassword: boolean
    }
}

const defaultStyle = {
    transition: 'opacity .15s ease-in',
    opacity: 0
}

const transitionStyles: any = {
    entering: {opacity: 0},
    entered: {opacity: 1},
    exiting: {opacity: 0},
    exited: {opactiy: 0}
}

const routeSizes = {
    login: {
        height: 460,
        width: 360
    },
    createAccount: {
        height: 515,
        width: 320
    },
    forgotPassword: {
        height: 200,
        width: 450
    }
}

const emptyRoute = {
    login: false,
    createAccount: false,
    forgotPassword: false
}

class TCLoginContainer extends React.Component<TCLoginContainerProps, TCLoginContainerState> {
    constructor(props: TCLoginContainerProps) {
        super(props);

        this.state = {
            height: 386,
            width: 360,
            route: {
                login: true,
                createAccount: false,
                forgotPassword: false
            }
        }
    }

    goTo(route: string) {
        const routeSize = get(routeSizes, route) as {width: number, height: number};
        const { width, height } = routeSize;
        this.setState({...this.state, height, width, route: emptyRoute }, () => {
            setTimeout(() => {this.setState({...this.state, route: {...this.state.route, [route]: true}})}, 200)
        })
    }
    render() {
        const { height, width, route } = this.state;
        const { login, createAccount, forgotPassword } = route;
        return (
            <Motion style={{
                height: spring(height),
                width: spring(width)
            }}>
                {({height, width}: {height: number, width: number }) =>
                    <div className='shadow login-container' style={{height: `${height}px`, width: `${width}px`}}>
                        <Transition in={login} timeout={150} mountOnEnter={true} unmountOnExit={true} appear={true}>
                            {(state: any) => 
                                <div style={{...defaultStyle, ...transitionStyles[state]}}>
                                    <TCLoginForm goTo={this.goTo.bind(this)} />    
                                </div> }
                        </Transition>
                        <Transition in={createAccount} timeout={150} mountOnEnter={true} unmountOnExit={true} appear={true}>
                            {(state: any) => 
                                <div style={{...defaultStyle, ...transitionStyles[state]}}>
                                    <TCCreateAccount goTo={this.goTo.bind(this)} />    
                                </div> }
                        </Transition>
                        <Transition in={forgotPassword} timeout={150} mountOnEnter={true} unmountOnExit={true} appear={true}>
                            {(state: any) => 
                                <div style={{...defaultStyle, ...transitionStyles[state]}}>
                                    <TCForgotPassword goTo={this.goTo.bind(this)} />    
                                </div> }
                        </Transition>
                    </div>
                }
            </Motion>
        )
    }
}

export default TCLoginContainer;