import { delay } from 'redux-saga';
import { get } from 'lodash';
import { put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { push } from 'react-router-redux';
import { cloneDeep, find } from 'lodash';

import { Actions, api, setCameras, setCameraDetails, getCameras, setStatusLiveStream } from './index';
import { PayloadAction } from '../../../Types/index';
import { toggleLoading } from '../ui';
import { ICameraDetail, ICameraConfigModel, IStreamStatusModel } from '../../Models';
import { State } from '../index';
import { error } from '../sagas';
import { activateFreePlan } from '../billing';

const cameraIDSelector = (state: State) => state.cameras.cameraDetails.camera.cameraID;

function* onAckTracking({ type, payload }: PayloadAction<number>) {
    try {
        yield api.setTrackingMode(payload, true);
        toast("Tracking mode acknowledged");
        const state: State = yield select()
        let cameras = cloneDeep(state.cameras.cameras);
        let camera = find(cameras, camera => camera.cameraID == payload);
        camera.trackingAcknowledged = true;

        yield put(setCameras(cameras));
    }
    catch(err) {
        console.log('Error in onAckTracking: ' + err)
        yield put(error(err));
    }
}

// Worker saga will fetch cameras
function* onAddCamera({ type, payload }: PayloadAction<{cameraName: string, cameraSerial: string}>) {
    try {
        const resp = yield api.addCamera(payload);
        const cameraId = get(resp, 'body.cameraID') as number;
        if (cameraId) {
            yield api.setConnected(cameraId, true);
            yield api.setDeployed(cameraId, true);
        }
        const freePlanAvailable = get(resp, 'body.freePlanAvailable', false);
        toast('Camera sucessfully added');

        yield* onGetCameras();
        if (freePlanAvailable) {
            yield put(activateFreePlan(resp.body.cameraID));
        } else {
            yield put(push('/cameras'));
        }
    }
    catch(err) {
        console.log('Error in onAddCamera: ' + err)
        yield put({type: 'ERROR', payload: err});
    }
}

function* onDisableTracking({ payload }: PayloadAction<number>) {
    try {
        yield api.setTrackingMode(payload, false);

        toast("Tracking mode disabled");
        const state: State = yield select()
        let cameras = cloneDeep(state.cameras.cameras);
        let camera = find(cameras, camera => camera.cameraID == payload);
        camera.trackingMode = false;
        camera.trackingAcknowledged = true;
        camera.trackingActive = false;

        yield put(setCameras(cameras));
    }
    catch(err) {
        console.log('Error in onDisableTracking: ' + err)
        yield put(error(err));
    }
}

function* onGetCameras() {
    yield put(toggleLoading(['background']))
    try {
        let res = yield api.getCameras();
        let cameras = res.body;

        yield put(setCameras(cameras));
    } 
    catch (err) {
        console.log('Error in onGetCameras: ' + err)
        yield put(error(err))
    }
    finally {
        yield put(toggleLoading(['background']))
    }
}

function* onGetCameraDetails({ payload }: PayloadAction<number>) {
    yield put(toggleLoading(['background']));
    try {
        let res = yield api.getCameraDetails(payload);
        const details: ICameraDetail = res.body;
        
        yield put(setCameraDetails(details));
    }
    catch(err) {
        console.log('Error in onGetCameraSettings: ' + err);
        yield put(error(err));
    }
    finally {
        yield put(toggleLoading(['background']));
    }
}

function* onRemoveCamera({ type, payload }: PayloadAction<number>) {
    yield put(toggleLoading(['background']));
    try {
        let res = yield api.removeCamera(payload);

        toast('Camera has been removed from your account');
        yield put(push('/cameras'))
    }
    catch(err) {
        console.log('Error in onRemoveCamera: ' + err);
        yield put(error(err))
    }
    finally {
        yield put(toggleLoading(['background']));
    }
}

function* onSaveCameraDetails({ type, payload }: PayloadAction<ICameraConfigModel>) {
    yield put(toggleLoading(['button']));
    try {
        const cameraID = yield select(cameraIDSelector);
        let res = yield api.saveCameraDetails(cameraID, payload);

        toast('Camera settings have been saved');
        yield put(push('/cameras'));
    }
    catch(err) {
        console.log('Error in onSaveCameraDetails');
        yield put(error(err))
    }
    finally {
        yield put(toggleLoading(['button']));
    }
}

function* onStartLiveStream({ type, payload }: PayloadAction<number>) {
    yield put(toggleLoading(['button']));
    try {
        let res = yield api.startLiveStream(payload);

        toast('Live Stream Requested');
    }
    catch (err) {
        console.log('Error in onStartLiveStream: ' + err);
        yield put(error(err))
    }
    finally {
        yield put(toggleLoading(['button']));
    }
}

function* onContinueLiveStream({ type, payload }: PayloadAction<number>) {
    yield put(toggleLoading(['button']));
    try {
        let res = yield api.continueLiveStream(payload);

        toast('Live Stream Continue Requested');
    }
    catch (err) {
        console.log('Error in onContinueLiveStream: ' + err);
        yield put(error(err))
    }
    finally {
        yield put(toggleLoading(['button']));
    }
}

function* onStopLiveStream({ type, payload }: PayloadAction<number>) {
    yield put(toggleLoading(['button']));
    try {
        let res = yield api.stopLiveStream(payload);

        toast('Live Stream Stop Requested');
    }
    catch (err) {
        console.log('Error in onStopLiveStream: ' + err);
        yield put(error(err))
    }
    finally {
        yield put(toggleLoading(['button']));
    }
}

function* onStatusLiveStream({ type, payload }: PayloadAction<number>) {
    yield put(toggleLoading(['button']));
    try {
        yield put(setStatusLiveStream(null));

        let res = yield api.statusLiveStream(payload);
        const streamStatus: IStreamStatusModel = res.body;

        yield put(setStatusLiveStream(streamStatus));
    }
    catch (err) {
        console.log('Error getting stream status: ' + err);
        yield put(error(err))
    }
    finally {
        yield put(toggleLoading(['button']));
    }
}

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export function* watchGetCamera() {
    yield takeEvery(Actions.ACK_TRACKING, onAckTracking);
    yield takeEvery(Actions.ADD_CAMERA, onAddCamera);
    yield takeEvery(Actions.DISABLE_TRACKING, onDisableTracking);
    yield takeLatest(Actions.GET_CAMERAS, onGetCameras);
    yield takeEvery(Actions.GET_CAMERA_DETAILS, onGetCameraDetails);
    yield takeLatest(Actions.REMOVE_CAMERA, onRemoveCamera);
    yield takeLatest(Actions.SAVE_CAMERA_DETAILS, onSaveCameraDetails);
    yield takeEvery(Actions.START_LIVE_STREAM, onStartLiveStream);
    yield takeEvery(Actions.CONTINUE_LIVE_STREAM, onContinueLiveStream);
    yield takeEvery(Actions.STOP_LIVE_STREAM, onStopLiveStream);
    yield takeEvery(Actions.STATUS_LIVE_STREAM, onStatusLiveStream);
}