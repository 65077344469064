import * as React from 'react'

interface TChrProps {
    style?: any
}

export const TChr = ({ style={} }: TChrProps) => {
    return (
        <div style={{ position: 'relative', marginTop: '5px', marginBottom: '15px', width: '100%', ...style }}>
            <hr style={{ width: '100%', marginTop: '0px', marginBottom: '0px', borderTop: '1px solid #757D91' }} />
            <hr style={{ position: 'absolute', left: 0, top: 0, width: '29px', marginTop: '0px', marginBottom: '0px', borderTop: '1px solid #F66E32' }} />
        </div>
    );
}
