import * as React from 'react';
import { connect, Dispatch } from 'react-redux';
import { reduxForm, Field, InjectedFormProps, GenericField, formValueSelector } from 'redux-form';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { Col, Row } from 'reactstrap';
import Typography from '@material-ui/core/Typography';
import { ArrowBack } from '@material-ui/icons';
import { State } from '../../reducers';

import { LoadingButton, ReduxSimpleSelect, ReduxTextInput, IReduxCheckboxProps, IReduxTextInputProps, ISimpleSelectProps } from '.';
import appStrings from './appStrings';
import { countryOptions, stateOptions, caStateOptions } from './options';

interface IBillingFormProps {
    billings?: any[];
    loading?: boolean;
    saveButton?: boolean;
    onBackClick?: () => void;
    onSave?: () => void;
}

const TextInputField = Field as new() => GenericField<IReduxTextInputProps>;
const SelectField = Field as new () => GenericField<ISimpleSelectProps>; 

const selector = formValueSelector('billing');


const BillingFormWrapper = ({ billings, intl: { formatMessage }, loading, saveButton, onBackClick, onSave, billing }: In & Out & IBillingFormProps & InjectedFormProps & InjectedIntlProps) => {
    const countryCode = billing?.countryCode;
    return (
        <form>
            <Row>
                <Col xs={12} sm={6} >
                    <TextInputField 
                        name='firstName'
                        component={ReduxTextInput}
                        label={formatMessage(appStrings.firstName)}
                        shrink/>
                </Col>
                <Col xs={12} sm={6} >
                    <TextInputField 
                        name='lastName'
                        component={ReduxTextInput}
                        label={formatMessage(appStrings.lastName)}
                        shrink/>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} >
                    <TextInputField 
                        name='address1'
                        component={ReduxTextInput}
                        label={formatMessage(appStrings.street1)}
                        shrink/>
                </Col>
                <Col xs={12} sm={6} >
                    <TextInputField 
                        name='address2'
                        component={ReduxTextInput}
                        label={formatMessage(appStrings.street2)}
                        shrink/>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} >
                    <TextInputField 
                        name='city'
                        component={ReduxTextInput}
                        label={formatMessage(appStrings.city)}
                        shrink/>
                </Col>
                <Col xs={12} sm={6} >
                    <SelectField 
                        id='stateRegion'
                        name='stateRegion'
                        component={ReduxSimpleSelect}
                        label={formatMessage(appStrings.state)}
                        options={countryCode == 'CA' ? caStateOptions : stateOptions}
                        shrink/>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} >
                    <TextInputField 
                        name='postalCode'
                        component={ReduxTextInput}
                        label={formatMessage(appStrings.zip)}
                        shrink/>
                </Col>
                <Col xs={12} sm={6} >
                    <SelectField 
                        id='countryCode'
                        name='countryCode'
                        component={ReduxSimpleSelect}
                        label={formatMessage(appStrings.country)}
                        options={countryOptions}
                        shrink/>
                </Col>
            </Row>

            <Row>
                {saveButton &&
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className='whiteButton' style={{ marginRight: '10px' }} onClick={onBackClick}>Cancel</div>
                    <LoadingButton 
                        label='Save Billing'
                        loading={loading}
                        type='orange' 
                        onClick={onSave}/>
                </div>}
            </Row>

            {!!billings && billings.length > 0 && 
            <Row>
                <Col xs={12} sm={12} style={{ display: 'flex' }} onClick={onBackClick}>
                    <ArrowBack />
                    <Typography variant="body2" className='clickable'>Back</Typography>
                </Col>
            </Row>}
            
        </form>
    )
}

const billingSelector = formValueSelector('billing');
const billingFields = ['firstName', 'lastName', 'address1', 'address2', 'city', 'stateRegion', 'postalCode', 'countryCode'];


interface In {
    billing: any;
}

const stateToProps = (state: State): In => ({
    billing: billingSelector(state, ...billingFields),
})

interface Out {
    
}

const dispatchToProps = (d: Dispatch<State>): Out => ({
    

})

export const BillingForm = connect(stateToProps, dispatchToProps)(injectIntl<In & Out & IBillingFormProps>(reduxForm<{}, In & Out & IBillingFormProps & InjectedIntlProps>({ form: 'billing', initialValues: { countryCode: 'US' } })(BillingFormWrapper)));