import * as React from 'react'
import * as _ from 'lodash';
import { injectIntl, InjectedIntlProps } from "react-intl";

export interface ITCSelectOption {
    value: string | number,
    label: string;
    disabled?: boolean;
}

export interface ITCSelectProps {
    defaultValue?: string | number;
    id: string;
    label?: string;
    options: ITCSelectOption[];
    style?: any;
    value: string | number;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const TCSelect  = injectIntl(({ id, intl, label, options, style, value, onChange }: ITCSelectProps & InjectedIntlProps) => {
    const { formatMessage } = intl;
    let optionFound = false;

    if (value == undefined || value == null) {
        value = options[0].value;
    }

    let optionElements = _.map(options, (option) => {
        if (option.value == value) {
            optionFound = true;
        }
        return <option key={option.value} value={option.value}>{option.label}</option>
    });

    if (!optionFound) {
        console.log('option value not found: ' + label + ' value: ' + value);
    }

    return (
        <div key={id} className={'flex-container-column underlineSelect'} style={{ alignItems: 'flex-start', ...style }}>
            <div style={{ fontSize: '13px', fontWeight: 'bolder', color: '#6B625E' }}>{label}</div>
            <select id={id} value={value} onChange={onChange} style={{ width: '100%' }}>
                {optionElements}
            </select>
        </div>
    );
})
