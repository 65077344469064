import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware  } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import createHistory from 'history/createBrowserHistory';

import reducers from '../reducers';
import { State } from '../../Types';

import rootSaga from '../reducers/sagas';

export const history = createHistory();

export let initStore = () => {
    const sagaMiddleware = createSagaMiddleware();
    const rMiddleware = routerMiddleware(history)
    const store = createStore<State>(
        reducers,
        composeWithDevTools(applyMiddleware(sagaMiddleware, rMiddleware))
    )

    sagaMiddleware.run(rootSaga)

    return { store };
}