import * as React from 'react'
import * as _ from 'lodash';
import { FormattedMessage, injectIntl, InjectedIntlProps, defineMessages } from "react-intl";

export interface ITCOnOffSwitchProps {
    checked: boolean;
    defaultValue?: boolean;
    label?: string,
    id: string;
    style?: any
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const appStrings = defineMessages({
    text: {
        id: "TCOnOffSwitch.text.label",
        defaultMessage: "text"
    }
});

export const TCOnOffSwitch = injectIntl(({ checked, defaultValue, id, intl, label, style, onChange }: ITCOnOffSwitchProps & InjectedIntlProps) => {
    const { formatMessage } = intl;
    if (checked === undefined || checked === null) {
        checked = defaultValue || false;
    }
    return (
        <div className={'flex-container onOffSwitch'} style={{ justifyContent: 'space-between', alignItems: 'center', ...style }}>
            <div>{label}</div>
            <div key={id} className='checkSwitchGrayOff flex-container' style={{ alignItems: 'center' }}>
                <input type='checkbox'
                    name='select'
                    value='valuable'
                    id={id}
                    onChange={onChange}
                    checked={checked}
                />
                <label htmlFor={id} />
            </div>
        </div>
    );
})
