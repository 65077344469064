import * as React from 'react'
import { Col, Glyphicon } from 'react-bootstrap';
import * as _ from 'lodash';
import { injectIntl, InjectedIntlProps, defineMessages } from "react-intl";
import { Link, Route, withRouter } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { connect, Dispatch } from 'react-redux';
import * as request from 'superagent';

import TCAccountDropdown from './TCAccountDropdown';
import HeaderPictureOptions from './HeaderPictureOptions';
import { IMessageModel } from '../../Models/IMessageModel';

import { State } from '../../../Types';
import { clearSelected, removeUnselected } from '../../reducers/gallery';
import { bulkDeleteMedia } from '../../reducers/media';
import { IUIState, setModalContent } from '../../reducers/ui';
import { isBushnell } from '../../constants';
import { error } from '../../reducers/sagas';

export interface ITCPageHeaderProps {
    messages?: IMessageModel[]
    loggedIn?: boolean
    adminLogin?: boolean
}

interface ITCPageHeaderState {
    headerHeight: string,
    loggedIn: boolean,
    color: string
    defaultState: boolean,
    editingPictures: boolean,
    firstName: string,
    lastName: string,
    email: string
}

const appStrings = defineMessages({
    text: {
        id: "TCPageHeader.text.label",
        defaultMessage: "text"
    },
    images: {
        id: "TCPageHeader.images.label",
        defaultMessage: "Images"
    },
    cameras: {
        id: "TCPageHeader.cameras.label",
        defaultMessage: "Cameras"
    }
});

const transitionStyles: any = {
    entering: {transform: 'translateY(0px)'},
    entered: {transform: 'translateY(0)'},
    exiting: {transform: 'translateY(-100px)'},
    exited: {transform: 'translateY(-100px)'}
}

const bushnellLogo = {
    src: '/images/Bushnell_logo.svg',
    height: '20px',
    width: '95px',
    smSrc: '/images/bushnell-round-gray.png',
    smHeight: '50px',
    smWidth: '50px'
}

const primosLogo = {
    src: '/images/Primos-logo.png',
    height: '50px',
    width: '112px',
    smSrc: '/images/Primos-logo.png',
    smHeight: '50px',
    smWidth: '112px'
}

const logo = isBushnell ? bushnellLogo : primosLogo

class TCPageHeader extends React.Component<ITCPageHeaderProps & In & Out & InjectedIntlProps, ITCPageHeaderState> {
    private resizeListener: EventListener & _.Cancelable;

    constructor(props: ITCPageHeaderProps & In & Out & InjectedIntlProps) {
        super(props);

        this.state = {
            loggedIn: ('loggedIn' in this.props && this.props.loggedIn != false),
            headerHeight: '72px',
            color: '#FFF',
            defaultState: true,
            editingPictures: false,
            firstName: '',
            lastName: '',
            email: ''
        }

        this.resizeListener = _.throttle(this.updateDimensions, 200);
    }

    componentWillMount() {
        this.updateDimensions();
    }

    componentDidMount() {
        window.addEventListener("resize", this.resizeListener);
        if( this.props.loggedIn) this.getDetails();
    }

    componentWillUnmount() {
        this.resizeListener.cancel();
        window.removeEventListener("resize", this.resizeListener);
    }
    
    componentWillUpdate(newProps: ITCPageHeaderProps & In & InjectedIntlProps) {
        if (newProps.selecting && !this.props.selecting) {
            this.setState({...this.state, defaultState: false}, () => {
                setTimeout(() => this.setState({...this.state, editingPictures: true}), 200)
            })
        } else if (!newProps.selecting && this.props.selecting) {
            this.setState({...this.state, editingPictures: false}, () => {
                setTimeout(() => this.setState({...this.state, defaultState: true}), 200)
            })
        }
    }

    getDetails = async () => {
        try {
            const response = await request
                .get('/api/account/details/') 

            const details = response.body;
            this.updateState(details);
        }
        catch (err) {
            this.props.dError(err);
        }
    }
	
	updateState(values: Object) {
        var newState = _.assign(this.state, values);
        this.setState(newState);
    }

    updateDimensions = () => {
        if (typeof window === 'undefined') {
            // Skip if we are rendering on the server
            return;
        }

        if (window.innerWidth <= 400) {
            if (this.state.headerHeight != '54px') {
                this.updateState({ headerHeight: '54px', footerHeight: '80px' });
            }
        } else if (this.state.headerHeight != '72px') {
            this.updateState({ headerHeight: '72px', footerHeight: '96px' });
        }
    }
    


    render(): JSX.Element {
        const { messages, loggedIn, selectedMedia, bulkDeleteMedia, onClear, setModalContent, dError } = this.props;
        const { defaultState, editingPictures, firstName, lastName, email } = this.state;
        let linkPath: string = '/';

        if (this.props.loggedIn) {
            linkPath += 'images';
        };

        let backgroundColor = isBushnell
            ? editingPictures ? "#F66E32" : "#FFF"
            : "#62BB46";

        if (this.props.adminLogin) {
            backgroundColor = "#fc4503";
        }

        return (
            <Col style={{zIndex: 2}}>
                <div className="shadow"
                    style={{
                        minWidth: '100%',
                        backgroundColor: backgroundColor,
                        height: this.state.headerHeight,
                        minHeight: this.state.headerHeight,
                        display: "flex",
                        transition: 'background-color 300ms ease-in',
                        zIndex: 2
                    }}
                >
                    <div className='container header' 
                        style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Link style={{display: 'flex', alignItems: 'center'}} to={ linkPath }>
                                <img 
                                    className='hidden-xs' 
                                    src={logo.src} width={logo.width} height={logo.height} />
                                <img 
                                    className='hidden-sm hidden-md hidden-lg'
                                    src={logo.smSrc}
                                    width={logo.smWidth}
                                    height={logo.smHeight}/>
                            </Link>
                            {loggedIn &&
                                <div style={{display: 'flex', flexFlow: 'column nowrap', justifyContent: 'space-between', flex: 1, zIndex: 100}}>
                                    <Transition in={defaultState} timeout={200} mountOnEnter={true} unmountOnExit={true} appear={true}>
                                        {(state: any) =>
                                            <div style={{
                                                    display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch', flex: 1,
                                                    transition: 'transform 200ms ease-in',
                                                    ...transitionStyles[state]
                                                }}>
                                                <div>
                                                    <div className='navText hidden-sm hidden-md hidden-lg'>
                                                        <OnlyWhenActiveLink
                                                            small 
                                                            to="/images" 
                                                            label={<Glyphicon glyph='picture' style={{fontSize: '30px'}}/>} />
                                                            &nbsp;/&nbsp; 
                                                        <OnlyWhenActiveLink 
                                                            small
                                                            to="/cameras" 
                                                            label={<Glyphicon glyph='camera' style={{fontSize: '36px', paddingTop: '2px'}}/>} />
                                                    </div>
                                                    <div className='navText hidden-xs '>
                                                        <OnlyWhenActiveLink to="/images" label='Images' /> / <OnlyWhenActiveLink to="/cameras" label='Cameras' />
                                                    </div>
                                                </div>
                                                <div style={{display: 'flex', paddingLeft: '40px'}}>
                                                    <Route path='/cameras' exact={true} children={({match} : any) => {
                                                        return <div className='hidden-xs center-items' style={{padding: '0 12px'}}>
                                                            {match && <Link to='/camera/add' className='header-add-camera' style={{color: isBushnell ? "#6AA652" : 'white'}} >
                                                                <Glyphicon glyph='plus-sign' />
                                                                <h5>Add Camera</h5>
                                                            </Link>}
                                                        </div>
                                                    }} />
                                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                        <Link to='messages' className='center-items' style={{padding: '11px 12px', color: isBushnell ? '#757D91' : '#FFF'}}>
                                                            <Glyphicon 
                                                                glyph='envelope' 
                                                                className={`header-text${messages.length ? '-active' : ''} clickable`} 
                                                                style={{lineHeight: '35px', fontSize: '35px', marginRight: '5px'}}/>
                                                        </Link>
                                                <TCAccountDropdown firstName={firstName} lastName={lastName} email={email} onError={dError}/>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </Transition>
                                    <Transition in={editingPictures} timeout={200}  mountOnEnter={true} unmountOnExit={true} appear={true}>
                                        {(state: any) =>
                                            <div style={{
                                                    display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch', flex: 1,
                                                    transition: 'transform 200ms ease-in',
                                                    ...transitionStyles[state]
                                                }}>
                                                <HeaderPictureOptions 
                                                    selectedMedia={selectedMedia} 
                                                    onClear={onClear} 
                                                    onDelete={bulkDeleteMedia} 
                                                    setModalContent={setModalContent}/>
                                            </div>
                                        }

                                    </Transition>
                                </div>
                            }
                    </div>
                </div>
            </Col>
        );
    }
}

interface In {
    selectedMedia: number[];
    selecting: boolean;
}

const stateToProps = (state: State) => ({
    selectedMedia: state.gallery.selectedMedia,
    selecting: state.gallery.selecting
})

interface Out {
    bulkDeleteMedia: (mediaIDs: number[]) => void;
    onClear: () => void;
    setModalContent: ({title, message, onConfirm}: IUIState['modal']) => void;
    dError: (err: any) => void;
}

const dispatchToProps = (d: Dispatch<State>): Out => ({
    bulkDeleteMedia: x => d(bulkDeleteMedia(x)),
    onClear: () => d(clearSelected()),
    setModalContent: x => d(setModalContent(x)),
    dError: e => d(error(e))
})

export default withRouter(connect(stateToProps, dispatchToProps)(injectIntl(TCPageHeader)));

const OnlyWhenActiveLink = ({ label, small, to }: { label: any, small?: boolean, to: any}) => (
    <Route path={to} exact={true} children={({ match }: any) => (
        <Link 
            className={match ? 'activeNav' : 'inactiveNav'} 
            style={{
                display: 'flex',
                color: isBushnell 
                    ? small ? null : "#6AA652"
                    : match ? 'white' : '#CACFD9'
                
            }} to={to}>{label}</Link>
    )} />
);