import { PayloadAction } from '../../../Types';
import * as update from 'immutability-helper';
import { IMediaModel, MediaCriteria } from 'tcmediamanager';
import { ITagModel } from '../../Models/ITagModel';

export interface MediaState {
    currentMedia: {
        mediaID: number,
        index: number,
        thumbnail: IMediaModel
    },
    previousMedia: {
        mediaID: number,
        thumbnail: IMediaModel
    },
    nextMedia: {
        mediaID: number,
        thumbnail: IMediaModel
    },
    tags: ITagModel[];
}

const initialState: MediaState = {
    currentMedia: {
        mediaID: null,
        index: null,
        thumbnail: {
            cameraFilename: '',
            dateTaken: '',
            fullSizeUploaded: false,
            fullSizeRequested: false,
            fullSizeServerURL: '',
            mapURL: '',
            mediaID: 0,
            mediaType: 0,
            serverURL: '',
        },
    },
    previousMedia: {
        mediaID: null,
        thumbnail: null
    },
    nextMedia: {
        mediaID: null,
        thumbnail: null
    },
    tags: []
}

export const Actions = {
    ADD_TAG: 'media/ADD_TAG',
    BULK_DELETE_MEDIA: 'media/BULK_DELETE_MEDIA',
    BULK_DELETE_MEDIA_BY_FILTER: 'media/BULK_DELETE_MEDIA_BY_FITLER',
    DELETE_MEDIA: 'media/DELETE_MEDIA',
    GET_ACCOUNT_FILTER_INFO: 'media/GET_ACCOUNT_FILTER_INFO',
    GO_TO_CAMERA_MEDIA: 'media/GO_TO_CAMERA_MEDIA',
    GO_TO_NEXT_MEDIA: 'media/GO_TO_NEXT_MEDIA',
    GO_TO_NEXT_MEDIA_WITH_BUFFER: 'media/GO_TO_NEXT_MEDIA_WITH_BUFFER',
    GO_TO_PREVIOUS_MEDIA: 'media/GO_TO_PREVIOUS_MEDIA',
    GO_TO_PREVIOUS_MEDIA_WITH_BUFFER: 'media/GO_TO_PREVIOUS_MEDIA_WITH_BUFFER',
    INITIALIZE_SLIDESHOW: 'media/INITIALIZE_SLIDESHOW',
    NAVIGATE_TO_CAMERA_LATEST: 'media/NAVIGATE_TO_CAMERA_LATEST',
    RATE_MEDIA: 'media/RATE_MEDIA',
    RATE_CURRENT_MEDIA: 'media/RATE_CURRENT_MEDIA',
    REMOVE_TAG: 'media/REMOVE_TAG',
    RETAIN_MEDIA: 'media/RETAIN_MEDIA',
    REQUEST_FULL_RES: 'media/REQUEST_FULL_RES',
    SET_CURRENT_MEDIA: 'media/SET_CURRENT_MEDIA',
    SET_CURRENT_MEDIA_ID: 'media/SET_CURRENT_MEDIA_ID',
    SET_CURRENT_MEDIA_INDEX: 'media/SET_CURRENT_MEDIA_INDEX',
    SET_CURRENT_MEDIA_RATING: 'media/SET_CURRENT_MEDIA_RATING',
    SET_CURRENT_MEDIA_TAGS: 'media/SET_CURRENT_MEDIA_TAGS',
    SET_MEDIA: 'media/SET_MEDIA',
    SET_NEXT_MEDIA: 'media/SET_NEXT_MEDIA',
    SET_PREVIOUS_MEDIA: 'media/SET_PREVIOUS_MEDIA',
    SET_TAGS: 'media/SET_TAGS',
    SET_THUMBNAIL: 'media/SET_THUMBNAIL',
}

export const addTag = ({mediaID, tagName}: {mediaID: number, tagName: string}) => ({type: Actions.ADD_TAG, payload: {mediaID, tagName}});
export const bulkDeleteMedia = (mediaIDs: number[]) => ({type: Actions.BULK_DELETE_MEDIA, payload: mediaIDs});
export const bulkDeleteMediaByFilter = () => ({ type: Actions.BULK_DELETE_MEDIA_BY_FILTER});
export const deleteMedia = (mediaID: number) => ({type: Actions.DELETE_MEDIA, payload: mediaID});
export const getAccountFilterInfo = () => ({type: Actions.GET_ACCOUNT_FILTER_INFO});
export const goToCameraMedia = (payload: number) => ({ type: Actions.GO_TO_CAMERA_MEDIA, payload });
export const goToNextMedia = () => ({type: Actions.GO_TO_NEXT_MEDIA});
export const goToNextMediaWithBuffer = (buffer: number) => ({type: Actions.GO_TO_NEXT_MEDIA_WITH_BUFFER, payload: buffer});
export const goToPrevMedia = () => ({type: Actions.GO_TO_PREVIOUS_MEDIA});
export const goToPrevMediaWithBuffer = (buffer: number) => ({type: Actions.GO_TO_PREVIOUS_MEDIA_WITH_BUFFER, payload: buffer});
export const initializeSlideshow = () => ({type: Actions.INITIALIZE_SLIDESHOW});
export const navigateToCameraLatest = (cameraID: number, mediaID: number) => ({ type: Actions.NAVIGATE_TO_CAMERA_LATEST, payload: { cameraID, mediaID } })
export const rateMedia = ({mediaID, rating}: {mediaID: number, rating: number}) => ({type: Actions.RATE_MEDIA, payload: {mediaID, rating}});
export const rateCurrentMedia = (rating: number) => ({type: Actions.RATE_CURRENT_MEDIA, payload: rating});
export const removeTag = ({mediaID, tagID}: {mediaID: number, tagID: number}) => ({type: Actions.REMOVE_TAG, payload: {mediaID, tagID}})
export const requestFullRes = (mediaID: number) => ({type: Actions.REQUEST_FULL_RES, payload: mediaID});
export const retainMedia = (payload: { mediaID: number, retain: boolean }) => ({ type: Actions.RETAIN_MEDIA, payload });
export const setCurrentMedia = ({mediaID, index}: {mediaID: number, index: number}) => ({type: Actions.SET_CURRENT_MEDIA, payload: {mediaID, index}});
export const setCurrentMediaID = (mediaID: number) => ({type: Actions.SET_CURRENT_MEDIA_ID, payload: mediaID});
export const setCurrentMediaIndex= (index: number) => ({type: Actions.SET_CURRENT_MEDIA_INDEX, payload: index});
export const setCurrentMediaRating = (rating: number) => ({type: Actions.SET_CURRENT_MEDIA_RATING, payload: rating});
export const setCurrentMediaTags = (tags: ITagModel[]) => ({type: Actions.SET_CURRENT_MEDIA_TAGS, payload: tags});
export const setMedia = (media: IMediaModel) => ({type: Actions.SET_MEDIA, payload: media});
export const setNextMedia = ({mediaID, thumbnail}: {mediaID: number, thumbnail: IMediaModel}) => ({type: Actions.SET_NEXT_MEDIA, payload: {mediaID, thumbnail}});
export const setPreviousMedia = ({mediaID, thumbnail}: {mediaID: number, thumbnail: IMediaModel}) => ({type: Actions.SET_PREVIOUS_MEDIA, payload: {mediaID, thumbnail}});
export const setTags = (tags: ITagModel[]) => ({type: Actions.SET_TAGS, payload: tags});
export const setThumbnail = (thumbnail: IMediaModel) => ({type: Actions.SET_THUMBNAIL, payload: thumbnail});

export default (state: MediaState=initialState, { type, payload }: PayloadAction<any>) => {
    switch(type) {
        case Actions.SET_CURRENT_MEDIA_ID:
            return update(state, {
                currentMedia: {
                    mediaID: {$set: payload},
                }
            });
        case Actions.SET_CURRENT_MEDIA_INDEX:
            return update(state, {
                currentMedia: {
                    index: {$set: payload},
                }
            });
        case Actions.SET_CURRENT_MEDIA_RATING:
            return update(state, {
                currentMedia: {
                    thumbnail: {
                        rating: {$set: payload}
                    }
                }
            })
        case Actions.SET_CURRENT_MEDIA_TAGS:
            return update(state, {
                currentMedia: {
                    thumbnail: {
                        tags: {$set: payload}
                    }
                }
            })
        case Actions.SET_NEXT_MEDIA:
            return update(state, {
                nextMedia: {
                    mediaID: {$set: payload.mediaID},
                    thumbnail: {$set: payload.thumbnail}
                }
            });
        case Actions.SET_PREVIOUS_MEDIA:
            return update(state, {
                previousMedia: {
                    mediaID: {$set: payload.mediaID},
                    thumbnail: {$set: payload.thumbnail}
                }
            });
        case Actions.SET_TAGS:
            return update(state, {
                tags: {$set: payload}
            });
        case Actions.SET_THUMBNAIL:
            return update(state, {
                currentMedia: {
                    thumbnail: {$set: payload}
                }
            });
        default:
            return state;
    }
}