import * as React from 'react';
import { FormField, FormError } from 'react-form';
import { StyledTextInput }  from './index';    

interface TextInputProps {
    disabled?: boolean
    field: string,
    id?: string;
    label: string,
    placeholder?: string;
    shrink?: boolean;
    type?: string
    helperText?: string
    fieldApi?: any;
    [key: string]: any
}



const TextInputWrapper = ({disabled=false, field, id, label, placeholder, shrink, type, helperText, fieldApi, ...props}: TextInputProps) => {
    const { getTouched, getError, getValue, setValue} = fieldApi;
    const error = !!getTouched() && !!getError();
    return (
        <div className={'flex-container-column create-account-input '}>
            <StyledTextInput 
                id={id}
                disabled={disabled}
                value={getValue()}
                onChange={(e: React.SyntheticEvent<any>) => setValue((e.target as any).value)}
                name={label}
                label={label} 
                placeholder={placeholder}
                shrink={shrink}
                type={type} 
                error={error} 
                helperText={(error && getError()) || helperText}
                {...props}/>
        </div>
    )
}

export const TextInput = FormField(TextInputWrapper)