import * as _ from 'lodash';
import * as moment from 'moment';
import { createSelector } from 'reselect';
import { State } from '../index';
import { IAutoOrderModel, IAutoOrderItemModel, IDataPlanReviewItem, IItem, ICameraModel, IDataPlanModel, ICameraDataPlan, ICameraBillings, IAutoOrderItemBillingModel, ICouponModel, Item } from '../../Models'


export const getModifiedAutoOrder = (state: State) => state.billing.modifiedAutoOrder;
export const getUpgradeNow = (state: State) => state.billing.cameraUpgradeNow;
export const getItems = (state: State) => state.billing.items;

const getCameras = (state: State) => state.cameras.cameras;
const getCurrentAO = (state: State) => state.billing.currentAutoOrder;

export const getCameraDataPlans = createSelector(
    [getCameras],
    (cameras: ICameraModel[]): ICameraDataPlan[] => {
        let cameraDataPlans = cameras.map(c => ({
            cameraName: c.cameraName,
            cameraID: c.cameraID,
            dataPlans: c.dataPlans.filter(plan => (plan.active && !(moment(plan.endDate).isBefore(moment()))))
        }));

        cameraDataPlans = cameraDataPlans.map(cdp => {
            _.set(cdp, 'endDate', ((plans: IDataPlanModel[]) => {
                let date: string | null = null;
                plans.forEach(p => {
                    if (date === null || moment(p.endDate).isAfter(moment(date))) {
                        date = p.endDate
                    }
                })
                return date;
            })(cdp.dataPlans))
            return cdp;
        })

        return cameraDataPlans;
    }
)

export const selectCurrentAutoOrder = createSelector(
    [getCurrentAO, getCameras, getCameraDataPlans],
    (currentAutoOrder: IAutoOrderModel, cameras: ICameraModel[], cameraDataPlans: ICameraDataPlan[]) => {
        function hasCurrentDataPlan(cameraID: number) {
            const camera = _.find(cameraDataPlans, c => c.cameraID === cameraID);
            if (!camera) return false;
            const dataPlans: IDataPlanModel[] = _.get(camera, 'dataPlans', []);
            let result = false;
            dataPlans.forEach(dp => {
                if (moment().isBetween(moment(dp.startDate), moment(dp.endDate))) result = true;
            })
            return result;
        }

        function getCurrentDataPlan(cameraID: number){
            const current = _.find(cameraDataPlans, cdp => cdp.cameraID === cameraID);
            if (!current) return;
            return _.find(current.dataPlans, dp => moment().isBetween(dp.startDate, dp.endDate));
        }

        function getCameraNextBillingDate(billings: IAutoOrderItemBillingModel[]) {
            let renewDates: Date[] = billings
                .map(x => x.renewDate)
                .filter(date => moment().isBefore(date))
                .sort((l, r) => moment(l).diff(moment(r)));
            
            if (renewDates.length == 0) return null;
            return renewDates[0];
        }

        function getCameraDataPlanEndDate(cameraID: number){
            return _.get(getCurrentDataPlan(cameraID), 'endDate');
        } 


        function isPaused(cameraID: number, billings: IAutoOrderItemBillingModel[]) {
            return (
                hasCurrentDataPlan(cameraID)
                    ? moment(getCameraNextBillingDate(billings)).isAfter(moment(getCameraDataPlanEndDate(cameraID)).add(1, 'week'))
                    : !_.isNull(getCameraNextBillingDate(billings))
            )
        }

        return {
            ...currentAutoOrder, 
            items: (_.get(currentAutoOrder, 'items', []) as IAutoOrderItemModel[])
                .filter(i => moment().isBefore(moment(i.nextShipment)))
                .filter(i => i.billings.length > 0)
                .map(i => {
                    return {
                        ...i,
                        isPaused: isPaused(i.cameraID, i.billings)
                    }
                })
        } 
    }
)

export const getCameraBillings = createSelector(
    [getCameras, selectCurrentAutoOrder],
    (cameras: ICameraModel[], currentAutoOrder: IAutoOrderModel): ICameraBillings[] => {
        let cameraBillings = cameras.map(c => ({
            cameraName: c.cameraName,
            cameraID: c.cameraID,
            billings: _.get(_.find(currentAutoOrder.items, i => i.cameraID == c.cameraID), 'billings', [])
        }));

        return cameraBillings;
    }
)



export const getCanceledCameraPlans= createSelector(
    [selectCurrentAutoOrder, getModifiedAutoOrder, getItems],
    (currentAutoOrder: IAutoOrderModel, modifiedAutoOrder: IAutoOrderModel, items: IItem[]): IDataPlanReviewItem[] => {
        const canceledItems =  _.get(modifiedAutoOrder, 'items', []).filter(item => item.reviewType == 'cancel');

        let reviewItems: IDataPlanReviewItem[] = [];
        canceledItems.forEach(newAOItem => {
            const currentAOItem = _.find(currentAutoOrder.items, item => item.cameraID == newAOItem.cameraID);
            const currentUCItem = _.find(items, item => item.ultracartItemID == _.get(currentAOItem, 'ultracartItemID'));
            reviewItems.push({
                currentAOItem,
                currentUCItem,
                newAOItem,
                newUCItem: null
            })
        })

        return reviewItems;
    }
)

export const getCurrentItems = createSelector(
    [selectCurrentAutoOrder],
    (currentAutoOrder: IAutoOrderModel): IAutoOrderItemModel[] => {
        let currentItems: IAutoOrderItemModel[] = _.get(currentAutoOrder, 'items', [])
        return currentItems
            .filter(ci => moment(ci.nextShipment).isAfter(moment()))
            .map(ci => ({ ...ci, billings: ci.billings.filter(b => moment(b.renewDate).isAfter(moment())) }))
    }
)

export const getCurrentImpusleItems = createSelector(
    [getCurrentItems, getCameras],
    (currentItems: IAutoOrderItemModel[], cameras: ICameraModel[]): IAutoOrderItemModel[] => {
        return currentItems.filter(item => {
            const camera = _.find(cameras, camera => camera.cameraID == item.cameraID);
            return _.get(camera, 'hardwareType') == 1;
        })
    }
)

export const getCurrentAggressorItems = createSelector(
    [getCurrentItems, getCameras],
    (currentItems: IAutoOrderItemModel[], cameras: ICameraModel[]): IAutoOrderItemModel[] => {
        return currentItems.filter(item => {
            const camera = _.find(cameras, camera => camera.cameraID == item.cameraID);
            return _.get(camera, 'hardwareType') == 0;
        })
    }
)


export const getNextBillingDate = createSelector(
    [selectCurrentAutoOrder],
    (currentAO: IAutoOrderModel): moment.Moment => {
        if (!currentAO) return null;
        if (_.get(currentAO, 'items', []).length == 0) return null;
        let date: Date = null;
        currentAO.items.forEach(i => {
            let nextBillingDate: Date = null;
            i.billings.forEach(b => {
                if (nextBillingDate == null) nextBillingDate = b.renewDate;
                else if (moment(b.renewDate).isBefore(nextBillingDate)) nextBillingDate = b.renewDate;
            })

            if (date == null) date = nextBillingDate;
            else if (moment(nextBillingDate).isBefore(date)) date = nextBillingDate;
        })
        return moment(date);
    }
);

export const getNextBillingDateString = createSelector(
    [getNextBillingDate],
    (nextBillingDate: moment.Moment): string => {
        return nextBillingDate != null ? nextBillingDate.format('L') : null;
    }
);

export const getCurrentMonthlyTotal = createSelector(
    [selectCurrentAutoOrder, getItems, getNextBillingDate],
    (currentAutoOrder: IAutoOrderModel, items: IItem[], billingDate: moment.Moment) => {
        let total = 0.0;
        if (currentAutoOrder == null || items == null) {
            return total;
        }

        _.forEach(currentAutoOrder.items, (aoItem: IAutoOrderItemModel) => {
            let nextShip = aoItem.billings.length > 0 ? aoItem.billings[0].renewDate : aoItem.nextShipment;
            let cost = (aoItem.billings.length > 0 && aoItem.billings[0].renewPrice) ? aoItem.billings[0].renewPrice : getItemCost(aoItem.ultracartItemID, items);
            
            if (_.isNull(_.get(aoItem, 'noOrdersAfter', null)) && moment(nextShip).isSame(billingDate, 'day')) total += cost;
        });

        return total;
    }
);


export const getFreeCameraPlans = createSelector(
    [selectCurrentAutoOrder, getModifiedAutoOrder, getItems],
    (currentAutoOrder: IAutoOrderModel, modifiedAutoOrder: IAutoOrderModel, items: IItem[]): IDataPlanReviewItem[] => {
        const freeItems =  _.get(modifiedAutoOrder, 'items', []).filter(item => item.reviewType == 'free');

        let reviewItems: IDataPlanReviewItem[] = [];
        freeItems.forEach(newAOItem => {
            const newUCItem = _.find(items, item => item.ultracartItemID == _.get(newAOItem, 'ultracartItemID'))
            reviewItems.push({currentAOItem: null, currentUCItem: null, newAOItem, newUCItem})
        })

        return reviewItems;
    }
)



export const getModifiedItems = createSelector(
    [getModifiedAutoOrder, getCurrentItems],
    (modifiedAutoOrder: IAutoOrderModel, currentItems: IAutoOrderItemModel[]): IAutoOrderItemModel[] => {
        if (modifiedAutoOrder == null) return null;
        
        let items: IAutoOrderItemModel[] = [];
        modifiedAutoOrder.items.forEach(item => {
            const cItem = _.find(currentItems, currentItem => currentItem.cameraID == item.cameraID);
            if (cItem) {
                if (cItem.ultracartItemID != item.ultracartItemID) items.push(item);
                else if (_.get(cItem, 'noOrdersAfter', null) != _.get(item, 'noOrdersAfter', null) != null) items.push(item);
                else if (!moment(_.get(item, 'nextShipment')).isSame(_.get(cItem, 'nextShipment'))) items.push(item);
            }
        })

        return items;
    }
)

export const getNewItems = createSelector(
    [getModifiedAutoOrder, getCurrentItems],
    (modifiedAutoOrder: IAutoOrderModel, currentItems: IAutoOrderItemModel[]): IAutoOrderItemModel[] => {
        if (modifiedAutoOrder == null) return null;
        
        let items: IAutoOrderItemModel[] = [];
        modifiedAutoOrder.items.forEach(item => {
            const cItem = _.find(currentItems, currentItem => currentItem.cameraID == item.cameraID);
            if (!cItem) items.push(item);
        })

        return items;
    }
)

export const getNewLaterItems = createSelector(
    [selectCurrentAutoOrder, getModifiedAutoOrder, getItems],
    (currentAutoOrder: IAutoOrderModel, modifiedAutoOrder: IAutoOrderModel, items: IItem[]): IDataPlanReviewItem[] => {
        const adminItems = _.get(modifiedAutoOrder, 'items', []).filter(item => item.reviewType == 'new later');
        
        let reviewItems: IDataPlanReviewItem[] = [];
        adminItems.forEach(newAOItem => {
            const newUCItem = _.find(items, item => item.ultracartItemID == _.get(newAOItem, 'ultracartItemID'))
            reviewItems.push({currentAOItem: null, currentUCItem: null, newAOItem, newUCItem})
        });

        return reviewItems;
    }
)

export const getNewCameraPlans = createSelector(
    [selectCurrentAutoOrder, getModifiedAutoOrder, getItems],
    (currentAutoOrder: IAutoOrderModel, modifiedAutoOrder: IAutoOrderModel, items: IItem[]): IDataPlanReviewItem[] => {
        const newItems = _.get(modifiedAutoOrder, 'items', []).filter(item => item.reviewType == 'new' || item.reviewType == 'new renew');
        
        let reviewItems: IDataPlanReviewItem[] = [];
        newItems.forEach(newAOItem => {
            const newUCItem = _.find(items, item => item.ultracartItemID == _.get(newAOItem, 'ultracartItemID'))
            reviewItems.push({currentAOItem: null, currentUCItem: null, newAOItem, newUCItem})
        });

        return reviewItems;
    }
)

export const getNewBillingDate = createSelector(
    [getModifiedAutoOrder],
    (modifiedAutoOrder: IAutoOrderModel): moment.Moment => {
        if (modifiedAutoOrder) {
            let newBillingDate: Date;
            modifiedAutoOrder.items.forEach(item => {
                if (!item.noOrdersAfter) {
                    if (!newBillingDate) newBillingDate = item.nextShipment;
                    if (moment(item.nextShipment).isBefore(moment(newBillingDate))) newBillingDate = item.nextShipment;
                }
            })
            
            return moment(newBillingDate);
        }
        return null;
    }
)

export const getNewBillingDateString = createSelector(
    [getNewBillingDate],
    (newBillingDate: moment.Moment): string => {
        return newBillingDate != null ? newBillingDate.format('L') : null;
    }
)

export const getNewMonthlyTotal = createSelector(
    [getModifiedAutoOrder, getItems, getNewBillingDate],
    (modifiedAutoOrder: IAutoOrderModel, items: IItem[], billingDate: moment.Moment) => {
        let total = 0.0;
        if (modifiedAutoOrder == null || items == null) {
            return total;
        }

        _.forEach(modifiedAutoOrder.items, (aoItem: IAutoOrderItemModel) => {
            if (_.get(aoItem, 'nextShipment')) {
                if (moment(aoItem.nextShipment).isSameOrBefore(billingDate, 'day') && _.get(aoItem, 'noOrdersAfter', null) == null) {
                    total += getItemCost(aoItem.ultracartItemID, items);
                }
            } else {
                total += getItemCost(aoItem.ultracartItemID, items);
            }
            
        });

        return total;
    }
);

export const getPausedCameraPlans = createSelector(
    [selectCurrentAutoOrder, getModifiedAutoOrder, getItems],
    (currentAutoOrder: IAutoOrderModel, modifiedAutoOrder: IAutoOrderModel, items: IItem[]): IDataPlanReviewItem[] => {
        const pausedItems = _.get(modifiedAutoOrder, 'items', []).filter(item => item.reviewType == 'paused');
        
        let reviewItems: IDataPlanReviewItem[] = [];
        pausedItems.forEach(newAOItem => {
            const currentAOItem = _.find(_.get(currentAutoOrder, 'items'), (item: IAutoOrderItemModel) => item.cameraID == newAOItem.cameraID);
            const currentUCItem = _.find(items, item => item.ultracartItemID == _.get(currentAOItem, 'ultracartItemID'));
            const newUCItem = _.find(items, item => item.ultracartItemID == _.get(newAOItem, 'ultracartItemID'));
            reviewItems.push({currentAOItem, currentUCItem, newAOItem, newUCItem})
        });

        return reviewItems;
    }
)

export const getResumedCameraPlans = createSelector(
    [selectCurrentAutoOrder, getModifiedAutoOrder, getItems],
    (currentAutoOrder: IAutoOrderModel, modifiedAutoOrder: IAutoOrderModel, items: IItem[]): IDataPlanReviewItem[] => {
        const renewItems: IAutoOrderItemModel[] = _.get(modifiedAutoOrder, 'items', []).filter((item: IAutoOrderItemModel) => item.reviewType == 'renew');

        let reviewItems: IDataPlanReviewItem[] = [];
        renewItems.forEach(newAOItem => {
            const currentAOItem = _.find(_.get(currentAutoOrder, 'items'), (item: IAutoOrderItemModel) => item.cameraID == newAOItem.cameraID);
            const currentUCItem = _.find(items, item => item.ultracartItemID == _.get(currentAOItem, 'ultracartItemID'));
            const newUCItem = _.find(items, item => item.ultracartItemID == _.get(newAOItem, 'ultracartItemID'));
            reviewItems.push({currentAOItem, currentUCItem, newAOItem, newUCItem})
        })
        return reviewItems;
    }
)

export const getResumedNowCameraPlans = createSelector(
    [selectCurrentAutoOrder, getModifiedAutoOrder, getItems],
    (currentAutoOrder: IAutoOrderModel, modifiedAutoOrder: IAutoOrderModel, items: IItem[]): IDataPlanReviewItem[] => {
        const renewItems: IAutoOrderItemModel[] = _.get(modifiedAutoOrder, 'items', []).filter((item: IAutoOrderItemModel) => item.reviewType == 'renew now');

        let reviewItems: IDataPlanReviewItem[] = [];
        renewItems.forEach(newAOItem => {
            const currentAOItem = _.find(_.get(currentAutoOrder, 'items'), (item: IAutoOrderItemModel) => item.cameraID == newAOItem.cameraID);
            const currentUCItem = _.find(items, item => item.ultracartItemID == _.get(currentAOItem, 'ultracartItemID'));
            const newUCItem = _.find(items, item => item.ultracartItemID == _.get(newAOItem, 'ultracartItemID'));
            reviewItems.push({currentAOItem, currentUCItem, newAOItem, newUCItem})
        })
        return reviewItems;
    }
)

export const getUpgradeCameraPlans = createSelector(
    [selectCurrentAutoOrder, getModifiedAutoOrder, getItems],
    (currentAO: IAutoOrderModel, modifiedAO: IAutoOrderModel, items: IItem[]): IDataPlanReviewItem[] => {
        const upgradeItems: IAutoOrderItemModel[] = _.get(modifiedAO, 'items', []).filter((item: IAutoOrderItemModel) => item.reviewType == 'upgrade');

        let reviewItems: IDataPlanReviewItem[] = [];
        upgradeItems.forEach(newAOItem => {
            const currentAOItem = _.find(_.get(currentAO, 'items'), (item: IAutoOrderItemModel) => item.cameraID == newAOItem.cameraID);
            const currentUCItem = _.find(items, item => item.ultracartItemID == _.get(currentAOItem, 'ultracartItemID'));
            const newUCItem = _.find(items, item => item.ultracartItemID == _.get(newAOItem, 'ultracartItemID'));
            reviewItems.push({currentAOItem, currentUCItem, newAOItem, newUCItem})
        })
        return reviewItems;
    }
);

export const getDowngradeCameraPlans = createSelector(
    [selectCurrentAutoOrder, getModifiedAutoOrder, getItems],
    (currentAO: IAutoOrderModel, modifiedAO: IAutoOrderModel, items: IItem[]): IDataPlanReviewItem[] => {
        const downgradeItems: IAutoOrderItemModel[] = _.get(modifiedAO, 'items', []).filter((item: IAutoOrderItemModel) => item.reviewType == 'downgrade');

        let reviewItems: IDataPlanReviewItem[] = [];
        downgradeItems.forEach(newAOItem => {
            const currentAOItem = _.find(_.get(currentAO, 'items'), (item: IAutoOrderItemModel) => item.cameraID == newAOItem.cameraID);
            const currentUCItem = _.find(items, item => item.ultracartItemID == _.get(currentAOItem, 'ultracartItemID'));
            const newUCItem = _.find(items, item => item.ultracartItemID == _.get(newAOItem, 'ultracartItemID'));
            reviewItems.push({currentAOItem, currentUCItem, newAOItem, newUCItem})
        })
        return reviewItems;
    }
);

export const getUnchangedCameraPlans = createSelector(
    [selectCurrentAutoOrder, getModifiedAutoOrder, getItems],
    (currentAO: IAutoOrderModel, modifiedAO: IAutoOrderModel, items: IItem[]): IDataPlanReviewItem[] => {
        const unchangedItems: IAutoOrderItemModel[] = _.get(modifiedAO, 'items', []).filter((item: IAutoOrderItemModel) => item.reviewType == 'no change');

        let reviewItems: IDataPlanReviewItem[] = [];
        unchangedItems.forEach(newAOItem => {
            const currentAOItem = _.find(_.get(currentAO, 'items'), (item: IAutoOrderItemModel) => item.cameraID == newAOItem.cameraID);
            const currentUCItem = _.find(items, item => item.ultracartItemID == _.get(currentAOItem, 'ultracartItemID'));
            const newUCItem = _.find(items, item => item.ultracartItemID == _.get(newAOItem, 'ultracartItemID'));
            reviewItems.push({currentAOItem, currentUCItem, newAOItem, newUCItem})
        })
        return reviewItems;
    }
);

export const getUpgradeDueNow = createSelector(
    [getUpgradeNow, getUpgradeCameraPlans],
    (upgradeNow: number[], upgradeCameraPlans: IDataPlanReviewItem[]): number => {
        let total: number = 0;
        if (!upgradeNow || upgradeNow.length == 0) return total;
        
        upgradeNow.forEach(id => {
            const plan = _.find(upgradeCameraPlans, upgradePlan => upgradePlan.currentAOItem.cameraID == id);
            if (plan) total += getUpgradePlanReviewPrice(plan).purchaseCost;
        });

        return total;
    }
)

function applyDiscountToPrice(price: number, coupon: ICouponModel): number {
    const discount = _.find(_.get(coupon, 'couponDiscounts', []), d => d.currency == "USD");
    var result = price;
    if (discount) {
        if (coupon.type == "percentOff") {
            result -= (price * discount.discount);
        } else if (coupon.type == "amountOff") {
            result -= discount.discount;

            if (result < 0) {
                result = 0;
            }
        }
    }

    return result;
}

export const getDataPlanReviewPrice = (plan: IDataPlanReviewItem): { itemCost: number, purchaseCost: number } => {
    let itemCost = _.get(plan.newUCItem, 'price') as number;
    let purchaseCost = _.get(plan.newAOItem, 'overridePrice', itemCost) as number;

    const firstBilling = _.get(plan, 'newAOItem.billings[0]') as IAutoOrderItemBillingModel;
    if (firstBilling && (firstBilling.renewPrice === 0 || firstBilling.renewPrice > 0)) {
        purchaseCost = firstBilling.renewPrice
    }

    return { itemCost, purchaseCost };
}

export const getSupplementalDataPrice = (autoOrder: IAutoOrderModel, items: IItem[]): { itemCost: number, purchaseCost: number } => {
    let itemCost = _.find(items, item => item.ultracartItemID == autoOrder.supplementalData).price;
    let purchaseCost = itemCost;

    if (autoOrder.supplementalDataCoupon) {
        purchaseCost = applyDiscountToPrice(itemCost, autoOrder.supplementalDataCoupon);
    }

    return { itemCost, purchaseCost };
}

export const getSupplementalThumbnailPrice = (autoOrder: IAutoOrderModel, items: IItem[]): { itemCost: number, purchaseCost: number } => {
    let itemCost = _.find(items, item => item.ultracartItemID == autoOrder.supplementalThumbnails).price;
    let purchaseCost = itemCost;

    if (autoOrder.supplementalThumbnailCoupon) {
        purchaseCost = applyDiscountToPrice(itemCost, autoOrder.supplementalThumbnailCoupon);
    }

    return { itemCost, purchaseCost };
}

export const getPhotoPackPrice = (autoOrder: IAutoOrderModel, items: IItem[]): { itemCost: number, purchaseCost: number } => {
    let itemCost = _.find(items, item => item.ultracartItemID == autoOrder.photoPack).price;
    let purchaseCost = itemCost;

    if (autoOrder.photoPackCoupon) {
        purchaseCost = applyDiscountToPrice(itemCost, autoOrder.photoPackCoupon);
    }

    return { itemCost, purchaseCost };
}


export const getUpgradePlanReviewPrice = (plan: IDataPlanReviewItem): { itemCost: number, purchaseCost: number } => {
    let itemCost = plan.newUCItem.price - plan.currentUCItem.price;
    let purchaseCost = itemCost;

    const firstBilling = _.get(plan, 'newAOItem.billings[0]') as IAutoOrderItemBillingModel;
    if (firstBilling && (firstBilling.renewPrice === 0 || firstBilling.renewPrice > 0)) {
        purchaseCost = firstBilling.renewPrice
    }
    return { itemCost, purchaseCost };
}

export const getPlanCoupon = (plan: IDataPlanReviewItem): string => {
    let itemCoupon = _.get(plan, 'newAOItem.itemCoupon.code') as string;
    if (!itemCoupon) {
        const billings = _.get(plan, 'newAOItem.billings', []) as IAutoOrderItemBillingModel[];
        for (var billing of billings) {
            if (billing.coupon) {
                itemCoupon = billing.coupon;
                break;
            }
        }
    }
    return itemCoupon;
}

export const getTotalDueNow = createSelector(
    [getUpgradeDueNow, getNewCameraPlans, getModifiedAutoOrder, getItems],
    (upgradeDueNow: number, newPlans: IDataPlanReviewItem[], modifiedAutoOrder: IAutoOrderModel, items: IItem[]): number => {
        let total = upgradeDueNow;
        if (!modifiedAutoOrder) return total;
        // if (!newPlans || newPlans.length == 0) return total;

        newPlans.forEach(plan => total += getDataPlanReviewPrice(plan).purchaseCost);

        if (modifiedAutoOrder.supplementalData) total += getSupplementalDataPrice(modifiedAutoOrder, items).purchaseCost;

        if (modifiedAutoOrder.supplementalThumbnails) total += getSupplementalThumbnailPrice(modifiedAutoOrder, items).purchaseCost;

        if (modifiedAutoOrder.photoPack) total += getPhotoPackPrice(modifiedAutoOrder, items).purchaseCost;

        if (modifiedAutoOrder.orderCoupon) {
            total = applyDiscountToPrice(total, modifiedAutoOrder.orderCoupon);
        }

        return total;
    }
)

export const hasCurrentDataPlan = createSelector(
    [selectCurrentAutoOrder, getCameras],
    (currentAutoOrder: IAutoOrderModel, cameras: ICameraModel[]): boolean => {
        let result = false;
        const items: IAutoOrderItemModel[] = _.get(currentAutoOrder, 'items', []);
        items.forEach(i => {
            const camera = _.find(cameras, c => c.cameraID == i.cameraID);
            if (camera && camera.hardwareType == 1) result = true;
        })

        return result;
    }
)

export const hasCurrentThumbnailPlan = createSelector(
    [selectCurrentAutoOrder, getCameras],
    (currentAutoOrder: IAutoOrderModel, cameras: ICameraModel[]): boolean => {
        let result = false;
        const items: IAutoOrderItemModel[] = _.get(currentAutoOrder, 'items', []);
        items.forEach(i => {
            const camera = _.find(cameras, c => c.cameraID == i.cameraID);
            if (camera && camera.hardwareType == 0) result = true;
        })

        return result;
    }
)


// Helper functions
function getItemCost(itemID: string, items: IItem[]): number {
    let ucItem = _.find(items, (item) => item.ultracartItemID == itemID);
    if (ucItem) {
        return ucItem.price;
    } else {
        return 0;
    }
}

function getItemName(itemID: string, items: IItem[]): string {
    let ucItem = _.find(items, item => item.ultracartItemID == itemID);
    if (ucItem) {
        return ucItem.description;
    } else {
        return null;
    }
}