import * as React from 'react';
import { Input, InputLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = {
    inkbar: {
        '&:after': {
            backgroundColor: '#F66E32'
        }
    },
    root: {
        fontSize: '14px'
    },
    inputError: {
        '&:after': {
            backgroundColor: '#ff1744'
        }
    },
    focused: {
        color:  '#F66E32'
    },
    formHelperTextRoot: {
        fontSize: '10px'
    },
    formHelperTextError: {
        color: 'red',
        fontSize: '10px'
    }
}


interface StyledInputProps {
    disabled?: boolean
    error?: boolean;
    helperText?: string;
    id?: string;
    label: string;
    name?: string;
    placeholder?: string;
    shrink?: boolean;
    type?: string;
    value?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    endAdornment?: JSX.Element;
    [key: string]: any;
}

export const StyledTextInput = ({ 
    disabled=false, 
    value=undefined, 
    error, 
    helperText, 
    id, 
    label, 
    name=(id || label), 
    placeholder, 
    shrink, 
    type='text', 
    onChange, 
    endAdornment,
    ...props
}: StyledInputProps) => {
    return (
        <FormControl error={error}>
            <InputLabel shrink={shrink} htmlFor={id}>{label}</InputLabel>
            <Input 
                id={id}
                name={name}
                type={type}
                // defaultValue={defaultValue}
                placeholder={placeholder}
                value={value}
                disabled={disabled}
                // classes={{root: classes.root, inkbar: classes.inkbar, error: classes.inputError}}
                onChange={onChange}
                endAdornment={endAdornment}
                inputProps={{...props}}
            />
            <FormHelperText 
                // classes={{
                //     root: classes.formHelperTextRoot,
                //     error: classes.formHelperTextError
                // }}
                >
                {helperText}
            </FormHelperText>
        </FormControl>
    )
}