import { all, call, CallEffect, put, select, takeEvery } from 'redux-saga/effects';
import { addSelected, clearSelected, removeUnselected, retrieveCount, setCount, setSelecting, DELETE_SELECTED, RESET_COUNT, RETRIEVE_COUNT, SELECT, SET_GRID, UNSELECT } from './index';
import { State } from '../index';
import { mediaManagerInstance } from 'tcmediamanager';

import { PayloadAction } from '../../../Types';


const selectedMediaSelector = (state: State): number[]  => state.gallery.selectedMedia

export function* watchSelect() {
    yield takeEvery(SELECT, checkSetSelecting);
    yield takeEvery(UNSELECT, checkUnsetSelecting);
    yield takeEvery(DELETE_SELECTED, deleteAllSelectedSaga)
    yield takeEvery(RESET_COUNT, resetCountSaga);
    yield takeEvery(RETRIEVE_COUNT, retrieveCountSaga);
    yield takeEvery(SET_GRID, setGrid)
}

function* checkSetSelecting({type, payload}: PayloadAction<number>) {
    const selectedMedia = yield select(selectedMediaSelector);
    if (selectedMedia.length == 0) yield put(setSelecting(true));
    yield put(addSelected(payload));
}

function* checkUnsetSelecting({type, payload}: PayloadAction<number>) {
    const selectedMedia = yield select(selectedMediaSelector);
    if (selectedMedia.length == 1 && selectedMedia[0] == payload) yield put(setSelecting(false));
    yield put(removeUnselected(payload));
}

function* deleteAllSelectedSaga({type}: PayloadAction<void>) {
    const selectedMedia = yield select(selectedMediaSelector)
    let p: CallEffect[] = [];
    selectedMedia.forEach((id: number) => p.push(call([mediaManagerInstance, mediaManagerInstance.deleteImage as any], [id])));
    yield all(p);
    yield put(clearSelected());
}

export function* resetCountSaga() {
    yield put(setCount(0))
    yield put(retrieveCount())
}

export function* retrieveCountSaga() {
    const count = yield call([mediaManagerInstance, mediaManagerInstance.getTotalMediaCount])
    yield put(setCount(count));
}

function* setGrid({type, payload}: PayloadAction<boolean>) {
    yield call([localStorage, localStorage.setItem], 'grid', payload ? '1' : '0')
}