export enum CameraHardwareType {
    Aggressor=0,
    Impulse = 1,
    PrimosProof = 2,
    Cellucore30 = 3,
    Cellucore24 = 4,
    Cellucore20 = 5,
    Cellucore20Solar = 6,
    CellucoreLive = 7,
    Cellucore20Dual = 8
}