import * as React from 'react';
import { Col, Row } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
let randomColor = require('randomcolor');

import { isBushnell } from '../../constants';
import { withStyles, WithStyles, StyledComponentProps } from '@material-ui/core/styles';

const primaryColor = isBushnell ? '#F66E32' : "#62BB46"
const secondaryColor = isBushnell ? "#6AA652" : "#F67318"

interface IMultiProgressBarProps {
    cameras: any[];
    label: string;
}

const styles = (theme: any) => ({
    tooltip: {
        backgroundColor: secondaryColor,
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(16)
        }
    }
})

export const MultiProgressBar  = React.memo(withStyles(styles)(({ cameras, classes, label }: IMultiProgressBarProps & StyledComponentProps ) => {
    let totalUsed = 0, totalData = 0;
    cameras.forEach(camera => {
        totalUsed += camera.dataUsed;
        totalData += camera.dataPlan;
    });
    return (
        <div className='multi-progress-bar__container'>
            <Row className='container'>
                <Col sm={2} xs={12} className='multi-progress-bar__label'>{label}</Col>
                <Col 
                    sm={10} xs={12}
                    className='multi-progress-bar__outer-container' 
                    style={{height: '30px', position: 'relative'}}>
                    {cameras.map((camera, i) => {
                        return <Tooltip 
                            className={`multi-progress-bar__segment-container`} 
                            classes={{tooltip: classes.tooltip}}
                            placement='top'
                            title={<div 
                                className='center-items'
                                style={{display: 'flex', flexFlow: 'column nowrap'}}>
                                <span>{camera.cameraName}</span>
                                <span>{`${camera.dataUsed/1000000}MB`}</span>
                            </div>} 
                            style={{flex: (camera.dataUsed/totalData)}}>
                            <div 
                                className='multi-progress-bar__segment'
                                style={{
                                    height: '100%',
                                    backgroundColor: randomColor({
                                        hue: (i%2==0) ? primaryColor : secondaryColor,
                                        seed: i,
                                        luminosity: 'bright'
                                    })}}/>
                            </Tooltip>
                    })}
                    <div style={{flex: ((totalData-totalUsed)/totalData), backgroundColor: 'transparent'}}></div>
                
                </Col>
            </Row>
        </div>
    )
}))