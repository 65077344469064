import * as React from 'react'
import * as _ from 'lodash';
import { FormattedMessage, injectIntl, InjectedIntlProps, defineMessages } from "react-intl";

export interface ITCTextInputProps {
    label?: string,
}

const appStrings = defineMessages({
    text: {
        id: "TCTextInput.text.label",
        defaultMessage: "text"
    }
});

class TCTextInputWrapper extends React.Component<ITCTextInputProps & InjectedIntlProps & React.HTMLProps<HTMLInputElement>, {}> {

    constructor(props: ITCTextInputProps & InjectedIntlProps & React.HTMLProps<HTMLInputElement>) {
        super(props);
    }
	
	updateState(values: Object) {
        var newState = _.assign(this.state, values);
        this.setState(newState);
    }

    render(): JSX.Element {
        const {formatMessage} = this.props.intl;
        let labelElement: JSX.Element = null;
        if (this.props.label) {
            labelElement = <div>{this.props.label}</div>
        }
        return (
            <div key={this.props.id} className={'flex-container-column underlineInput ' + this.props.className} style={{ alignItems: 'flex-start', ...this.props.style }}>
                <div style={{ fontSize: '13px', fontWeight: 'bolder', color: '#6B625E' }}>{this.props.label}</div>
                <input type={this.props.type ? this.props.type : 'text'} id={this.props.id} value={this.props.value} onChange={this.props.onChange} style={{ width: '100%' }} />
            </div>
        );
    }
}

export const TCTextInput = injectIntl(TCTextInputWrapper);