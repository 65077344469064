import * as React from "react";
import { FormattedMessage, injectIntl, InjectedIntlProps, defineMessages } from "react-intl";
import { ControlLabel, FormGroup, FormControl } from "react-bootstrap";

import { isBushnell } from '../../constants';
import appStrings from './appStrings';


export interface ITCLanguageSelectorProps {

}

class TCLanguageSelectorWrapper extends React.Component<ITCLanguageSelectorProps & InjectedIntlProps, any> {

    handleSelectChange(event: any) {
        event.target.form.submit();
    }

    render(): JSX.Element {
        const {formatMessage} = this.props.intl;
        return (
            <form action="SetLanguage" method="post">
                <FormGroup controlId="formControlsSelect">
                    <ControlLabel style={{color: isBushnell ? '#333' : '#FFF'}} >{formatMessage(appStrings.select)}</ControlLabel>
                    <FormControl defaultValue={this.props.intl.locale} componentClass="select" placeholder="select" name="culture" onChange={this.handleSelectChange}>
                        <option value="en-US">{formatMessage(appStrings.english)}</option>
                        <option value="fr-CA">{formatMessage(appStrings.frenchCanadian)}</option>
                    </FormControl>
                </FormGroup>
            </form>
        );
    }
}

export const TCLanguageSelector = injectIntl(TCLanguageSelectorWrapper);
