import * as React from 'react';
import { Form } from 'react-form';
import { defineMessages, injectIntl, InjectedIntlProps } from 'react-intl';
import { toast } from 'react-toastify';
import * as request from 'superagent';

import { TextInput } from '../Elements';
import { State } from '../../reducers';
import { Dispatch, connect } from 'react-redux';
import { error } from '../../reducers/sagas';

const appStrings = defineMessages({
    email: {
        id: "TCCreateAccount.email.label",
        defaultMessage: "Email"
    },
    emailError: {
        id: "TCCreateAccount.email.error",
        defaultMessage: "Email is invalid"
    },
    confirmEmail: {
        id: "TCCreateAccount.confirmEmail.label",
        defaultMessage: "Confirm Email"
    },
    emailRequired: {
        id: 'TCCreateAccount.emailRequired.error',
        defaultMessage: 'Email is required'
    },
    confirmEmailError: {
        id: "TCCreateAccount.confirmEmail.error",
        defaultMessage: "Emails do not match"
    },
    password: {
        id: "TCCreateAccount.password.label",
        defaultMessage: "Password"
    },
    passwordError: {
        id: "TCCreateAccount.password.error",
        defaultMessage: "A password of 6 or more characters is required"
    },
    passwordRequired: {
        id: 'TCCreateAccount.passwordRequired.error',
        defaultMessage: 'Password is required'
    },
    confirmPassword: {
        id: "TCCreateAccount.confirmPassword.label",
        defaultMessage: "Confirm Password"
    },
    confirmPasswordError: {
        id: "TCCreateAccount.confirmPassword.error",
        defaultMessage: "Passwords do not match"
    },
    mobileNumber: {
        id: "TCCreateAccount.mobileNumber.label",
        defaultMessage: "Mobile Number"
    },
    primaryNumber: {
        id: "TCCreateAccount.primaryNumber.label",
        defaultMessage: "Primary Number"
    },
    primaryNumberHelper: {
        id: 'TCCreateAccount.primaryNumber.helper',
        defaultMessage: '* if different than mobile number' 
    },
    create: {
        id: "TCCreateAccount.create.label",
        defaultMessage: "Create Account"
    }
});

interface TCCreateAccountProps {
    goTo: (route: string)=>void
}

type ITCCreateAccountAllProps = In & Out & TCCreateAccountProps & InjectedIntlProps;

class TCCreateAccount extends React.Component<ITCCreateAccountAllProps, {}> {
    
    validate(values: any, state: any, props: any, instance: any) {
        const { formatMessage } = this.props.intl;
        const { email, confirmEmail, password, confirmPassword } = values;
        return {
            email: email 
                ? !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
                    ? formatMessage(appStrings.emailError)
                    : null
                : formatMessage(appStrings.emailRequired),
            confirmEmail: confirmEmail 
                ? !(email === confirmEmail) ? formatMessage(appStrings.confirmEmailError) : null
                : formatMessage(appStrings.emailRequired),
            password: password 
                ? password.length < 6 ? formatMessage(appStrings.passwordError) : null
                : formatMessage(appStrings.passwordRequired),
            confirmPassword: confirmPassword 
                ? !(password === confirmPassword) ? formatMessage(appStrings.confirmPasswordError) : null
                : formatMessage(appStrings.passwordRequired),
        }
    }

    async submit({email, password, mobileNumber, primaryNumber}: any) {
        let body = {
            email,
            password,
            phoneNumber: mobileNumber,
            primaryContactNumber: primaryNumber || mobileNumber
        }
        try {
            await request
                .post('/api/Account/Create')
                .send(body)
            
            toast('Account Created.  Please check your inbox for email confirmation.')
            this.props.goTo('login');
        }
        catch (err) {
            toast.error("Unable to create account");
            this.props.dError(err);
        }
    }

    render() {
        const { intl, goTo } = this.props;
        const { formatMessage } = intl;
        return (
            <div style={{position: 'relative'}}>
                <div 
                    className={"flex-container"} 
                    style={{ 
                        position: 'absolute',
                        top: 0,
                        left: '-50px',
                        alignItems: 'center', 
                        justifyContent: 'space-between', 
                        marginBottom: '10px',
                    }}>
                    <div className={"flex-container"} style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
                        <a onClick={() => goTo('login')} className='circleButton' ><img src={"/images/chevron-left.svg"} /></a>
                    </div>
                </div>
                <Form
                    onSubmit={this.submit.bind(this)}
                    validate={this.validate.bind(this)}
                    defaultValues={{
                        email: '',
                        confirmEmail: '',
                        password: '',
                        confirmPassword: '',
                        mobileNumber: '',
                        primaryNumber: ''
                    }}>
                    {(props: any) => {
                        return <form>
                            <TextInput field='email' label={formatMessage(appStrings.email)} />
                            <TextInput field='confirmEmail' label={formatMessage(appStrings.confirmEmail)} />
                            <TextInput field='password' label={formatMessage(appStrings.password)} type='password' />
                            <TextInput field='confirmPassword' label={formatMessage(appStrings.password)} type='password' />
                            <TextInput field='mobileNumber' label={formatMessage(appStrings.mobileNumber)} />
                            <TextInput field='primaryNumber' label={formatMessage(appStrings.primaryNumber)} helperText={formatMessage(appStrings.primaryNumberHelper)} />
                            <a className={'orangeButton'} onClick={props.submitForm}>{formatMessage(appStrings.create)}</a>
                        </form>
                    }}
                </Form>
            </div>
        )
    }
}

interface In {}

const stateToProps = (state: State):In => ({});

interface Out {
    dError: (err: any) => void;
}

const dToProps = (d: Dispatch<State>): Out => ({
    dError: e => d(error(e))
})

export default connect(stateToProps, dToProps)(injectIntl(TCCreateAccount));