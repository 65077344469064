import { delay } from 'redux-saga'
import { call, put, takeEvery, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as request from 'superagent';

import { watchAccount, getSupplementalData, getLegalData } from './account';
import { watchGetCamera } from './cameras/sagas';
import { watchSelect } from '../reducers/gallery/sagas';
import { watchMedia, getAccountFilterInfo } from '../reducers/media/sagas';
import { watchUI } from '../reducers/ui/sagas'; 
import { setGrid } from '../reducers/gallery';
import { watchBilling } from '../reducers/billing/sagas';
import { getMessages, watchMessages } from './messages';
import { getDetails } from './account';
import { PayloadAction } from '../../Types';

export default function* rootSaga() {
    yield all([
        watchAccount(),
        watchGetCamera(),
        watchSelect(),
        watchMedia(),
        watchUI(),
        watchBilling(),
        watchMessages(),
        watchRoot(),
    ]);
}

export const error = (err: any) => ({ type: 'ERROR', payload: err })

function* watchRoot() {
    yield takeEvery('INIT', init);
    yield takeEvery('ERROR', onError);
}

function* init() {
    yield all([
        put(getDetails()),
        put(getLegalData()),
        put(getSupplementalData()),
        put(getMessages()),
        setGridFromMemory(),
        getAccountFilterInfo(),
        startHeartbeat(),
    ])
}

function* onError({ payload }: PayloadAction<any>) {
    if (payload && payload.status == 401) {
        window.location.replace('/');
    } else if (payload && payload.response) {
        return toast.error(payload.response);
    } else {
        return toast.error('There was an error');
    }
}

function* setGridFromMemory() {
    let grid = localStorage.getItem('grid');
    yield put(setGrid(!!parseInt(grid)))
}

function* startHeartbeat() {
    while(true) {
        yield delay(1000*60*15);
        try {
            yield request
                .post('/api/Account/Heartbeat')
        }
        catch(err) {
            yield put(error(err));
        }
    }
}