import * as React from 'react';
import { StyledComponentProps, Theme, withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

interface ILoadingButtonProps {
    label: string;
    loading: boolean;
    type: 'green' | 'orange' | 'white';
    onClick: () => void;
}

const styles = (theme: Theme) => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
})

export const LoadingButton = withStyles(styles as any)(({ classes, label, loading, type, onClick }: ILoadingButtonProps & StyledComponentProps) => {
    return (
        <div className={classes.wrapper}>
            <a 
                className={`${type}Button ${loading ? 'disabled' : ''}`}
                style={{ maxWidth: '200px' }}
                onClick={onClick}>
                {label}
            </a>
            {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
        </div>
    )
})