import { put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import { Actions, api, setDetails, setSupplementalData, setEmailConfirmed, setLegalData } from '.'
import { PayloadAction } from "../../../Types";
import { replace } from "react-router-redux";
import { error } from "../sagas";

function* onGetDetails() {
    try {
        const res = yield api.getDetails();
        yield put(setDetails(res.body));
    }
    catch(err) {
        console.log('Error in onGetDetails: ' + err);
        yield put(error(err));
    }
}

function* onGetLegalData() {
    try {
        const res = yield api.getLegalData();
        yield put(setLegalData(res.body));
    }
    catch (err) {
        console.log('Error in onGetDetails: ' + err);
        yield put(error(err));
    }
}

function* onConfirmEmail({ payload }: PayloadAction<string>) {
    try {
        yield api.confirmEmail(payload);

        yield put(setEmailConfirmed(true));
        toast("Email Confirmed");
    }
    catch (err) {
        console.log('Error in onConfirmEmail: ' + err);
        yield put(error(err));
    }
    finally {
        yield put(replace('/Images'))
    }
}

function* onGetSupplementalData() {
    try {
        const res = yield api.getSupplementalData();
        yield put(setSupplementalData(res.body))
    }
    catch(err) {
        console.log('Error in onGetSupplementalData: ' + err);
        yield put(error(err));
    }
}

function* onResendConfirmationEmail() {
    try {
        yield api.resendConfirmation();
        toast('Confirmation email resent');
    }
    catch(err) {
        console.log('Error in onResendConfirmationEmail: ' + err);
        yield put(error(err));
    }
}

function* onAgreeLegalDocs({ payload }: PayloadAction<string[]>) {
    try {
        yield api.agreeLegalDocs(payload);
    }
    catch (err) {
        console.log('Error in onAgreeLegalDocs: ' + err);
    }
    finally {
        yield put(setLegalData(null));
    }
}

export function* watchAccount() {
    yield takeEvery(Actions.GET_DETAILS, onGetDetails);
    yield takeEvery(Actions.GET_LEGAL_DATA, onGetLegalData);
    yield takeEvery(Actions.GET_SUPPLEMENTAL_DATA, onGetSupplementalData);
    yield takeEvery(Actions.RESEND_CONFIRMATION_EMAIL, onResendConfirmationEmail);
    yield takeEvery(Actions.CONFIRM_EMAIL, onConfirmEmail);
    yield takeEvery(Actions.AGREE_LEGAL_DOCS, onAgreeLegalDocs);
}