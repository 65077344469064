import * as React from 'react'
import { connect, Dispatch } from 'react-redux';
import * as _ from 'lodash';
import * as request from 'superagent';
import { Link } from 'react-router-dom';
import { Glyphicon } from 'react-bootstrap';
import { injectIntl, InjectedIntlProps, defineMessages } from "react-intl";
import { toast } from 'react-toastify';
import { error } from '../../reducers/sagas';
import { State } from '../../reducers';

export interface ITCConfirmEmailProps {
    token: string
}

interface ITCConfirmEmailState {
    emailConfirmed: boolean
}

const appStrings = defineMessages({
    text: {
        id: "TCConfirmEmail.text.label",
        defaultMessage: "text"
    }
});

type ITCConfirmEmailAllProps = ITCConfirmEmailProps & In & Out & InjectedIntlProps

class TCConfirmEmail extends React.Component<ITCConfirmEmailAllProps, ITCConfirmEmailState> {

    constructor(props: ITCConfirmEmailAllProps) {
        super(props);

        this.state = {
            emailConfirmed: null
        }
    }

    componentDidMount() {
        this.confirmToken();
    }
	
	updateState(values: Object) {
        var newState = _.assign(this.state, values);
        this.setState(newState);
    }

    confirmToken = async () => {
        try {
            await request
                .post('/Home/ConfirmEmail')
                .query({ token: this.props.token })

                this.updateState({ emailConfirmed: true });
        }
        catch(err) {
            toast.error('There was an error confirming your email');
            this.props.dError(err);
        }
    }

    render(): JSX.Element {
        const { formatMessage } = this.props.intl;
        const { emailConfirmed } = this.state

        return (
            <div className='email-confirm'>
                <div className='container page-container page'>
                    {emailConfirmed 
                        ?   <div>
                                <h3>Email Confirmed</h3>
                                <h5>You can now log in to your account</h5>
                            </div>
                        :   <div>
                                <h3>Unable to confirm email</h3>
                                <h5>Please try registering again or contact Bushnell support</h5>
                            </div>}
                    <br/>
                    <Link to='/Login' className='orangeButton' style={{display: 'inline'}}>
                        <Glyphicon glyph='arrow-left' style={{marginRight: '10px'}} />
                        <div style={{display: 'inline'}}>Go back to login</div>
                    </Link>
                </div>
            </div>
        );
    }
}

interface In {}

const stateToProps = (state: State):In => ({});

interface Out {
    dError: (err: any) => void;
}

const dToProps = (d: Dispatch<State>): Out => ({
    dError: e => d(error(e))
})

export default connect(stateToProps, dToProps)(injectIntl(TCConfirmEmail));