import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import gray from '@material-ui/core/colors/grey';
import { Color } from "@material-ui/core";
import { isBushnell } from '../constants'

export const bOrange: Color = {
    50: '#feeee6',
    100: '#fcd4c2',
    200: '#fbb799',
    300: '#f99a70',
    400: '#f78451',
    500: '#f66e32',
    600: '#f5662d',
    700: '#f35b26',
    800: '#f2511f',
    900: '#ef3f13',
    A100: '#ffffff',
    A200: '#ffeeeb',
    A400: '#ffc4b8',
    A700: '#ffae9e',
};

export const pGreen: Color = {
    50: '#ffffff',
    100: '#d7eed0',
    200: '#b4dfa7',
    300: '#88cb73',
    400: '#75c35c',
    500: '#62bb46',
    600: '#56a63d',
    700: '#4a8f35',
    800: '#3f792c',
    900: '#336224',
    A100: '#ffffff',
    A200: '#b9f3a7',
    A400: '#7ad85c',
    A700: '#6fc853',
};

export const theme = createMuiTheme({
    palette: {
        primary: isBushnell ? bOrange : pGreen,
        secondary: gray,
        error: red,
    },
    typography: {
        fontFamily: isBushnell ? '"Roboto", Roboto, sans-serif' : '"Klavika", Klavika, sans-serif'
    },
    overrides: {
        MuiIconButton: {
            root: {
                height: '24px',
                width: '24px'
            }
        },
        MuiInput: {
            root: {
                fontSize: '14px'
            },
            input: {
                color: isBushnell ? 'currentColor' : 'white'
            }
        } as any,
        MuiFormHelperText: {
            root: {
                fontSize: '10px',
                color: isBushnell ? 'rgba(0,0,0,0.54)' : 'rgba(255,255,255,.8)',
                "&$error": {
                    color: red[500],
                    fontSize: '10px'
                }
            }
        },
        MuiFormLabel: {
            root: {
                color: isBushnell ? 'rgba(0,0,0,.54)' : 'rgba(255,255,255,.8)'
            }
        },
        MuiCheckbox: {
            root: {
                "&$checked": {
                    color: isBushnell ? '#6AA652' : '#F67318'
                }
            }
        }
    }
});