import { PayloadAction } from '../../../Types';
import * as update from 'immutability-helper';
import { cloneDeep } from 'lodash';

import { IAutoOrderModel, IAutoOrderItemModel, IItem, Cart, CartBilling, CartPaymentCreditCard, CartCustomerProfile, IOrderModel, Customer } from '../../Models/'
import { UnregisterCallback } from 'history';

export interface BillingState {
    cartID: string;
    customerProfile: CartCustomerProfile | Customer
    currentAutoOrder: IAutoOrderModel,
    defaultBilling: {
        billing: any,
        payment: any,
    },
    couponCode: string,
    modifiedAutoOrder: IAutoOrderModel,
    newMonthlyTotal: number,
    nextBillingDate: Date,
    items: IItem[],
    cameraUpgradeNow: number[],
    orders: IOrderModel[]
}

const initialState: BillingState = {
    cartID: null,
    customerProfile: null,
    currentAutoOrder: null,
    couponCode: null,
    defaultBilling: null,
    modifiedAutoOrder: null,
    newMonthlyTotal: 0,
    nextBillingDate: null,
    items: null,
    cameraUpgradeNow: null,
    orders: null
}

export const Actions = {
    APPLY_COUPON: 'billing/APPLY_COUPON',
    ACTIVATE_FREE_PLAN: 'billing/ACTIVATE_FREE_PLAN',
    ACTIVATE_FIRST_FREE_PLAN: 'billing/ACTIVATE_FIRST_FREE_PLAN',
    ADD_CAMERA_UPGRADE_NOW: 'billing/ADD_CAMERA_UPGRADE_NOW',
    CREATE_ONE_TIME_PURCHASE: 'billing/CREATE_ONE_TIME_PURCHASE',
    DELAY_OR_CANCEL: 'billing/DELAY_OR_CANCEL',
    DELETE_BILLING: 'billing/DELETE_BILLING',
    DELETE_CARD: 'billing/DELETE_CARD',
    FETCH_ITEMS: 'billing/FETCH_ITEMS',
    FINISH_ACTIVATE_FIRST_FREE_PLAN: 'billing/FINISH_ACTIVATE_FIRST_FREE_PLAN',
    FINISH_BILLING: 'billing/FINISH_BILLING',
    FINISH_REVIEW: 'billing/FINISH_REVIEW',
    FINISH_CHECKOUT: 'billing/FINISH_CHECKOUT',
    GET_CURRENT_AUTO_ORDER: 'billing/GET_CURRENT_AUTO_ORDER',
    GET_CUSTOMER_PROFILE: 'billing/GET_CUSTOMER_PROFILE',
    GET_DEFAULT_BILLING: 'billing/GET_DEFAULT_BILLING',
    GET_ORDERS: 'billing/GET_ORDERS',
    INITIALIZE: 'billing/INITIALIZE',
    PURCHASE_PHOTO_PACK: 'billing/PURCHASE_PHOTO_PACK',
    REMOVE_CAMERA_UPGRADE_NOW: 'billing/REMOVE_CAMERA_UPGRADE_NOW',
    RENEW: 'billing/RENEW',
    REVIEW_MODIFIED_AUTO_ORDER: 'billing/REVIEW_MODIFIED_AUTO_ORDER',
    SAVE_BILLING: 'billing/SAVE_BILLING',
    SAVE_CARD: 'billing/SAVE_CARD',
    SET_CARTID: 'billing/SET_CARTID',
    SET_COUPON_CODE: 'billing/SET_COUPON_CODE',
    SET_CAMERA_UPGRADE_NOW: 'billing/SET_CAMERA_UPGRADE_NOW',
    SET_CURRENT_AUTO_ORDER: 'billing/SET_CURRENT_AUTO_ORDER',
    SET_CUSTOMER_PROFILE: 'billing/SET_CUSTOMER_PROFILE',
    SET_DEFAULT_BILLING: 'billing/SET_DEFAULT_BILLING',
    SET_NEW_DEFAULT_BILLING: 'billing/SET_NEW_DEFAULT_BILLING',
    SET_NEW_DEFAULT_BILLING_WITH_CALLBACK: 'billing/SET_NEW_DEFAULT_BILLING_WITH_CALLBACK',
    SET_ITEMS: 'billing/SET_ITEMS',
    SET_MODIFIED_AUTO_ORDER: 'billing/SET_MODIFIED_AUTO_ORDER',
    SET_NEW_DEFAULT_PAYMENT: 'billing/SET_NEW_DEFAULT_PAYMENT',
    SET_NEW_DEFAULT_PAYMENT_WITH_CALLBACK: 'billing/SET_NEW_DEFAULT_PAYMENT_WITH_CALLBACK',
    SET_NEW_MONTHLY_TOTAL: 'billing/SET_NEW_MONTHLY_TOTAL',
    SET_NEXT_BILLING_DATE: 'billing/SET_NEXT_BILLING_DATE',
    SET_ORDERS: 'billing/SET_ORDERS',
    SET_PHOTO_PACK: 'billing/SET_PHOTO_PACK',
    SET_SUPPLEMENTAL_DATA: 'billing/SET_SUPPLEMENTAL_DATA',
    SET_SUPPLEMENTAL_THUMBNAILS: 'billing/SET_SUPPLEMENTAL_THUMBNAILS',
    SUBMIT_AUTO_ORDER_UPDATE: 'billing/SUBMIT_AUTO_ORDER_UPDATE',
    UPDATE_CAMERA_VALUE: 'billing/UPDATE_CAMERA_VALUE'
}

export let activateFreePlan = (cameraID: number) => ({type: Actions.ACTIVATE_FREE_PLAN, payload: cameraID});
export let activateFirstFreePlan = () => ({ type: Actions.ACTIVATE_FIRST_FREE_PLAN });
export let addCameraUpgradeNow = (c: number) => ({ type: Actions.ADD_CAMERA_UPGRADE_NOW, payload: c });
export let createOneTimePurchase = (payload: { credits?: string, data?: string, thumbnails?: string }) => (
    { type: Actions.CREATE_ONE_TIME_PURCHASE, payload }
)
export const delayOrCancel = (cameraID: number, value: string, cb: () => void) => ({ type: Actions.DELAY_OR_CANCEL, payload: { cameraID, value, cb } });
export let deleteBilling = (payload: number) => ({ type: Actions.DELETE_BILLING, payload });
export let deleteCard = (payload: number) => ({ type: Actions.DELETE_CARD, payload });
export let fetchItems = () => ({ type: Actions.FETCH_ITEMS });
export let finishActivateFirstFreePlan = (values: { billing: CartBilling, card: CartPaymentCreditCard }) => ({ type: Actions.FINISH_ACTIVATE_FIRST_FREE_PLAN, payload: values});
export let finishBilling = (billing: CartBilling, card: CartPaymentCreditCard, storeCard: boolean, onetime: boolean, unblock: UnregisterCallback) => ({ type: Actions.FINISH_BILLING, payload: { billing, card, storeCard, onetime, unblock } });
export let finishReview = (payload: UnregisterCallback) => ({ type: Actions.FINISH_REVIEW, payload });
export let finishCheckout = (cart: Cart, onetime: boolean, unblock: UnregisterCallback) => ({ type: Actions.FINISH_CHECKOUT, payload: { cart, onetime, unblock } });
export let applyCoupon = (payload: any) => ({ type: Actions.APPLY_COUPON, payload });
export let getCurrentAutoOrder = () => ({ type: Actions.GET_CURRENT_AUTO_ORDER });
export let getCustomerProfile = () => ({ type: Actions.GET_CUSTOMER_PROFILE });
export let getDefaultBilling = () => ({ type: Actions.GET_DEFAULT_BILLING });
export let getOrders = () => ({ type: Actions.GET_ORDERS });
export let initialize = () => ({ type: Actions.INITIALIZE });
export let purchasePhotoPack = (payload: string) => ({ type: Actions.PURCHASE_PHOTO_PACK, payload });
export let removeCameraUpgradeNow = (c: number) => ({ type: Actions.REMOVE_CAMERA_UPGRADE_NOW, payload: c });
export let renew = (cameraID: number, cb: () => void) => ({ type: Actions.RENEW, payload: { cameraID, cb }});
export let reviewModifiedAutoOrder = () => ({ type: Actions.REVIEW_MODIFIED_AUTO_ORDER });
export let saveBilling = (billing: CartBilling, callback?: (selected: number) => void) => ({ type: Actions.SAVE_BILLING, payload: { billing, callback }});
export let saveCard = (card: CartPaymentCreditCard, callback?: (selected: number) => void) => ({ type: Actions.SAVE_CARD, payload: { card, callback }});
export let setCameraUpgradeNow = (c: number[]) => ({ type: Actions.SET_CAMERA_UPGRADE_NOW, payload: c });
export let setCartID = (id: string) => ({ type: Actions.SET_CARTID, payload: id });
export let setCouponCode = (couponCode: string) => ({ type: Actions.SET_COUPON_CODE, payload: couponCode });
export let setCurrentAutoOrder = (currentAutoOrder: IAutoOrderModel) => ({ type: Actions.SET_CURRENT_AUTO_ORDER, payload: currentAutoOrder });
export let setCustomerProfile = (payload: CartCustomerProfile) => ({ type: Actions.SET_CUSTOMER_PROFILE, payload })
export let setDefaultBilling = (payload: {payment: any, billing: any}) => ({ type: Actions.SET_DEFAULT_BILLING, payload });
export let setItems = (items: IItem[]) => ({ type: Actions.SET_ITEMS, payload: items });
export let setModifiedAutoOrder = (modifiedAutoOrder: IAutoOrderModel) => ({ type: Actions.SET_MODIFIED_AUTO_ORDER, payload: modifiedAutoOrder });
export let setNewDefaultBilling = (payload: CartBilling) => ({ type: Actions.SET_NEW_DEFAULT_BILLING, payload });
export let setNewDefaultBillingWithCallback = (billing: CartBilling, callback?: () => void) => ({ type: Actions.SET_NEW_DEFAULT_BILLING_WITH_CALLBACK, payload: { billing, callback } });
export let setNewDefaultPayment = (payload: CartPaymentCreditCard) => ({ type: Actions.SET_NEW_DEFAULT_PAYMENT, payload });
export let setNewDefaultPaymentWithCallback = (card: CartPaymentCreditCard, callback: () => void) => ({ type: Actions.SET_NEW_DEFAULT_PAYMENT_WITH_CALLBACK, payload: { card, callback } });
export let setNewMonthlyTotal = (total: number) => ({ type: Actions.SET_NEW_MONTHLY_TOTAL, payload: total });
export let setNextBillingDate = (nextBilling: Date) => ({ type: Actions.SET_NEXT_BILLING_DATE, payload: nextBilling });
export let setOrders = (payload: IOrderModel[]) => ({ type: Actions.SET_ORDERS, payload });
export let setPhotoPack = (payload: string) => ({ type: Actions.SET_PHOTO_PACK, payload });
export let setSupplementalData = (payload: string) => ({ type: Actions.SET_SUPPLEMENTAL_DATA, payload });
export let setSupplementalThumbnails = (payload: string) => ({ type: Actions.SET_SUPPLEMENTAL_THUMBNAILS, payload });
export let submitAutoOrderUpdate = () => ({ type: Actions.SUBMIT_AUTO_ORDER_UPDATE });
export let updateCameraValue = (cameraID: number, value: string, free?: boolean) => (
    { type: Actions.UPDATE_CAMERA_VALUE, payload: { cameraID, value, free } }
)

export default (state: BillingState = initialState, { type, payload }: PayloadAction<any>) => {
    switch (type) {
        case Actions.SET_COUPON_CODE:
            return update(state, { couponCode: { $set: payload } });

        case Actions.SET_CARTID:
            return update(state, { cartID: { $set: payload } });

        case Actions.SET_CURRENT_AUTO_ORDER:
            return update(state, { currentAutoOrder: { $set: payload } });

        case Actions.SET_DEFAULT_BILLING:
            return update(state, { defaultBilling: { $set: payload } });

        case Actions.SET_MODIFIED_AUTO_ORDER:
            return update(state, { modifiedAutoOrder: { $set: payload } });

        case Actions.SET_NEW_MONTHLY_TOTAL:
            return update(state, { newMonthlyTotal: { $set: payload } });

        case Actions.SET_ITEMS:
            return update(state, { items: { $set: payload } });

        case Actions.SET_CAMERA_UPGRADE_NOW:
            return update(state, { cameraUpgradeNow: { $set: payload } });

        case Actions.SET_CUSTOMER_PROFILE:
            return update(state, { customerProfile: { $set: payload } });

        case Actions.SET_ORDERS:
            return update(state, { orders: { $set: payload } });

        case Actions.SET_PHOTO_PACK:
            let modifiedAutoOrderPhoto = cloneDeep(state.modifiedAutoOrder ? state.modifiedAutoOrder : (state.currentAutoOrder ? state.currentAutoOrder : {}));
            modifiedAutoOrderPhoto.photoPack = payload;
            return update(state, { modifiedAutoOrder: { $set: modifiedAutoOrderPhoto } });

            
        case Actions.SET_SUPPLEMENTAL_DATA:
            let modifiedAutoOrderData = cloneDeep(state.modifiedAutoOrder ? state.modifiedAutoOrder : state.currentAutoOrder);
            modifiedAutoOrderData.supplementalData = payload;
            return update(state, { modifiedAutoOrder: { $set: modifiedAutoOrderData } });

        case Actions.SET_SUPPLEMENTAL_THUMBNAILS:
            let modifiedAutoOrderThumbnails = cloneDeep(state.modifiedAutoOrder ? state.modifiedAutoOrder : state.currentAutoOrder);
            modifiedAutoOrderThumbnails.supplementalThumbnails = payload;
            return update(state, { modifiedAutoOrder: { $set: modifiedAutoOrderThumbnails } })
        
        default:
            return state;
    }
}

export * from './api';
export * from './sagas';
export * from './selectors';