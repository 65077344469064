import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { mediaManagerInstance, MediaCriteria, MediaFilterIn } from 'tcmediamanager';
import * as update  from 'immutability-helper';
import { cloneDeep, filter, find, isNull } from 'lodash';
import { toast } from 'react-toastify';
import { replace, push } from 'react-router-redux';
import * as request from 'superagent';

import { Actions, setCurrentMedia, setCurrentMediaID, setCurrentMediaIndex, setCurrentMediaRating, setCurrentMediaTags, setThumbnail, setNextMedia, 
    setPreviousMedia, setTags } from './index';
import { toggleModalLoading, toggleModal, toggleLoading } from '../ui';
import { setCameras } from '../cameras';
import { resetCount, setCount } from '../gallery'
import { retrieveCountSaga } from '../gallery/sagas';
import { PayloadAction, State } from '../../../Types';
import { ITagModel } from '../../Models/ITagModel';
import { error } from '../sagas';

const backgroundLoadingSelector = (state: State) => state.ui.loading.background;

export function* getAccountFilterInfo() {
    try {
        const response = yield request('/api/Media/AccountFilterInfo/');
        const { cameras, tags }: { cameras: any, tags: Array<ITagModel> } = response.body;
        yield all([
            put(setTags(tags)),
            put(setCameras(cameras))
        ])
    }
    catch (err) {
        yield put(error(err))
    }
}

function* addTag({type, payload}: PayloadAction<{mediaID: number, tagName: string}>) {
    const state: State = yield select(); 
    const { mediaID, tagName } = payload;
    const tagID: number = yield call([mediaManagerInstance, mediaManagerInstance.tagMedia], mediaID, tagName);
    const tag = {tagName, tagID};
    yield all([
        put(setCurrentMediaTags(update(state.media.currentMedia.thumbnail.tags, {$push: [tag]}))),
        getAccountFilterInfo()
    ])
}

function* removeTag({type, payload}: PayloadAction<{mediaID: number, tagID: number}>) {
    const { mediaID, tagID } = payload;
    yield call([mediaManagerInstance, mediaManagerInstance.removeTag], mediaID, tagID);

    const state: State = yield select();
    const tags = filter(state.media.currentMedia.thumbnail.tags, tag => tag.tagID != tagID) as ITagModel[];
    yield all([
        put(setCurrentMediaTags(tags)),
        getAccountFilterInfo()
    ])
}

function* requestFullRes({type, payload}: PayloadAction<number>) {
    try {
        yield request(`/api/Media/RequestFullUpload/${payload}`);
        let state: State = yield select();
        yield put(setThumbnail(update(state.media.currentMedia.thumbnail, {fullSizeRequested: {$set: true}})));
    } 
    catch (err) {
        yield put(error(err));
    }
}

function* getAndSetThumbnail({mediaID, index} :{mediaID: number, index: number}) {
    try {
        let state: State = yield select();
        let thumbnail;
        
        if (!isNull(index)) {
            thumbnail = yield* getThumbnail(index);
            yield put(setThumbnail(thumbnail));
        }
        
        let p = [];
        thumbnail = yield* getFullsize(mediaID);
        p.push(put(setThumbnail(thumbnail)));
        
        if (state.media.currentMedia.index != 0 && state.media.currentMedia.index != null) {
            let previousThumbnail = yield* getThumbnail(index - 1);
            if (!previousThumbnail) {
                yield* getThumbnails(index - 1, index - 1);
                previousThumbnail = yield* getThumbnail(index - 1);
            }
            previousThumbnail = yield* getFullsize(previousThumbnail.mediaID);
            p.push(put(setPreviousMedia({mediaID: previousThumbnail.mediaID, thumbnail: previousThumbnail})));
        } else p.push(put(setPreviousMedia({mediaID: null, thumbnail: null})))

        if (state.media.currentMedia.index != state.gallery.count -1 && state.media.currentMedia.index != null) {
            let nextThumbnail = yield* getThumbnail(index + 1);
            if (!nextThumbnail) {
                yield* getThumbnails(index + 1, index + 1);
                nextThumbnail = yield getThumbnail(index + 1);
            }
            nextThumbnail = yield getFullsize(nextThumbnail.mediaID);
            p.push(put(setNextMedia({mediaID: nextThumbnail.mediaID, thumbnail: nextThumbnail})))
        } 
        else p.push(put(setNextMedia({mediaID: null, thumbnail: null})))
        
        yield all(p);
    } catch(err) {console.log(err)}
}

function* getFullsize(mediaID: number) {
    return yield call([mediaManagerInstance, mediaManagerInstance.getFullsize], mediaID);
}

function* getThumbnail(index: number) {
    return yield call([mediaManagerInstance, mediaManagerInstance.getThumbnail], index)
}

function* getThumbnails(start: number, end: number) {
    yield call([mediaManagerInstance, mediaManagerInstance.getThumbnails], start, end)
}

function* onBulkDeleteMedia({type, payload}: PayloadAction<number[]>) {
    yield put(toggleModalLoading());
    const count = payload.length;
    let p: any = [];
    let response = yield call([mediaManagerInstance, mediaManagerInstance.bulkDeleteImage], payload);
    if (response) {
        yield put(resetCount());
        toast(`${count} Media successfully deleted`);
    }
    yield put(toggleModalLoading());
    yield put(toggleModal());
}

function* onBulkDeleteMediaByFilter() {
    yield put(toggleModalLoading());
    let response = yield call([mediaManagerInstance, mediaManagerInstance.bulkDeleteByFilter]);
    if (response) {
        yield put(resetCount());
        toast('Media successfully deleted');
    }
    yield put(toggleModalLoading());
    yield put(toggleModal());
}




function* onDeleteMedia({type, payload}: PayloadAction<number>) {
    yield put(toggleModalLoading());
    let response = yield call([mediaManagerInstance, mediaManagerInstance.deleteImage], payload);
    yield put(toggleModalLoading());
    if (response) {
        const count = yield refreshMediaCache()
        yield put(setCount(count));
        yield put(toggleModal());
        yield put(replace('/images'));
        toast('Media successfully deleted');
    } else {
        toast.error('There was an error deleting the media');
    }
}

function* onGoToCameraMedia({ type, payload }: PayloadAction<number>) {
    mediaManagerInstance.criteria.filters = [{ field: 'camera', values: [payload], operation: 'in' }];
    yield* retrieveCountSaga();
    yield call([mediaManagerInstance, mediaManagerInstance.getThumbnails], 0, 25);
    const thumbnail = yield call([mediaManagerInstance, mediaManagerInstance.getThumbnail], 0)
    yield* setCurrentMediaSaga(thumbnail.mediaID, 0 );
    yield put(push(`/images/${thumbnail.mediaID}`));
}

function* onGoToNextMedia() {
    const state = yield select();
    const { currentMedia, nextMedia } = state.media;
    yield put(setCurrentMedia({mediaID: nextMedia.mediaID, index: currentMedia.index + 1}))
}

function* onGoToNextMediaWithBuffer({type, payload}: PayloadAction<number>) {
    const state = yield select();
    const { index } = state.media.currentMedia;
    const { count } = state.gallery;
    yield* onGoToNextMedia();
    for (let i = index + 1; (i <= index + payload) && (i != count + 1);  i++) {
        let thumbnail = yield call([mediaManagerInstance, mediaManagerInstance.getThumbnail], i);
        if (!thumbnail) yield call([mediaManagerInstance, mediaManagerInstance.getThumbnails], i, i);
    }
}

function* onGoToPreviousMedia() {
    const state = yield select();
    const { currentMedia, previousMedia } = state.media;
    yield put(setCurrentMedia({mediaID: previousMedia.mediaID, index: currentMedia.index - 1}))
}

function* onGoToPreviousMediaWithBuffer({type, payload}: PayloadAction<number>) {
    const state = yield select();
    const { index } = state.media.currentMedia;
    yield* onGoToPreviousMedia();
    for (let i = index - 1; (i >= index - payload) && (i >= 0);  i--) {
        let thumbnail = yield call([mediaManagerInstance, mediaManagerInstance.getThumbnail], i);
        if (!thumbnail) yield call([mediaManagerInstance, mediaManagerInstance.getThumbnails], i, i);
    }
}

function* onInitializeSlideshow() {
    try {
        yield* retrieveCountSaga();
        yield call([mediaManagerInstance, mediaManagerInstance.getThumbnails], 0, 0);
        const thumbnail = yield call([mediaManagerInstance, mediaManagerInstance.getThumbnail], 0)
        yield put(setCurrentMedia({mediaID: thumbnail.mediaID, index: 0}));
    } catch(err) {console.log(err)}
}

interface NavigateToCameraLatestPayload {
    cameraID: number;
    mediaID: number;
}
function* onNavigateToCameraLatest({ payload: { cameraID, mediaID } }: PayloadAction<NavigateToCameraLatestPayload>) {
    if (!(yield select(backgroundLoadingSelector))) yield put(toggleLoading(['background']));
    try {
        let criteria = cloneDeep(mediaManagerInstance.criteria);
        let cameraFilter: MediaFilterIn = find(criteria.filters, f => f.field == 'camera') as MediaFilterIn;
        if (!cameraFilter) {
            cameraFilter = new MediaFilterIn('camera', [cameraID]);
            criteria.filters.push(cameraFilter);
        }
        else cameraFilter.values = [cameraID];

        yield* setFilterCriteria(criteria);
        yield* setCurrentMediaSaga(mediaID,  0);
        yield put(push(`/images/${mediaID}`))
    }
    finally {
        if (yield select(backgroundLoadingSelector)) yield put(toggleLoading(['background']));
    }
    
}

function* onRateCurrentMedia({type, payload}: PayloadAction<number>) {
    try {
        const state: State = yield select();
        const result = yield* rateMedia(state.media.currentMedia.mediaID, payload);
        if (result) yield put(setCurrentMediaRating(payload))
    } catch(err) {
        console.log(err);
        toast.error('There was an error')
    }
}

function* onRateMedia({type, payload}: PayloadAction<{mediaID: number, rating: number}>) {
    const { mediaID, rating } = payload;
    yield* rateMedia(mediaID, rating);
}

function* onRetainMedia({ type, payload }: PayloadAction<{ mediaID: number, retain: boolean }>) {
    const { mediaID, retain } = payload;
    yield call([mediaManagerInstance, mediaManagerInstance.retainMedia], mediaID, retain);
    const fullSize = yield call([mediaManagerInstance, mediaManagerInstance.getFullsize], mediaID);
    yield put(setThumbnail(fullSize));
}

function* onSetCurrentMedia({type, payload}: PayloadAction<{mediaID: number, index: number}>) {
    const {mediaID, index} = payload;
    yield put.resolve(setCurrentMediaID(mediaID));
    yield put.resolve(setCurrentMediaIndex(index));
    if (mediaID) {
        yield* getAndSetThumbnail({mediaID, index})
    }
}

function* rateMedia(mediaID: number, rating: number) {
    return yield call([mediaManagerInstance, mediaManagerInstance.rateMedia], mediaID, rating);
}

function* refreshMediaCache() {
    return yield call([mediaManagerInstance, mediaManagerInstance.refreshCache]);
}

function* setCurrentMediaSaga(mediaID: number, index: number) {
    yield put.resolve(setCurrentMediaID(mediaID));
    yield put.resolve(setCurrentMediaIndex(index));
    yield* getAndSetThumbnail({mediaID, index})
}

function* setFilterCriteria(criteria: MediaCriteria) {
    mediaManagerInstance.criteria = criteria;
    yield put(setCount(0))
    const count = yield call([mediaManagerInstance, mediaManagerInstance.getTotalMediaCount])
    yield put(setCount(count));
}

export function* watchMedia() {
   
    yield takeEvery(Actions.GET_ACCOUNT_FILTER_INFO, getAccountFilterInfo);
    yield takeEvery(Actions.ADD_TAG, addTag);
    yield takeEvery(Actions.BULK_DELETE_MEDIA, onBulkDeleteMedia);
    yield takeEvery(Actions.BULK_DELETE_MEDIA_BY_FILTER, onBulkDeleteMediaByFilter)
    yield takeEvery(Actions.DELETE_MEDIA, onDeleteMedia);
    yield takeEvery(Actions.REMOVE_TAG, removeTag);
    yield takeEvery(Actions.REQUEST_FULL_RES, requestFullRes);
    yield takeEvery(Actions.RETAIN_MEDIA, onRetainMedia);
    yield takeEvery(Actions.INITIALIZE_SLIDESHOW, onInitializeSlideshow);
    yield takeEvery(Actions.RATE_MEDIA, onRateMedia);
    yield takeEvery(Actions.RATE_CURRENT_MEDIA, onRateCurrentMedia);
    yield takeEvery(Actions.NAVIGATE_TO_CAMERA_LATEST, onNavigateToCameraLatest);

    yield takeLatest(Actions.SET_CURRENT_MEDIA, onSetCurrentMedia);
    yield takeLatest(Actions.GO_TO_CAMERA_MEDIA, onGoToCameraMedia);
    yield takeLatest(Actions.GO_TO_NEXT_MEDIA, onGoToNextMedia);
    yield takeLatest(Actions.GO_TO_PREVIOUS_MEDIA, onGoToPreviousMedia);
    yield takeLatest(Actions.GO_TO_NEXT_MEDIA_WITH_BUFFER, onGoToNextMediaWithBuffer);
    yield takeLatest(Actions.GO_TO_PREVIOUS_MEDIA_WITH_BUFFER, onGoToPreviousMediaWithBuffer);
}