import * as React from 'react';
import { Glyphicon } from 'react-bootstrap';
import { defineMessages, injectIntl, InjectedIntlProps } from 'react-intl'

import { IUIState } from '../../reducers/ui';

interface HeaderPictureOptionsProps {
    selectedMedia: number[];
    onClear: ()=>void,
    onDelete: (mediaIDs: number[]) => void
    setModalContent: ({title, message, onConfirm}: IUIState['modal']) => void;
    
}

const appStrings = defineMessages({
    rate: {
        id: "HeaderPictureOptions.rate.label",
        defaultMessage: "Rate"
    },
    selected: {
        id: "HeaderPictureOptions.selected.label",
        defaultMessage: "Selected"
    },
    selectAll: {
        id: 'HeaderPictureOptions.selectAll.label',
        defaultMessage: "Select All"
    },
    delete: {
        id: "HeaderPictureOptions.delete.label",
        defaultMessage: "Delete"
    },
    clear: {
        id: "HeaderPictureOptions.clear.label",
        defaultMessage: 'Clear'
    }
});

const HeaderPictureOptions = ({selectedMedia, onClear, onDelete, setModalContent, intl}: HeaderPictureOptionsProps & InjectedIntlProps) => {
    const { formatMessage } = intl;
    return (
        <div style={{display: 'flex', flex: 1, justifyContent: 'space-between'}}>
            <div style={{display: 'flex'}}>
                <div className='header-action' style={{width: '190px'}}>
                    <h6>{`${selectedMedia.length} ${formatMessage(appStrings.selected)}`}</h6>
                </div>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
            }}>
                <div className='header-action clickable'>
                    <Glyphicon glyph='star'/>
                    <h6>{formatMessage(appStrings.rate)}</h6>
                </div>
                <div className='header-action clickable' onClick={() => setModalContent({
                    title: 'Delete Media',
                    message: `Are you sure you want to delete ${selectedMedia.length > 1 ? 'these' : 'this'} ${selectedMedia.length > 1 ? selectedMedia.length : ''} item${selectedMedia.length > 1 ? 's' : ''}?`,
                    onConfirm: () => onDelete(selectedMedia)
                })}>
                    <Glyphicon glyph='trash' />
                    <h6>{formatMessage(appStrings.delete)}</h6>
                </div>
                <div className='header-action_spacer' />
                <div className='header-action clickable' onClick={() => onClear()} >
                    <Glyphicon glyph='remove' />
                    <h6>{formatMessage(appStrings.clear)}</h6>
                </div>
            </div>
        </div>
    )
}

export default injectIntl(HeaderPictureOptions);