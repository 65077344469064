import { createSelector } from 'reselect';
import { compact, get } from 'lodash';
import * as moment from 'moment'

import { CameraHardwareType, ICameraModel, ICameraDataPlanModel, IDataPlanModel, ICameraDataPlan } from '../../Models';
import { State } from '..';
import cameras, { getCameraDetails } from '.';

const getCameras = (state: State) => state.cameras.cameras;

export const getMBCameras = createSelector(
    [getCameras],
    (cameras: ICameraModel[]): ICameraModel[] => {
        return cameras.filter(camera => camera.hardwareType == CameraHardwareType.CellucoreLive && camera.active)
    }
)

export const getTNCameras = createSelector(
    [getCameras],
    (cameras: ICameraModel[]): ICameraModel[] => {
        return cameras.filter(camera => camera.hardwareType != CameraHardwareType.CellucoreLive && camera.active)
    }
)

export const getNonAggressorCameras = createSelector(
    [getCameras],
    (cameras: ICameraModel[]): ICameraModel[] => {
        return cameras.filter(camera => camera.hardwareType != CameraHardwareType.Aggressor && camera.active)
    }
)

export const getAggressorCameras = createSelector(
    [getCameras],
    (cameras: ICameraModel[]): ICameraModel[] => {
        return cameras.filter(camera => camera.hardwareType == CameraHardwareType.Aggressor && camera.active)
    }
)

export const getImpulseCameras = createSelector(
    [getCameras],
    (cameras: ICameraModel[]): ICameraModel[] => {
        return cameras.filter(camera => camera.hardwareType == CameraHardwareType.Impulse && camera.active)
    }
)

export const getCellucore30Cameras = createSelector(
    [getCameras],
    (cameras: ICameraModel[]): ICameraModel[] => {
        return cameras.filter(camera => camera.hardwareType == CameraHardwareType.Cellucore30 && camera.active)
    }
)

export const getCellucore24Cameras = createSelector(
    [getCameras],
    (cameras: ICameraModel[]): ICameraModel[] => {
        return cameras.filter(camera => camera.hardwareType == CameraHardwareType.Cellucore24 && camera.active)
    }
)

export const getCellucore20Cameras = createSelector(
    [getCameras],
    (cameras: ICameraModel[]): ICameraModel[] => {
        return cameras.filter(camera => camera.hardwareType == CameraHardwareType.Cellucore20 && camera.active)
    }
)

export const getCellucore20SolarCameras = createSelector(
    [getCameras],
    (cameras: ICameraModel[]): ICameraModel[] => {
        return cameras.filter(camera => camera.hardwareType == CameraHardwareType.Cellucore20Solar && camera.active)
    }
)

export const getCellucoreLiveCameras = createSelector(
    [getCameras],
    (cameras: ICameraModel[]): ICameraModel[] => {
        return cameras.filter(camera => camera.hardwareType == CameraHardwareType.CellucoreLive && camera.active)
    }
)

export const getCellucoreDualSimCameras = createSelector(
    [getCameras],
    (cameras: ICameraModel[]): ICameraModel[] => {
        return cameras.filter(camera => camera.hardwareType == CameraHardwareType.Cellucore20Dual && camera.active)
    }
)


const getCurrentDataPlans = createSelector(
    [getCameras],
    (cameras: ICameraModel[]): IDataPlanModel[] => {
        return compact(cameras.map(camera => {
            if (get(camera, 'dataPlans', []).length > 0) {
                const currentDataPlan = camera.dataPlans[0];
                if (moment().isBetween(moment.utc(currentDataPlan.startDate), moment.utc(currentDataPlan.endDate))) {
                    return currentDataPlan;
                }
            }
        }))
    }
);


export const getCurrentCameraDataPlans = createSelector(
    [getCameras],
    (cameras: ICameraModel[]): ICameraDataPlanModel[] => {
        return compact(cameras.map(camera => {
            if (get(camera, 'dataPlans', []).length > 0) {
                let currentDataPlan, i = 0;
                while (!currentDataPlan && i < camera.dataPlans.length ) {
                    let dataPlan = camera.dataPlans[i];
                    if (moment().isBetween(moment.utc(dataPlan.startDate), moment.utc(dataPlan.endDate)) && dataPlan.totalBytes > 0) {
                        currentDataPlan = dataPlan;        
                    }
                    i++;
                }
                if (currentDataPlan) {
                    return {
                        cameraID: camera.cameraID,
                        cameraName: camera.cameraName,
                        totalBytes: currentDataPlan.totalBytes,
                        usedBytes: currentDataPlan.usedBytes,
                        startDate: currentDataPlan.startDate,
                        endDate: currentDataPlan.endDate,
                        imageCredits: currentDataPlan.imageCredits,
                        videoCredits: currentDataPlan.videoCredits
                    } as ICameraDataPlanModel
                }
            }
        }))
    }
)

export const getCurrentMonthlyCameraDataPlans = createSelector(
    [getCurrentCameraDataPlans],
    (currentDataPlans: ICameraDataPlanModel[]): ICameraDataPlanModel[] => {
        return currentDataPlans.filter(cdp => moment(cdp.endDate).diff(cdp.startDate, 'days') <= 31)
    }
)

export const getCurrentCameraThumbnailPlans = createSelector(
    [getCameras],
    (cameras: ICameraModel[]): ICameraDataPlanModel[] => {
        return compact(cameras.map(camera => {
            if (get(camera, 'dataPlans', []).length > 0) {
                let currentDataPlan, i = 0;
                while (!currentDataPlan && i < camera.dataPlans.length ) {
                    let dataPlan = camera.dataPlans[i];
                    if (moment().isBetween(moment.utc(dataPlan.startDate), moment.utc(dataPlan.endDate)) && dataPlan.totalThumbnails > 0) {
                        currentDataPlan = dataPlan;        
                    }
                    i++;
                }
                if (currentDataPlan) {
                    return {
                        cameraID: camera.cameraID,
                        cameraName: camera.cameraName,
                        totalThumbnails: currentDataPlan.totalThumbnails,
                        remainingThumbnails: currentDataPlan.remainingThumbnails,
                        startDate: currentDataPlan.startDate,
                        endDate: currentDataPlan.endDate,
                        imageCredits: currentDataPlan.imageCredits,
                        videoCredits: currentDataPlan.videoCredits
                    } as ICameraDataPlanModel
                }
            }
        }))
    }
)

export const getCurrentCameraUnlimitedPlans = createSelector(
    [getCameras],
    (cameras: ICameraModel[]): ICameraDataPlanModel[] => {
        return compact(cameras.map(camera => {
            const dataplans = get(camera, 'dataPlans', new Array<IDataPlanModel>());
            if (dataplans.length > 0) {
                const currentDataPlan = dataplans.find((dp) => moment().isBetween(moment.utc(dp.startDate), moment.utc(dp.endDate)) && dp.unlimited);
                if (currentDataPlan) {
                    return {
                        cameraID: camera.cameraID,
                        cameraName: camera.cameraName,
                        unlimited: true,
                        startDate: currentDataPlan.startDate,
                        endDate: currentDataPlan.endDate,
                        imageCredits: currentDataPlan.imageCredits,
                        videoCredits: currentDataPlan.videoCredits
                    } as ICameraDataPlanModel
                }
            }
        }))
    }
)

export const getCurrentPlanForCamera = (cameraId: number) => {
    return createSelector(
        [getCameras],
        (cameras: ICameraModel[]): IDataPlanModel => {
            const camera = cameras.find(c => c.cameraID == cameraId);
            let currentPlan: IDataPlanModel = null;
            if (camera && camera.dataPlans) {
                currentPlan = camera.dataPlans.find((dp) => moment().isBetween(moment.utc(dp.startDate), moment.utc(dp.endDate)));
            }

            return currentPlan;
        }
    )
}

export const getCurrentMonthlyCameraThumbnailPlans = createSelector(
    [getCurrentCameraThumbnailPlans],
    (currentDataPlans: ICameraDataPlanModel[]): ICameraDataPlanModel[] => {
        return currentDataPlans.filter(cdp => moment(cdp.endDate).diff(cdp.startDate, 'days') <= 31)
    }
)

export const getCurrentMonthlyCameraUnlimitedPlans = createSelector(
    [getCurrentCameraUnlimitedPlans],
    (currentDataPlans: ICameraDataPlanModel[]): ICameraDataPlanModel[] => {
        return currentDataPlans.filter(cdp => moment(cdp.endDate).diff(cdp.startDate, 'days') <= 31)
    }
)

export const getCurrentAnnualPlans = createSelector(
    [getCurrentDataPlans],
    (currentDataPlans: IDataPlanModel[]): ICameraDataPlanModel[] => {
        return currentDataPlans
            .filter(cdp => moment(cdp.endDate).diff(cdp.startDate, 'days') > 31)
            .map(cdp => ({
                cameraID: cdp.cameraID,
                cameraName: cdp.cameraName,
                totalThumbnails: cdp.totalThumbnails,
                remainingThumbnails: cdp.remainingThumbnails,
                totalBytes: cdp.totalBytes,
                usedBytes: cdp.usedBytes,
                startDate: cdp.startDate,
                endDate: cdp.endDate,
                imageCredits: cdp.imageCredits,
                videoCredits: cdp.videoCredits
            } as ICameraDataPlanModel))
    }
)


export const getInactiveCameras = createSelector(
    [getCameras],
    (cameras: ICameraModel[]): ICameraModel[] => cameras.filter(camera => !camera.active)
)

export const getStartAndEndDate = createSelector(
    [getCurrentDataPlans],
    (dataPlans: IDataPlanModel[]): { startDate: string, endDate: string } => {
        let cdp = dataPlans.filter(plan => plan.costPaid > 0 && plan.startDate);
        if (cdp.length > 0) {
            let dp = cdp.reduce((prev, curr) => {
                return  prev == null || moment(curr.startDate).isBefore(prev.startDate) 
                        ? moment(curr.endDate).diff(curr.startDate, 'days') > 31 ? prev : curr
                       : moment(prev.endDate).diff(prev.startDate, 'days') <= 31 ? prev : null
            }, cdp[0]);
            if (!dp) {
                // If dp is null try to get one that is less than or equal to 31 days
                dp = dataPlans.reduce((prev, curr) => {
                    return prev == null || moment(curr.startDate).isBefore(prev.startDate)
                        ? moment(curr.endDate).diff(curr.startDate, 'days') > 31 ? prev : curr
                        : moment(prev.endDate).diff(prev.startDate, 'days') <= 31 ? prev : null
                }, cdp[0]);

                if (!dp) {dp = cdp[0]}
            }
            return { startDate: dp.startDate, endDate: dp.endDate }
        } else if (dataPlans.length > 0) {
            const dp = dataPlans.reduce((prev, curr) => moment(curr.startDate).isBefore(prev.startDate) ? curr : prev, dataPlans[0]);
            return { startDate: dp.startDate, endDate: dp.endDate }
        }
        return { startDate: '', endDate: '' };
    }
)

export const getDaysRemaining = createSelector(
    [getStartAndEndDate],
    ({ startDate, endDate }: { startDate: string, endDate: string }): number => {
        return moment(endDate).diff(moment(), 'days');
    }
)

export const getTotalDays = createSelector(
    [getStartAndEndDate],
    ({ startDate, endDate }: { startDate: string, endDate: string }): number => {
        return moment(endDate).diff(moment(startDate), 'days')
    }
)

export const getTotalBytes = createSelector(
    [getCurrentCameraDataPlans],
    (currentDataPlans: ICameraDataPlanModel[]): number => {
        return currentDataPlans.reduce((prev, curr) => prev += curr.totalBytes, 0)
    }
)

export const getTotalUsedBytes = createSelector(
    [getCurrentCameraDataPlans],
    (currentDataPlans: ICameraDataPlanModel[]): number => {
        return currentDataPlans.reduce((prev, curr) => prev += curr.usedBytes, 0)
    }
)

export const getTotalThumbnails = createSelector(
    [getCurrentCameraThumbnailPlans],
    (currentDataPlans: ICameraDataPlanModel[]): number => {
        return currentDataPlans.reduce((prev, curr) => prev += curr.totalThumbnails, 0)
    }
)

export const getTotalRemainingThumbnails = createSelector(
    [getCurrentCameraThumbnailPlans],
    (currentDataPlans: ICameraDataPlanModel[]): number => {
        return currentDataPlans.reduce((prev, curr) => prev += curr.remainingThumbnails, 0)
    }
)