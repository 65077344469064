import * as React from 'react';
import * as _ from 'lodash';

const icons: { [key: string]: Array<string> } = {
    moon: ['M774.271 816.295c41.831-41.831 74.948-92.378 95.864-148.153-214.386 80.177-451.431-29.631-531.609-244.017-34.86-92.378-34.86-195.214 0-287.591-214.386 78.434-324.194 317.222-244.017 531.609s317.222 324.194 531.609 244.017c55.775-19.173 106.322-52.289 148.153-95.864zM650.031 976.1c-249.397 93.271-525.964-34.038-619.463-284.045-93.414-249.78 34.431-528.143 284.503-619.632l139.932-51.195-52.607 139.408c-28.998 76.844-28.998 162.543 0.071 239.576 67.168 179.601 265.208 270.762 443.754 203.989l140.505-52.547-52.672 140.458c-24.078 64.209-62.158 122.963-111.021 171.963-49.528 51.439-109.227 90.128-173.003 112.025z'],
    sun: [
        "M512 732.444c-121.748 0-220.444-98.696-220.444-220.444s98.696-220.444 220.444-220.444c121.748 0 220.444 98.696 220.444 220.444s-98.696 220.444-220.444 220.444zM512 675.556c90.329 0 163.556-73.226 163.556-163.556s-73.226-163.556-163.556-163.556c-90.329 0-163.556 73.226-163.556 163.556s73.226 163.556 163.556 163.556z",
        "M540.444 170.667v-170.667h-56.889v170.667z",
        "M540.444 1024v-170.667h-56.889v170.667z",
        "M853.333 540.444h170.667v-56.889h-170.667z",
        "M0 540.444h170.667v-56.889h-170.667z",
        "M773.18 291.047l121.6-121.6-40.227-40.227-121.6 121.6z",
        "M169.447 894.78l121.6-121.6-40.227-40.227-121.6 121.6z",
        "M732.953 773.18l121.6 121.6 40.227-40.227-121.6-121.6z",
        "M129.22 169.447l121.6 121.6 40.227-40.227-121.6-121.6z"
    ],
    moon_fq: [
        "M512 960c247.424 0 448-200.576 448-448s-200.576-448-448-448c-247.424 0-448 200.576-448 448s200.576 448 448 448zM512 1024c-282.77 0-512-229.23-512-512s229.23-512 512-512c282.77 0 512 229.23 512 512s-229.23 512-512 512z",
        "M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512v-1024z"
    ],
    moon_wancre: [
        "M512 1024c-282.77 0-512-229.23-512-512s229.23-512 512-512c282.77 0 512 229.23 512 512s-229.23 512-512 512zM852.883 823.559c46.742-46.742 83.746-103.222 107.117-165.545-239.553 89.589-504.425-33.109-594.014-272.662-38.952-103.222-38.952-218.13 0-321.352-239.553 87.641-362.251 354.461-272.662 594.014s354.461 362.251 594.014 272.662c62.323-21.423 118.803-58.428 165.545-107.117z"
    ],
    moon_wangib: [
        "M178.769 877.814c-50.081-50.081-89.728-110.595-114.769-177.37 256.664 95.988 540.456-35.474 636.444-292.138 41.734-110.595 41.734-233.711 0-344.306 256.664 93.902 388.126 379.78 292.138 636.444s-379.78 388.126-636.444 292.138c-66.774-22.954-127.289-62.601-177.37-114.769z",
        "M512 960c247.424 0 448-200.576 448-448s-200.576-448-448-448c-247.424 0-448 200.576-448 448s200.576 448 448 448zM512 1024c-282.77 0-512-229.23-512-512s229.23-512 512-512c282.77 0 512 229.23 512 512s-229.23 512-512 512z"
    ],
    moon_waxgib: [
        "M512 960c247.424 0 448-200.576 448-448s-200.576-448-448-448c-247.424 0-448 200.576-448 448s200.576 448 448 448zM512 1024c-282.77 0-512-229.23-512-512s229.23-512 512-512c282.77 0 512 229.23 512 512s-229.23 512-512 512z",
        "M845.231 877.814c50.081-50.081 89.728-110.595 114.769-177.37-256.664 95.988-540.456-35.474-636.444-292.138-41.734-110.595-41.734-233.711 0-344.306-256.664 93.902-388.126 379.78-292.138 636.444s379.78 388.126 636.444 292.138c66.774-22.954 127.289-62.601 177.37-114.769z"
    ],
    moon_lq: [
        "M512 64c-247.424 0-448 200.576-448 448s200.576 448 448 448c247.424 0 448-200.576 448-448s-200.576-448-448-448zM512 0c282.77 0 512 229.23 512 512s-229.23 512-512 512c-282.77 0-512-229.23-512-512s229.23-512 512-512z",
        "M512 1024c282.77 0 512-229.23 512-512s-229.23-512-512-512v1024z"
    ],
    moon_full: [
        "M512 960c247.424 0 448-200.576 448-448s-200.576-448-448-448c-247.424 0-448 200.576-448 448s200.576 448 448 448zM512 1024c-282.77 0-512-229.23-512-512s229.23-512 512-512c282.77 0 512 229.23 512 512s-229.23 512-512 512z"
    ],
    moon_waxcre: [
        "M512 1024c-282.77 0-512-229.23-512-512s229.23-512 512-512c282.77 0 512 229.23 512 512s-229.23 512-512 512zM171.117 823.559c46.742 48.69 103.222 85.694 165.545 107.117 239.553 89.589 504.425-33.109 594.014-272.662s-33.109-506.373-272.662-594.014c38.952 103.222 38.952 218.13 0 321.352-89.589 239.553-354.461 362.251-594.014 272.662 23.371 62.323 60.375 118.803 107.117 165.545z",
        "M512 992c265.097 0 480-214.903 480-480s-214.903-480-480-480c-265.097 0-480 214.903-480 480s214.903 480 480 480zM148.033 845.72l0.457 0.466c-50.431-50.431-89.697-110.922-114.452-176.936l-24.69-65.84 65.862 24.631c222.484 83.206 469.24-30.38 552.866-253.988 36.204-95.941 36.204-202.815 0-298.756l-24.659-65.347 65.593 23.998c256.335 93.781 387.414 379.185 291.64 635.275-95.935 256.522-379.5 387.052-634.389 291.715-66.653-22.912-127.58-62.461-178.227-115.218z"
    ],
    moon_new: [
        "M1024 512c0 282.77-229.23 512-512 512s-512-229.23-512-512c0-282.77 229.23-512 512-512s512 229.23 512 512z",
        "M992 512c0 265.097-214.903 480-480 480s-480-214.903-480-480c0-265.097 214.903-480 480-480s480 214.903 480 480z"
    ],
    compass: [
        "M578.181 578.181c17.161-17.164 26.961-40.409 27.393-64.552l200.021-272.135c4.832-6.571 4.14-15.69-1.627-21.46-5.772-5.767-14.893-6.463-21.46-1.627l-272.133 200.017c-24.389 0.414-47.258 10.106-64.554 27.397-17.161 17.164-26.961 40.409-27.393 64.552l-200.021 272.135c-4.832 6.571-4.14 15.69 1.627 21.46 3.2 3.198 7.43 4.836 11.685 4.836 3.418 0 6.849-1.055 9.775-3.209l272.133-200.017c24.387-0.412 47.256-10.106 64.554-27.397zM509.098 572.491c-3.808-0.176-7.518 0.945-10.568 3.189l-189.466 139.257 139.257-189.466c2.246-3.052 3.369-6.783 3.189-10.568-0.81-17.020 5.629-33.686 17.666-45.725 11.44-11.438 26.648-17.738 42.821-17.738 0.969 0 1.94 0.024 2.905 0.068 3.799 0.185 7.516-0.947 10.568-3.189l189.466-139.257-139.257 189.466c-2.246 3.052-3.369 6.783-3.189 10.568 0.81 17.020-5.629 33.686-17.666 45.725-12.127 12.125-28.606 18.474-45.725 17.67z",
        "M952.43 494.383h-77.683c-3.028-62.957-22.204-123.818-56.009-176.996-4.893-7.699-15.102-9.969-22.799-5.078-7.699 4.893-9.971 15.1-5.078 22.799 33.581 52.821 51.33 113.99 51.33 176.892 0 182.069-148.123 330.19-330.19 330.19-62.902 0-124.074-17.752-176.897-51.332-7.692-4.893-17.906-2.621-22.799 5.078-4.895 7.697-2.621 17.906 5.078 22.799 53.49 34.006 114.752 53.215 178.101 56.071v77.624c0 9.124 7.393 16.516 16.516 16.516s16.516-7.393 16.516-16.516v-77.597c88.108-3.964 168.141-39.46 229.059-95.457l23.812 23.812c3.226 3.224 7.454 4.836 11.68 4.836s8.454-1.612 11.68-4.836c6.45-6.452 6.45-16.908 0-23.358l-23.871-23.871c55.369-60.969 90.317-140.783 93.999-228.544h77.555c9.124 0 16.516-7.393 16.516-16.516s-7.393-16.516-16.516-16.516z",
        "M512 494.383c-9.714 0-17.617 7.904-17.617 17.617s7.904 17.617 17.617 17.617c9.714 0 17.617-7.904 17.617-17.617s-7.904-17.617-17.617-17.617z",
        "M874.038 149.962c-96.705-96.703-225.278-149.962-362.038-149.962s-265.333 53.259-362.038 149.962c-96.703 96.705-149.962 225.278-149.962 362.038s53.259 265.333 149.962 362.038c96.705 96.703 225.278 149.962 362.038 149.962s265.333-53.259 362.038-149.962c96.703-96.705 149.962-225.278 149.962-362.038s-53.259-265.333-149.962-362.038zM512 990.968c-264.104 0-478.968-214.864-478.968-478.968s214.864-478.968 478.968-478.968c264.104 0 478.968 214.864 478.968 478.968s-214.864 478.968-478.968 478.968z",
        "M228.055 711.682c7.699-4.893 9.971-15.1 5.078-22.799-33.576-52.819-51.323-113.983-51.323-176.883 0-182.069 148.123-330.19 330.19-330.19 62.902 0 124.074 17.752 176.897 51.332 7.688 4.891 17.903 2.621 22.799-5.078 4.895-7.697 2.621-17.906-5.078-22.799-53.49-34.006-114.752-53.215-178.101-56.071v-77.624c0-9.124-7.393-16.516-16.516-16.516s-16.516 7.393-16.516 16.516v77.597c-87.326 3.929-166.729 38.82-227.443 93.959l-23.871-23.871c-6.452-6.448-16.908-6.448-23.358 0-6.45 6.452-6.45 16.908 0 23.358l23.812 23.812c-55.763 60.665-91.206 140.281-95.415 227.958h-77.639c-9.124 0-16.516 7.393-16.516 16.516s7.393 16.516 16.516 16.516h77.566c2.68 63.737 21.92 125.393 56.12 179.189 3.147 4.95 8.489 7.659 13.953 7.659 3.030 0 6.1-0.835 8.846-2.581z"
    ],
    star: [
        "M551.385 827.077l-324.096 170.387 61.897-360.886-262.199-255.581 362.35-52.653 162.048-328.345 162.048 328.345 362.35 52.653-262.199 255.581 61.897 360.886z"
    ],
    pencil: [
        "M300.343 835.721l422.452-424.347-110.17-110.17-424.347 422.452-68.963 181.027 181.027-68.963zM329.558 893.078l-320.874 122.238 122.238-320.874 7.327-11.286 474.478-472.36 200.477 200.477-472.36 474.478-11.286 7.327z",
        "M907.151 218.7c27.712-29.032 27.712-75.179 1.052-101.851-29.032-27.712-75.179-27.712-102.371-0.52l-56.577 56.577 101.841 101.841 56.055-56.047zM658.745 172.906l101.832-101.832c52.012-52.012 137.872-52.012 192.349 0 52.012 52.012 52.012 137.872 0 192.349l-101.832 101.832-192.349-192.349z"
    ],
    battery0: [
        "M1433.582 921.6v-819.2h-1141.029c-104.594 0-190.171 92.16-190.171 204.8v409.6c0 112.64 85.577 204.8 190.171 204.8h1141.029zM1535.982 1024h-1243.429c-162.913 0-292.571-139.632-292.571-307.2v-409.6c0-167.568 129.658-307.2 292.571-307.2h1243.429v1024z",
        "M1535.982 307.2v409.6h102.4v-409.6h-102.4zM1433.582 204.8h307.2v614.4h-307.2v-614.4z"
    ],
    battery1: [
        "M1433.6 921.6v-819.2h-1141.029c-104.594 0-190.171 92.16-190.171 204.8v409.6c0 112.64 85.577 204.8 190.171 204.8h1141.029zM1536 1024h-1243.429c-162.913 0-292.571-139.632-292.571-307.2v-409.6c0-167.568 129.658-307.2 292.571-307.2h1243.429v1024z",
        "M1536 307.2v409.6h102.4v-409.6h-102.4zM1433.6 204.8h307.2v614.4h-307.2v-614.4z",
        "M307.2 307.2h102.4v409.6h-102.4v-409.6z"
    ],
    battery2: [
        "M1433.6 921.6v-819.2h-1141.029c-104.594 0-190.171 92.16-190.171 204.8v409.6c0 112.64 85.577 204.8 190.171 204.8h1141.029zM1536 1024h-1243.429c-162.913 0-292.571-139.632-292.571-307.2v-409.6c0-167.568 129.658-307.2 292.571-307.2h1243.429v1024z",
        "M1536 307.2v409.6h102.4v-409.6h-102.4zM1433.6 204.8h307.2v614.4h-307.2v-614.4z",
        "M307.2 307.2h102.4v409.6h-102.4v-409.6z",
        "M512 307.2h102.4v409.6h-102.4v-409.6z"
    ],
    battery3: [
        "M1433.6 921.6v-819.2h-1141.029c-104.594 0-190.171 92.16-190.171 204.8v409.6c0 112.64 85.577 204.8 190.171 204.8h1141.029zM1536 1024h-1243.429c-162.913 0-292.571-139.632-292.571-307.2v-409.6c0-167.568 129.658-307.2 292.571-307.2h1243.429v1024z",
        "M1536 307.2v409.6h102.4v-409.6h-102.4zM1433.6 204.8h307.2v614.4h-307.2v-614.4z",
        "M307.2 307.2h102.4v409.6h-102.4v-409.6z",
        "M512 307.2h102.4v409.6h-102.4v-409.6z",
        "M716.8 307.2h102.4v409.6h-102.4v-409.6z"
    ],
    battery4: [
        "M1433.6 921.6v-819.2h-1141.029c-104.594 0-190.171 92.16-190.171 204.8v409.6c0 112.64 85.577 204.8 190.171 204.8h1141.029zM1536 1024h-1243.429c-162.913 0-292.571-139.632-292.571-307.2v-409.6c0-167.568 129.658-307.2 292.571-307.2h1243.429v1024z",
        "M1536 307.2v409.6h102.4v-409.6h-102.4zM1433.6 204.8h307.2v614.4h-307.2v-614.4z",
        "M307.2 307.2h102.4v409.6h-102.4v-409.6z",
        "M512 307.2h102.4v409.6h-102.4v-409.6z",
        "M716.8 307.2h102.4v409.6h-102.4v-409.6z",
        "M921.6 307.2h102.4v409.6h-102.4v-409.6z"
    ],
    battery5: [
        "M1433.6 921.6v-819.2h-1141.029c-104.594 0-190.171 92.16-190.171 204.8v409.6c0 112.64 85.577 204.8 190.171 204.8h1141.029zM1536 1024h-1243.429c-162.913 0-292.571-139.632-292.571-307.2v-409.6c0-167.568 129.658-307.2 292.571-307.2h1243.429v1024z",
        "M1536 307.2v409.6h102.4v-409.6h-102.4zM1433.6 204.8h307.2v614.4h-307.2v-614.4z",
        "M307.2 307.2h102.4v409.6h-102.4v-409.6z",
        "M512 307.2h102.4v409.6h-102.4v-409.6z",
        "M716.8 307.2h102.4v409.6h-102.4v-409.6z",
        "M921.6 307.2h102.4v409.6h-102.4v-409.6z",
        "M1126.4 307.2h102.4v409.6h-102.4v-409.6z"
    ],
    list: [
        "M0 0h219.429v146.286h-219.429v-146.286z",
        "M365.714 0h950.857v146.286h-950.857v-146.286z",
        "M0 438.857h219.429v146.286h-219.429v-146.286z",
        "M365.714 438.857h950.857v146.286h-950.857v-146.286z",
        "M0 877.714h219.429v146.286h-219.429v-146.286z",
        "M365.714 877.714h950.857v146.286h-950.857v-146.286z"
    ],
    grid: [
        "M0 127.988c0-70.734 57.099-127.988 127.988-127.988h192.025c70.734 0 127.988 57.099 127.988 127.988v192.025c0 70.734-57.099 127.988-127.988 127.988h-192.025c-70.734 0-127.988-57.099-127.988-127.988v-192.025z",
        "M576 127.988c0-70.734 57.099-127.988 127.988-127.988h192.025c70.734 0 127.988 57.099 127.988 127.988v192.025c0 70.734-57.099 127.988-127.988 127.988h-192.025c-70.734 0-127.988-57.099-127.988-127.988v-192.025z",
        "M0 703.988c0-70.734 57.099-127.988 127.988-127.988h192.025c70.734 0 127.988 57.099 127.988 127.988v192.025c0 70.734-57.099 127.988-127.988 127.988h-192.025c-70.734 0-127.988-57.099-127.988-127.988v-192.025z",
        "M576 703.988c0-70.734 57.099-127.988 127.988-127.988h192.025c70.734 0 127.988 57.099 127.988 127.988v192.025c0 70.734-57.099 127.988-127.988 127.988h-192.025c-70.734 0-127.988-57.099-127.988-127.988v-192.025z"
    ],
    exit: [
        "M321.961 136.112c-174.774 58.088-295.013 221.552-295.013 408.207 0 237.738 193.047 430.443 431.158 430.443s431.158-192.705 431.158-430.443c0-186.655-120.239-350.118-295.013-408.207-14.123-4.694-29.377 2.95-34.071 17.073s2.95 29.377 17.073 34.071c152.932 50.829 258.117 193.827 258.117 357.063 0 207.951-168.896 376.548-377.263 376.548s-377.263-168.597-377.263-376.548c0-163.236 105.185-306.234 258.117-357.063 14.123-4.694 21.767-19.948 17.073-34.071s-19.948-21.767-34.071-17.073z",
        "M431.158 0h53.895v431.158h-53.895v-431.158z"
    ],
    profile: [
        "M742.265 290.024c33.851 26.151 35.592 74.035-2.193 138.834-3.54 6.066-5.915 7.919-12.207 12.009-1.706 1.109-3.454 2.174-6.56 4.037 7.96-4.812 0.614 0.328 3.63-9.76-25.383 85.459-113.227 190.634-212.935 190.634s-187.552-105.175-212.92-190.586c3.001 10.040-4.345 4.9 4.114 10.013-3.604-2.164-5.352-3.23-7.058-4.338-6.292-4.090-8.668-5.943-12.213-12.017-37.78-64.79-36.039-112.674-2.188-138.825 1.112-0.859 2.224-1.667 3.332-2.427 0.212-24.94 2.798-52.849 9.255-80.255 0.739-3.137 1.525-6.233 2.392-9.405 5.288-19.019 18.308-45.477 42.494-71.755 39.881-43.331 97.132-69.293 172.791-69.293s132.91 25.962 172.791 69.293c24.186 26.278 37.207 52.736 42.528 71.876 0.834 3.052 1.62 6.147 2.359 9.284 6.457 27.406 9.043 55.315 9.255 80.255 1.108 0.76 2.22 1.568 3.332 2.427zM332.072 396.187c3.932 2.383 5.739 3.52 7.758 4.933 7.771 5.438 10.926 8.312 13.77 17.826 19.035 64.086 90.744 149.943 158.4 149.943s139.366-85.857 158.415-149.991c2.83-9.466 5.984-12.341 13.756-17.778 2.019-1.413 3.825-2.55 7.206-4.6 1.341-0.808 1.991-1.2 2.537-1.53 21.828-38.811 20.967-54.233 13.572-59.946-1.351-1.044-2.746-1.569-2.284-1.487-14.171-2.527-24.22-15.241-23.406-29.612 0.016-0.259 0.016-0.259 0.105-2.699 0.739-23.962-1.114-53.346-7.596-80.857-0.587-2.493-1.208-4.939-1.83-7.215-3.068-11.036-12.235-29.662-29.542-48.467-29.276-31.809-71.441-50.93-130.932-50.93s-101.656 19.121-130.932 50.93c-17.308 18.805-26.474 37.431-29.509 48.346-0.655 2.396-1.276 4.842-1.863 7.335-6.482 27.511-8.335 56.895-7.596 80.857 0.089 2.439 0.089 2.439 0.105 2.699 0.815 14.371-9.235 27.085-23.406 29.612 0.462-0.082-0.933 0.443-2.284 1.487-7.396 5.713-8.256 21.137 13.572 59.946 0.546 0.33 1.196 0.722 1.986 1.197z",
        "M928.567 876.374c-26.899 4.685-57.949 9.378-92.712 13.764-99.485 12.55-208.607 20.085-323.855 20.085s-224.369-7.534-323.855-20.085c-34.763-4.385-65.813-9.079-92.712-13.764-2.958-0.515-5.745-1.009-8.358-1.478 13.026-132.014 79.156-199.268 203.689-238.536 23.167-7.306 44.752-16.258 64.733-26.502 35.042-17.964 59.232-36.238 72.326-49.198 11.165-11.051 11.258-29.061 0.207-40.226s-29.061-11.258-40.226-0.207c-1.914 1.894-6.486 5.889-13.652 11.302-12.41 9.375-27.301 18.832-44.607 27.704-17.198 8.817-35.835 16.546-55.89 22.871-153.227 48.315-236.884 140.409-245.178 314.592-0.662 13.898 8.828 26.235 22.43 29.161 6.259 1.346 17.992 3.639 34.765 6.561 27.758 4.834 59.686 9.661 95.353 14.16 101.743 12.835 213.218 20.532 330.975 20.532s229.232-7.697 330.975-20.532c35.667-4.499 67.596-9.326 95.353-14.16 16.773-2.921 28.506-5.214 34.765-6.561 13.603-2.926 23.092-15.263 22.43-29.161-8.294-174.182-91.952-266.277-245.18-314.593-20.053-6.325-38.69-14.054-55.888-22.87-17.306-8.872-32.197-18.329-44.607-27.704-7.165-5.413-11.738-9.407-13.652-11.302-11.165-11.051-29.175-10.959-40.226 0.207s-10.959 29.175 0.207 40.226c13.093 12.959 37.284 31.234 72.326 49.198 19.981 10.243 41.567 19.195 64.731 26.501 124.535 39.268 190.665 106.522 203.691 238.536-2.613 0.47-5.4 0.963-8.358 1.478z",
        "M714.252 600.125c-9.125 17.772-19.658 34.373-31.507 49.6-93.202 119.774-240.655 119.774-333.857-0-15.038-19.326-27.951-40.858-38.552-64.167-6.503-14.3-23.368-20.621-37.668-14.117s-20.621 23.368-14.117 37.668c12.413 27.295 27.621 52.654 45.439 75.553 115.978 149.044 307.673 149.044 423.651 0 14.056-18.063 26.492-37.664 37.217-58.552 7.175-13.975 1.663-31.121-12.311-38.296s-31.121-1.663-38.296 12.311z",
        "M483.556 768v170.667c0 15.709 12.735 28.444 28.444 28.444s28.444-12.735 28.444-28.444v-170.667c0-15.709-12.735-28.444-28.444-28.444s-28.444 12.735-28.444 28.444z"
    ],
    data: [
        "M259.524 467.542l29.077 358.41c2.574 31.733 47.378 35.919 55.786 5.212l102.597-374.703 102.597 374.703c8.33 30.423 52.614 26.694 55.739-4.694l36.082-362.391 37.329 391.951c3.106 32.617 49.814 34.992 56.213 2.858l60.458-303.603h171.709c15.709 0 28.444-12.735 28.444-28.444s-12.735-28.444-28.444-28.444h-195.048c-13.568 0-25.247 9.583-27.897 22.889l-27.174 136.459-46.645-489.774c-3.263-34.256-53.211-34.363-56.621-0.121l-49.809 500.26-89.499-326.866c-7.642-27.91-47.227-27.91-54.869 0l-87.323 318.919-30.267-373.070c-2.612-32.195-48.35-35.811-55.989-4.427l-59.73 245.413h-129.352c-15.709 0-28.444 12.735-28.444 28.444s12.735 28.444 28.444 28.444h151.704c13.118 0 24.535-8.972 27.638-21.718l23.294-95.706z"
    ],
    cart: [
        "M146.996 134.737l184.781 489.552c8.685 28.639 36.403 49.395 67.896 49.395h446.151c32.979 0 61.606-22.719 69.526-54.922l80.923-329.018c3.554-14.452-5.28-29.049-19.732-32.604s-29.049 5.28-32.604 19.732l-80.923 329.018c-2.027 8.243-9.154 13.899-17.191 13.899h-446.151c-7.668 0-14.552-5.155-16.912-12.885l-198.501-526.062h-130.365c-14.883 0-26.947 12.065-26.947 26.947s12.065 26.947 26.947 26.947h93.101z",
        "M458.105 943.158c-59.53 0-107.789-48.259-107.789-107.789s48.259-107.789 107.789-107.789c59.53 0 107.789 48.259 107.789 107.789s-48.259 107.789-107.789 107.789zM458.105 889.263c29.765 0 53.895-24.129 53.895-53.895s-24.129-53.895-53.895-53.895c-29.765 0-53.895 24.129-53.895 53.895s24.129 53.895 53.895 53.895z",
        "M835.368 943.158c-59.53 0-107.789-48.259-107.789-107.789s48.259-107.789 107.789-107.789c59.53 0 107.789 48.259 107.789 107.789s-48.259 107.789-107.789 107.789zM835.368 889.263c29.765 0 53.895-24.129 53.895-53.895s-24.129-53.895-53.895-53.895c-29.765 0-53.895 24.129-53.895 53.895s24.129 53.895 53.895 53.895z",
        "M269.474 404.211h646.737c14.883 0 26.947-12.065 26.947-26.947s-12.065-26.947-26.947-26.947h-646.737c-14.883 0-26.947 12.065-26.947 26.947s12.065 26.947 26.947 26.947z"
    ],
    bell: [
        "M881.778 720.543c0-43.596-18.205-86.302-50.713-116.384l-78.276-80.351c-39.885-43.208-62.536-99.002-62.536-157.473v-95.364c0-103.172-80.351-185.637-180.148-185.637s-180.148 82.466-180.148 185.637v93.418c0 60.245-22.041 115.928-63.064 158.030l-77.22 79.237c-30.357 32.886-47.449 74.988-47.449 118.887v47.457h739.556v-47.457zM938.667 720.543v104.346h-853.333v-104.346c0-58.471 22.651-114.265 63.064-158.030l77.748-79.794c30.537-31.341 46.921-72.731 46.921-118.33v-93.418c0-134.265 105.486-242.526 237.037-242.526s237.037 108.262 237.037 242.526v95.364c0 43.899 17.093 86 46.921 118.33l76.676 78.75c43.243 39.943 67.929 97.854 67.929 157.129z",
        "M312.889 796.444v42.667c0 88.178 91.022 156.444 199.111 156.444s199.111-68.267 199.111-156.444v-42.667h-56.889v42.667c0 52.622-62.578 99.556-142.222 99.556s-142.222-46.933-142.222-99.556v-42.667h-56.889z"
    ],
    creditCard: [
        "M882.439 824.889c30.796 0 56.227-26.456 56.227-59.642v-506.495c0-33.185-25.431-59.642-56.227-59.642h-740.879c-30.796 0-56.227 26.456-56.227 59.642v506.495c0 33.185 25.431 59.642 56.227 59.642h740.879zM882.439 881.778h-740.879c-62.729 0-113.116-52.419-113.116-116.53v-506.495c0-64.112 50.387-116.53 113.116-116.53h740.879c62.729 0 113.116 52.419 113.116 116.53v506.495c0 64.112-50.387 116.53-113.116 116.53z",
        "M56.889 426.667h910.222c15.709 0 28.444-12.735 28.444-28.444s-12.735-28.444-28.444-28.444h-910.222c-15.709 0-28.444 12.735-28.444 28.444s12.735 28.444 28.444 28.444z",
        "M538.771 654.222h321.255c15.709 0 28.444-12.735 28.444-28.444s-12.735-28.444-28.444-28.444h-321.255c-15.709 0-28.444 12.735-28.444 28.444s12.735 28.444 28.444 28.444z"
    ],
    filter: [
        "M606.655 1027.38l-189.311-155.121v-14.259l0.011-204.679c-1.899-70.954-36.010-136.535-92.006-178.81l-272.157-181.353-26.227-263.040h970.169l-26.349 256.491-272.858 186.889c-57.727 43.518-91.273 108.986-91.273 179.018v374.865zM546.42 652.515c0-89.192 42.616-172.363 116.357-227.914l250.941-171.913 16.676-162.336h-836.889l16.799 168.481 249.876 166.564c72.003 54.279 114.997 136.937 117.4 227.117v191.226l68.84 56.408v-247.634z"
    ],
    cross: [
        "M491.338 387.899l-387.899-387.899-103.44 103.44 387.899 387.899-387.899 387.899 103.44 103.44 387.899-387.899 387.899 387.899 103.44-103.44-387.899-387.899 387.899-387.899-103.44-103.44-387.899 387.899z"
    ]
};

interface TCIconProps {
    icon: string,
    width: number,
    height: number,
}

export const TCIcon = (props: TCIconProps & React.SVGProps<SVGSVGElement>): JSX.Element => {
    let paths = _.map(icons[props.icon], (path: string, index: number) => (<path key={index} d={path}></path>));
    return (
        <svg {...props} viewBox="0 0 1024 1024">
            {paths}
        </svg>
    );
};
