export class AutoOrder {
    /**
    * Unique code assigned to this auto order
    */
    'autoOrderCode': string;
    /**
    * Auto order object identifier
    */
    'autoOrderOid': number;
    /**
    * Cancel this auto order after X additional rebills
    */
    'cancelAfterNextXOrders': number;
    /**
    * True if the auto order was canceled because the customer purchased a downgrade item
    */
    'cancelDowngrade': boolean;
    /**
    * True if the auto order was canceled because the customer purchased an upgrade item
    */
    'cancelUpgrade': boolean;
    /**
    * The user that canceled the auto order
    */
    'canceledByUser': string;
    /**
    * The date/time that the auto order was canceled
    */
    'canceledDts': string;
    /**
    * The number of credit card attempts that have taken place
    */
    'creditCardAttempt': number;
    /**
    * The date/time the auto order was disabled due to failed rebills
    */
    'disabledDts': string;
    /**
    * True if this auto order is enabled
    */
    'enabled': boolean;
    /**
    * The reason this auto order failed during the last rebill attempt
    */
    'failureReason': string;
    /**
    * The items that are setup to rebill
    */
    'items': Array<AutoOrderItem>;
    /**
    * The next time that the auto order will be attempted for processing
    */
    'nextAttempt': string;
    /**
    * The original order id that this auto order is associated with.
    */
    'originalOrderId': string;
    /**
    * Override the affiliate id given credit for rebills of this auto order
    */
    'overrideAffiliateId': number;
    /**
    * The RTG code associated with this order for future rebills
    */
    'rotatingTransactionGatewayCode': string;
}

export class AutoOrderItem {
    /**
    * Arbitrary item id that should be rebilled instead of the normal schedule
    */
    'arbitraryItemId': string;
    /**
    * An arbitrary percentage discount to provide on future rebills
    */
    'arbitraryPercentageDiscount': number;
    /**
    * Arbitrary quantity to rebill
    */
    'arbitraryQuantity': number;
    /**
    * The number of days to rebill if the frequency is set to an arbitrary number of days
    */
    'arbitraryScheduleDays': number;
    /**
    * Arbitrary unit cost that rebills of this item should occur at
    */
    'arbitraryUnitCost': number;
    /**
    * The number of rebills to give the arbitrary unit cost on before reverting to normal pricing.
    */
    'arbitraryUnitCostRemainingOrders': number;
    /**
    * Primary key of AutoOrderItem
    */
    'autoOrderItemOid': number;
    /**
    * Frequency of the rebill if not a fixed schedule
    */
    'frequency': AutoOrderItem.FrequencyEnum;
    /**
    * Date/time of the last order of this item
    */
    'lastOrderDts': string;
    /**
    * The life time value of this item including the original purchase
    */
    'lifeTimeValue': number;
    /**
    * The date/time of when the next pre-shipment notice should be sent
    */
    'nextPreshipmentNoticeDts': string;
    /**
    * Date/time that this item is scheduled to rebill
    */
    'nextShipmentDts': string;
    /**
    * Date/time after which no additional rebills of this item should occur
    */
    'noOrderAfterDts': string;
    /**
    * The number of times this item has rebilled
    */
    'numberOfRebills': number;
    /**
    * Options associated with this item
    */
    'options': Array<AutoOrderItemOption>;
    /**
    * The original item id purchased
    */
    'originalItemId': string;
    /**
    * The original quantity purchased
    */
    'originalQuantity': number;
    /**
    * The PayPal Payer ID tied to this item
    */
    'paypalPayerId': string;
    /**
    * The PayPal Profile ID tied to this item
    */
    'paypalRecurringPaymentProfileId': string;
    /**
    * True if the preshipment notice associated with the next rebill has been sent
    */
    'preshipmentNoticeSent': boolean;
    /**
    * The value of the rebills of this item
    */
    'rebillValue': number;
    /**
    * The number of rebills remaining before this item is complete
    */
    'remainingRepeatCount': number;
}

export namespace AutoOrderItem {
    export enum FrequencyEnum {
        Weekly = <any>'Weekly',
        Biweekly = <any>'Biweekly',
        Every_ = <any>'Every...',
        Every10Days = <any>'Every 10 Days',
        Every24Days = <any>'Every 24 Days',
        Every28Days = <any>'Every 28 Days',
        Monthly = <any>'Monthly',
        Every45Days = <any>'Every 45 Days',
        Every2Months = <any>'Every 2 Months',
        Every3Months = <any>'Every 3 Months',
        Every4Months = <any>'Every 4 Months',
        Every6Months = <any>'Every 6 Months',
        Yearly = <any>'Yearly'
    }
}
export class AutoOrderItemOption {
    /**
    * Label
    */
    'label': string;
    /**
    * Value
    */
    'value': string;
}

export class AutoOrderResponse {
    'autoOrder': AutoOrder;
    'error': Error;
    'metadata': ResponseMetadata;
    /**
    * Indicates if API call was successful
    */
    'success': boolean;
}

export class AutoOrdersResponse {
    'autoOrders': Array<AutoOrder>;
    'error': Error;
    'metadata': ResponseMetadata;
    /**
    * Indicates if API call was successful
    */
    'success': boolean;
}

export class ResponseMetadata {
    /**
    * Payload name
    */
    'payloadName': string;
    'resultSet': ResultSet;
}

export class ResultSet {
    /**
    * Number of results in this set
    */
    'count': number;
    /**
    * Maximum number of results that can be returned in a set
    */
    'limit': number;
    /**
    * True if there are more results to query
    */
    'more': boolean;
    /**
    * The next offset that you should query to retrieve more results
    */
    'nextOffset': number;
    /**
    * Offset of this result set (zero based)
    */
    'offset': number;
    /**
    * The total number of records in the result set.  May be null if the number is not known and the client should continue iterating as long as more is true.
    */
    'totalRecords': number;
}

export class Cart {
    affiliate?: CartAffiliate;
    /**
    * The ISO-4217 three letter base currency code of the account
    */
    baseCurrencyCode?: string;
    billing?: CartBilling;
    buysafe?: CartBuysafe;
    /**
    * Unique identifier for this cart
    */
    cart_id: string;
    checkout?: CartCheckout;
    /**
    * Coupons
    */
    coupons?: Array<CartCoupon>;
    /**
    * The ISO-4217 three letter currency code the customer is viewing prices in
    */
    currencyCode?: string;
    customerProfile?: CartCustomerProfile;
    /**
    * The exchange rate if the customer is viewing a different currency than the base
    */
    exchangeRate?: number;
    gift?: CartGift;
    giftCertificate?: CartGiftCertificate;
    /**
    * Items
    */
    items?: Array<CartItem>;
    /**
    * The ISO-631 three letter code the customer would like to checkout with
    */
    languageIsoCode?: string;
    /**
    * True if the customer is logged into their profile
    */
    loggedIn?: boolean;
    marketing?: CartMarketing;
    /**
    * Merchant ID this cart is associated with
    */
    merchantId?: string;
    payment?: CartPayment;
    settings?: CartSettings;
    shipping?: CartShipping;
    summary?: CartSummary;
    taxes?: CartTaxes;
    upsellAfter?: CartUpsellAfter;
}

export class CartAffiliate {
    /**
    * Affiliate id associated with the cart
    */
    affiliateId: number;
    /**
    * Affiliate sub id associated with the cart
    */
    affiliateSubId: string;
}

export class CartBilling {
    /**
    * Address line 1
    */
    address1?: string;
    /**
    * Address line 2
    */
    address2?: string;
    /**
    * CC emails.  Multiple allowed, but total length of all emails can not exceed 100 characters.
    */
    ccEmails?: Array<string>;
    /**
    * City
    */
    city?: string;
    /**
    * Company
    */
    company?: string;
    /**
    * ISO-3166 two letter country code
    */
    countryCode?: string;
    /**
    * Day phone
    */
    dayPhone?: string;
    /**
    * Email
    */
    email?: string;
    /**
    * Email entered for confirmation
    */
    emailConfirm?: string;
    /**
    * Evening phone
    */
    eveningPhone?: string;
    /**
    * First name
    */
    firstName?: string;
    /**
    * Last name
    */
    lastName?: string;
    /**
    * Postal code
    */
    postalCode?: string;
    /**
    * State for United States otherwise region or province for other countries
    */
    stateRegion?: string;
    /**
    * Title
    */
    title?: string;
}

export class CartBuysafe {
    /**
    * True if buySAFE is willing to bond the order
    */
    bondAvailable: boolean;
    bondCost: Currency;
    /**
    * True if the bond is free (merchant paying for it)
    */
    bondFree: boolean;
    /**
    * True if the customer wants the bond
    */
    bondWanted: boolean;
    /**
    * Recommend text to display to the customer
    */
    cartDisplayText: string;
    /**
    * URL associated with the recommended text
    */
    cartDisplayUrl: string;
}

export class CartCheckout {
    /**
    * Comments from the customer.  Rarely used on the single page checkout.
    */
    comments: string;
    /**
    * Custom field 1
    */
    customField1: string;
    /**
    * Custom field 2
    */
    customField2: string;
    /**
    * Custom field 3
    */
    customField3: string;
    /**
    * Custom field 4
    */
    customField4: string;
    /**
    * Custom field 5
    */
    customField5: string;
    /**
    * Custom field 6
    */
    customField6: string;
    /**
    * Custom field 7
    */
    customField7: string;
    /**
    * IP Address
    */
    ipAddress: string;
    /**
    * Screen branding theme code
    */
    screenBrandingThemeCode: string;
}

export class CartCoupon {
    /**
    * Coupon code
    */
    couponCode: string;
}

export class CartCustomerProfile {
    /**
    * True if profile is allowed to bill to their 3rd party shipping account
    */
    allow3rdPartyBilling: boolean;
    /**
    * True if this profile is allowed to use a COD
    */
    allowCod: boolean;
    /**
    * True if this profile is allowed to use a purchase order
    */
    allowPurchaseOrder: boolean;
    /**
    * Billing addresses on file for this profile
    */
    billingAddresses: Array<CartCustomerProfileAddress>;
    /**
    * Credit cards on file for this profile (masked)
    */
    creditCards: Array<CartCustomerProfileCreditCard>;
    /**
    * Unique identifier
    */
    customerProfileOid: number;
    /**
    * DHL account number on file
    */
    dhlAccountNumber: string;
    /**
    * Email
    */
    email: string;
    /**
    * FedEx account number on file
    */
    fedexAccountNumber: string;
    /**
    * True if this profile always qualifies for free shipping
    */
    freeShipping: boolean;
    /**
    * The minimum aount that this profile has to purchase to qualify for free shipping
    */
    freeShippingMinimum: number;
    /**
    * Maximum item count this profile can purchase
    */
    maximumItemCount: number;
    /**
    * Minimum item count this profile must purchase
    */
    minimumItemCount: number;
    /**
    * Minimum subtotal this profile must purchase
    */
    minimumSubtotal: number;
    /**
    * True if this profile is prevented from using coupons
    */
    noCoupons: boolean;
    /**
    * True if this profile is never given free shipping
    */
    noFreeShipping: boolean;
    /**
    * True if this customers orders are not charged in real-time
    */
    noRealtimeCharge: boolean;
    /**
    * Pricing tier names this profile qualifies for
    */
    pricingTiers: Array<string>;
    /**
    * Shipping addresses on file for this profile
    */
    shippingAddresses: Array<CartCustomerProfileAddress>;
    /**
    * True if this profile is exempt from sales tax
    */
    taxExempt: boolean;
    /**
    * UPS account number on file
    */
    upsAccountNumber: string;
}

export class CartCustomerProfileAddress {
    /**
    * Address 1
    */
    address1: string;
    /**
    * Address 2
    */
    address2: string;
    /**
    * City
    */
    city: string;
    /**
    * Company
    */
    company: string;
    /**
    * ISO-3166 Country code
    */
    countryCode: string;
    /**
    * Day phone
    */
    dayPhone: string;
    /**
    * Evening phone
    */
    eveningPhone: string;
    /**
    * First name
    */
    firstName: string;
    /**
    * Last name
    */
    lastName: string;
    /**
    * Unique identifier for this address
    */
    oid: number;
    /**
    * Postal code
    */
    postalCode: string;
    /**
    * State for United States otherwise region or province for other countries
    */
    stateRegion: string;
    /**
    * Tax county if a billing address
    */
    taxCounty: string;
    /**
    * Title
    */
    title: string;
}

export class CartCustomerProfileCreditCard {
    /**
    * Card expiration month (1-12)
    */
    cardExpirationMonth: number;
    /**
    * Card expiration year (four digit)
    */
    cardExpirationYear: number;
    /**
    * Card number (masked last 4 digits)
    */
    cardNumber: string;
    /**
    * Card type
    */
    cardType: CartCustomerProfileCreditCard.CardTypeEnum;
    /**
    * Unique identifier for this stored card
    */
    customerProfileCreditCardId: number;
    /**
    * Last used
    */
    lastUsedDate: string;
}

export namespace CartCustomerProfileCreditCard {
    export enum CardTypeEnum {
        AMEX = <any>'AMEX',
        DinersClub = <any>'Diners Club',
        Discover = <any>'Discover',
        MasterCard = <any>'MasterCard',
        JCB = <any>'JCB',
        VISA = <any>'VISA'
    }
}

export class CartGift {
    /**
    * True if this order is a gift
    */
    gift: boolean;
    giftCharge: Currency;
    /**
    * Email address of the gift recipient
    */
    giftEmail: string;
    /**
    * Message to the gift recipient
    */
    giftMessage: string;
    giftWrapCost: Currency;
    /**
    * Title of the selected gift wrap
    */
    giftWrapTitle: string;
}

export class CartGiftCertificate {
    giftCertificateAmount: Currency;
    /**
    * Gift certificate code
    */
    giftCertificateCode: string;
    giftCertificateRemainingBalanceAfterOrder: Currency;
}

export class CartItem {
    arbitraryUnitCost: Currency;
    /**
    * Attributes
    */
    attributes: Array<CartItemAttribute>;
    /**
    * Auto order schedule the customer selected
    */
    autoOrderSchedule: string;
    /**
    * URL to the default multimedia image
    */
    defaultImageUrl: string;
    /**
    * URL to the default multimedia thumbnail
    */
    defaultThumbnailUrl: string;
    /**
    * Description of the item
    */
    description: string;
    discount: Currency;
    /**
    * Extended description of the item
    */
    extendedDescription: string;
    /**
    * Item ID
    */
    itemId: string;
    /**
    * Item object identifier
    */
    itemOid: number;
    /**
    * True if this item is a kit
    */
    kit: boolean;
    /**
    * Options associated with the kit components
    */
    kitComponentOptions: Array<CartKitComponentOption>;
    manufacturerSuggestedRetailPrice: Currency;
    /**
    * Maximum quantity the customer can purchase
    */
    maximumQuantity: number;
    /**
    * Minimum quantity the customer can purchase
    */
    minimumQuantity: number;
    /**
    * Multimedia
    */
    multimedia: Array<CartItemMultimedia>;
    /**
    * Options
    */
    options: Array<CartItemOption>;
    phsyical: CartItemPhysical;
    /**
    * Position of the item in the cart
    */
    position: number;
    /**
    * True if this item is on pre-order
    */
    preorder: boolean;
    /**
    * quantity
    */
    quantity: number;
    /**
    * Customer selectable auto order schedules
    */
    schedules: Array<string>;
    totalCost: Currency;
    totalCostWithDiscount: Currency;
    unitCost: Currency;
    unitCostWithDiscount: Currency;
    /**
    * True if this item was added to the cart as part of an upsell
    */
    upsell: boolean;
    /**
    * Variations
    */
    variations: Array<CartItemVariationSelection>;
    /**
    * URL to view the product on the site
    */
    viewUrl: string;
}

export class CartItemAttribute {
    /**
    * Name of the attribute
    */
    name: string;
    /**
    * Type of attribute
    */
    type: string;
    /**
    * Value of the attribute
    */
    value: string;
}

export class CartItemMultimedia {
    /**
    * Code assigned to the multimedia
    */
    code: string;
    /**
    * Description
    */
    description: string;
    /**
    * True if the image should be excluded from galleries
    */
    excludeFromGallery: boolean;
    /**
    * Image height
    */
    imageHeight: number;
    /**
    * Image width
    */
    imageWidth: number;
    /**
    * True if the multimedia is the default for this type
    */
    isDefault: boolean;
    /**
    * Thumbnails of the images
    */
    thumbnails: Array<CartItemMultimediaThumbnail>;
    /**
    * Type of multimedia
    */
    type: CartItemMultimedia.TypeEnum;
    /**
    * URL to view multimedia at
    */
    url: string;
}

export namespace CartItemMultimedia {
    export enum TypeEnum {
        Image = <any>'Image',
        PDF = <any>'PDF',
        Text = <any>'Text',
        Unknown = <any>'Unknown',
        Video = <any>'Video'
    }
}
export class CartItemMultimediaThumbnail {
    /**
    * Height in pixels
    */
    height: number;
    /**
    * True if thumbnail is a PNG, otherwise its a JPEG
    */
    png: boolean;
    /**
    * True if the thumbnail is square
    */
    square: boolean;
    /**
    * URL for the thumbnail
    */
    url: string;
    /**
    * Width in pixels
    */
    width: number;
}

export class CartItemOption {
    costIfSpecified: Currency;
    costPerLetter: Currency;
    costPerLine: Currency;
    /**
    * True if the default answer is ignored
    */
    ignoreIfDefault: boolean;
    /**
    * Display label for the option
    */
    label: string;
    /**
    * Name of the option
    */
    name: string;
    /**
    * Charge the fee a single time instead of multiplying by the quantity
    */
    oneTimeFee: boolean;
    /**
    * Unique identifier for the option
    */
    optionOid: number;
    /**
    * True if the customer is required to select a value
    */
    required: boolean;
    /**
    * The value of the option specified by the customer
    */
    selectedValue: string;
    /**
    * Type of option
    */
    type: CartItemOption.TypeEnum;
    /**
    * Values that the customer can select from for radio or select type options
    */
    values: Array<CartItemOptionValue>;
}

export namespace CartItemOption {
    export enum TypeEnum {
        Single = <any>'single',
        Multiline = <any>'multiline',
        Dropdown = <any>'dropdown',
        Hidden = <any>'hidden',
        Radio = <any>'radio',
        Fixed = <any>'fixed'
    }
}
export class CartItemOptionValue {
    additionalCost: Currency;
    additionalWeight: Weight;
    /**
    * True if this is the default value
    */
    defaultValue: boolean;
    /**
    * Display order of the option value
    */
    displayOrder: number;
    /**
    * Value of the option the customer can select
    */
    value: string;
}

export class CartItemPhysical {
    height: Distance;
    length: Distance;
    weight: Weight;
    width: Distance;
}

export class CartItemVariationSelection {
    /**
    * Variation name
    */
    variationName: string;
    /**
    * Variation value
    */
    variationValue: string;
}

export class CartKitComponentOption {
    costIfSpecified: Currency;
    costPerLetter: Currency;
    costPerLine: Currency;
    /**
    * True if the default answer is ignored
    */
    ignoreIfDefault: boolean;
    /**
    * Kit component item id
    */
    itemId: string;
    /**
    * Unique identifier for the kit component item
    */
    itemOid: number;
    /**
    * Display label for the option
    */
    label: string;
    /**
    * Name of the option
    */
    name: string;
    /**
    * Charge the fee a single time instead of multiplying by the quantity
    */
    oneTimeFee: boolean;
    /**
    * Unique identifier for the option
    */
    optionOid: number;
    /**
    * True if the customer is required to select a value
    */
    required: boolean;
    /**
    * The value of the option specified by the customer
    */
    selectedValue: string;
    /**
    * Type of option
    */
    type: CartKitComponentOption.TypeEnum;
    /**
    * Values that the customer can select from for radio or select type options
    */
    values: Array<CartItemOptionValue>;
}

export namespace CartKitComponentOption {
    export enum TypeEnum {
        Single = <any>'single',
        Multiline = <any>'multiline',
        Dropdown = <any>'dropdown',
        Hidden = <any>'hidden',
        Radio = <any>'radio',
        Fixed = <any>'fixed'
    }
}
export class CartMarketing {
    /**
    * The advertising source the customer indicated
    */
    advertisingSource: string;
    /**
    * True if the customer agrees to receiving marketing emails
    */
    mailingListOptIn: boolean;
}

export class CartPayment {
    amazon?: CartPaymentAmazon;
    check?: CartPaymentCheck;
    creditCard?: CartPaymentCreditCard;
    /**
    * Payment method
    */
    paymentMethod?: string;
    purchaseOrder?: CartPaymentPurchaseOrder;
    /**
    * Rotating transaction gateway code
    */
    rtgCode?: string;
}

export class CartPaymentAmazon {
    /**
    * Amazon order reference id
    */
    amazonOrderReferenceId: string;
}

export class CartPaymentCheck {
    /**
    * Check number they are paying with
    */
    checkNumber: number;
}

export class CartPaymentCreditCard {
    /**
    * Card expiration month (1-12)
    */
    cardExpirationMonth?: number;
    /**
    * Card expiration year (four digit year)
    */
    cardExpirationYear?: number;
    /**
    * Card number (masked to the last 4)
    */
    cardNumber?: string;
    /**
    * Hosted field token for the card number
    */
    cardNumberToken?: string;
    /**
    * Card type
    */
    cardType?: string;
    /**
    * Card verification number (masked)
    */
    cardVerificationNumber?: string;
    /**
    * Hosted field token for the card verification number
    */
    cardVerificationNumberToken?: string;
    /**
    * ID of the stored credit card to use
    */
    customerProfileCreditCardId?: number;
    /**
    * True if the customer wants to store the card on their profile for future re-use
    */
    storeCreditCard?: boolean;
}

export class CartPaymentPurchaseOrder {
    /**
    * Purchase order number
    */
    purchaseOrderNumber: string;
}

export class CartResponse {
    'cart': Cart;
    /**
    * Errors that should be displayed to the customer
    */
    'errors': Array<string>;
}

export class CartSettings {
    billing: CartSettingsBilling;
    gift: CartSettingsGift;
    payment: CartSettingsPayment;
    shipping: CartSettingsShipping;
    taxes: CartSettingsTaxes;
    terms: CartSettingsTerms;
}

export class CartSettingsBilling {
    /**
    * Provinces
    */
    provinces: Array<CartSettingsProvince>;
}

export class CartSettingsGift {
    /**
    * True if this checkout supports gift giving
    */
    allowGifts: boolean;
    giftCharge: Currency;
    /**
    * The gift wraps available for the customer to select from
    */
    giftWraps: Array<CartSettingsGiftWrap>;
    /**
    * The maximum length of the gift message the giver can enter
    */
    maxMessageLength: number;
}

export class CartSettingsGiftWrap {
    cost: Currency;
    /**
    * Title of the gift wrap
    */
    title: string;
    /**
    * URL for the sample of the gift wrap
    */
    url: string;
}

export class CartSettingsPayment {
    amazon: CartSettingsPaymentAmazon;
    creditCard: CartSettingsPaymentCreditCard;
    /**
    * True if this card requires a payment from the customer
    */
    needPayment: boolean;
    paypal: CartSettingsPaymentPayPal;
    /**
    * True if Amazon payments are available on this order
    */
    supportsAmazon: boolean;
    /**
    * True if check payments are available on this order
    */
    supportsCheck: boolean;
    /**
    * True if COD payments are available on this order
    */
    supportsCod: boolean;
    /**
    * True if credit card payments are available on this order
    */
    supportsCreditCard: boolean;
    /**
    * True if money order payments are available on this order
    */
    supportsMoneyOrder: boolean;
    /**
    * True if PayPal payments are available on this order
    */
    supportsPaypal: boolean;
    /**
    * True if purchase order payments are available on this order
    */
    supportsPurchaseOrder: boolean;
    /**
    * True if quote requests payments are available on this order
    */
    supportsQuoteRequest: boolean;
    /**
    * True if wire transfer payments are available on this order
    */
    supportsWireTransfer: boolean;
}

export class CartSettingsPaymentAmazon {
    /**
    * Amazon button URL
    */
    amazonButtonUrl: string;
    /**
    * Amazon merchant ID
    */
    amazonMerchantId: string;
    /**
    * Amazon widget URL
    */
    amazonWidgetUrl: string;
}

export class CartSettingsPaymentCreditCard {
    /**
    * True if the credit card verification number should be collected
    */
    collectCreditCardVerificationNumber: boolean;
    /**
    * Available credit card types
    */
    creditCardTypes: Array<string>;
    /**
    * The shoppingCartToken needed for proper initialization of hosted fields collection
    */
    hostedFieldsShoppingCartToken: string;
}

export class CartSettingsPaymentPayPal {
    /**
    * PayPal button alt text
    */
    paypalButtonAltText: string;
    /**
    * PayPal button URL
    */
    paypalButtonUrl: string;
    /**
    * PayPal Credit button URL
    */
    paypalCreditButtonUrl: string;
    /**
    * PayPal Credit legal image URL
    */
    paypalCreditLegalImageUrl: string;
    /**
    * PayPal Credit legal URL
    */
    paypalCreditLegalUrl: string;
}

export class CartSettingsProvince {
    code: string;
    province: string;
}

export class CartSettingsShipping {
    deliverOnDate: CartSettingsShippingCalendar;
    /**
    * Estimates for this cart
    */
    estimates: Array<CartSettingsShippingEstimate>;
    /**
    * True if this order needs shipping
    */
    needShipping: boolean;
    /**
    * Provinces
    */
    provinces: Array<CartSettingsProvince>;
    shipOnDate: CartSettingsShippingCalendar;
}

export class CartSettingsShippingCalendar {
    /**
    * Specified dates that are blacked out on the calendar
    */
    blackouts: string;
    /**
    * Days of week that should be enabled on the calendar (0 - Sunday through 6 - Saturday)
    */
    daysOfWeek: Array<boolean>;
    /**
    * The earliest date that can be selected on the calendar
    */
    earliest: string;
    /**
    * True if the customer is required to select a date
    */
    require: boolean;
    /**
    * True if this calendar should be shown to the customer
    */
    show: boolean;
}

export class CartSettingsShippingEstimate {
    /**
    * True if this method allows the customer to use their own shipper account number
    */
    allow3rdPartyBilling: boolean;
    /**
    * Comment to display to the customer about this method
    */
    comment: string;
    cost: Currency;
    costBeforeDiscount: Currency;
    /**
    * True if this is the default method
    */
    defaultMethod: boolean;
    discount: Currency;
    /**
    * True if this method is discounted because of a coupon
    */
    discounted: boolean;
    /**
    * The name to display to the customer
    */
    displayName: string;
    /**
    * Date of the estimated delivery (or range)
    */
    estimatedDelivery: string;
    /**
    * True if a lift gate option for this method should be offered to the customer
    */
    liftGateOption: boolean;
    /**
    * Shipping method name
    */
    name: string;
    tax: Currency;
    totalTax: Currency;
}

export class CartSettingsTaxes {
    counties: Array<string>;
}

export class CartSettingsTerms {
    /**
    * HTML version of the terms
    */
    html: string;
    /**
    * Text version of the terms.
    */
    text: string;
}

export class CartShipping {
    /**
    * Address line 1
    */
    address1: string;
    /**
    * Address line 2
    */
    address2: string;
    /**
    * City
    */
    city: string;
    /**
    * Company
    */
    company: string;
    /**
    * ISO-3166 two letter country code
    */
    countryCode: string;
    /**
    * Day time phone
    */
    dayPhone: string;
    /**
    * Date the customer is requesting delivery on. Typically used for perishable product delivery.
    */
    deliveryDate: string;
    /**
    * Evening phone
    */
    eveningPhone: string;
    /**
    * First name
    */
    firstName: string;
    /**
    * Last name
    */
    lastName: string;
    /**
    * Lift gate requested (LTL shipping methods only)
    */
    liftGate: boolean;
    /**
    * Postal code
    */
    postalCode: string;
    /**
    * Date the customer is requesting that the order ship on.  Typically used for perishable product delivery.
    */
    shipOnDate: string;
    /**
    * True if the shipping adress is residential.  Effects the methods that are available to the customer as well as the price of the shipping method.
    */
    shipToResidential: boolean;
    /**
    * 3rd party account number to ship against for UPS or FedEx
    */
    shipping3rdPartyAccountNumber: string;
    /**
    * Shipping method
    */
    shippingMethod: string;
    /**
    * Special instructions from the customer regarding shipping
    */
    specialInstructions: string;
    /**
    * State/Region
    */
    stateRegion: string;
    /**
    * Title
    */
    title: string;
}

export class CartSummary {
    arbitraryShippingHandlingTotal: Currency;
    arbitraryTax: Currency;
    arbitraryTaxRate: Currency;
    arbitraryTaxableSubtotal: Currency;
    shippingHandling: Currency;
    shippingHandlingDiscount: Currency;
    shippingHandlingWithDiscount: Currency;
    subtotal: Currency;
    subtotalDiscount: Currency;
    subtotalWithDiscount: Currency;
    surcharge: Currency;
    tax: Currency;
    taxableSubtotal: Currency;
    taxableSubtotalDiscount: Currency;
    taxableSubtotalWithDiscount: Currency;
    total: Currency;
}

export class CartTaxes {
    /**
    * Tax county if the state requires it.
    */
    county: string;
    /**
    * True if tax exempt
    */
    exempt: boolean;
    /**
    * Tax rate
    */
    rate: number;
}

export class CartUpsellAfter {
    /**
    * The date/time after which the cart will finalize into an order.
    */
    finalizeAfterDts: string;
    /**
    * The amount of inactivity in minutes after which the cart should be finalized into an order.  This will calculate the finalize_after_dts field.
    */
    finalizeAfterMinutes: number;
    /**
    * Upsell path code
    */
    upsellPathCode: string;
}

export class Currency {
    /**
    * Value localized to the customer
    */
    localized: number;
    /**
    * Value localized and formatted for the customer
    */
    localizedFormatted: string;
    /**
    * Value in base currency
    */
    value: number;
}

export class Customer {
    billing: CustomerBilling[];
    cards: CustomerCard[];
}

export class CustomerBilling {
    address1: string;
    city: string;
    country_code: string;
    customer_billing_oid: number;
    customer_profile_oid: number;
    first_name: string;
    last_name: string;
    last_used_dts: string;
    postal_code: string;
    state_region: string;
}

export class CustomerCard {
    card_expiration_month: number;
    card_expiration_year: number;
    card_number: string;
    card_type: string;
    customer_profile_credit_card_id: number;
    customer_profile_oid: number;
    last_used_dts: string;
}

export class Distance {
    /**
    * Unit of measure
    */
    uom: Distance.UomEnum;
    /**
    * The distance measured in UOM
    */
    value: number;
}

export namespace Distance {
    export enum UomEnum {
        IN = <any>'IN',
        CM = <any>'CM'
    }
}

export class Weight {
    /**
    * Unit of measure
    */
    uom: Weight.UomEnum;
    /**
    * Weight
    */
    value: number;
}

export namespace Weight {
    export enum UomEnum {
        KG = <any>'KG',
        LB = <any>'LB',
        OZ = <any>'OZ'
    }
}

export class Item {
    accounting: any;
    amember: any;
    auto_order: any;
    ccbill: any;
    channelPartnerMappings: any;
    chargeback: any;
    checkout: any;
    content: any;
    creation_dts: any;
    description: any;
    description_translated_text_instance_oid: any;
    digital_delivery: any;
    ebay: any;
    email_notifications: any;
    enrollment123: any;
    gift_certificate: any;
    google_product_search: any;
    identifiers: any;
    inactive: any;
    instant_payment_notifications: any;
    internal: any;
    kit: any;
    kit_definition: any;
    last_modified_dts: any;
    merchant_id: any;
    merchant_item_id: any;
    merchant_item_oid: any;
    options: any;
    parent_category_id: any;
    payment_processing: any;
    physical: any;
    pricing: any;
    realtime_pricing: any;
    related: any;
    reporting: any;
    restriction: any;
    revguard: any;
    reviews: any;
    salesforce: any;
    shipping: any;
    tax: any;
    third_party_email_marketing: any;
    variant_items: any;
    variations: any;
    wishlist_member: any;
}