import { put, takeEvery } from 'redux-saga/effects';

import { Actions, IUIState, setModalContent, toggleModal } from './index';
import { PayloadAction, State } from '../../../Types';

export function* watchUI() {
    yield takeEvery(Actions.SET_MODAL_CONTENT, onSetModalContent);
}

function* onSetModalContent() {
    yield put(toggleModal())
}