import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { StyledTextInput } from '.';

export interface IReduxTextInputProps {
    id?: string;
    placeholder?: string
    shrink?: boolean
    disabled?: boolean
}

export const ReduxTextInput = ({ id, input: { value, onChange }, label, placeholder, shrink, disabled }: IReduxTextInputProps & WrappedFieldProps ) => {
    return (
        <div className={'flex-container-column create-account-input '}>
            <StyledTextInput 
                id={id}
                value={value}
                onChange={onChange}
                name={label}
                label={label}
                placeholder={placeholder}
                shrink={shrink}
                disabled={disabled}
            />
        </div>
    )
}