import * as React from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { toast } from 'react-toastify';
import { connect, Dispatch } from 'react-redux';
import * as request from 'superagent';

import  { StyledTextInput as TextField }  from '../Elements/';
import { isBushnell } from '../../constants';
import { State } from '../../reducers';
import { error } from '../../reducers/sagas';

interface TCForgotPasswordProps {
    goTo: (route: string)=>void
}

interface TCForgotPasswordState {
    email: string
    errorText: string
}

type ITCForgotPasswordAllProps = In & Out & TCForgotPasswordProps & InjectedIntlProps

class TCForgotPassword extends React.Component<ITCForgotPasswordAllProps, TCForgotPasswordState> {
    constructor(props: ITCForgotPasswordAllProps) {
        super(props)

        this.state = {
            email: '',
            errorText: ''
        }
    }

    async sendEmail() {
        const { email } = this.state;
        if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
            try { 
                request
                    .post('api/account/passwordreset')
                    .query({ email: encodeURIComponent(email) })
                
                toast('Password reset email sent');
                this.props.goTo('login')
            }
            catch (err) {
                if (err.status == 400) {
                    this.setState({...this.state, errorText: 'Cannot find email'})
                }
                else {
                    toast.error('There was an error. Please try again')
                    this.props.dError(err);
                }
            }
        } else {
            this.setState({...this.state, errorText: 'Email is not valid'})
        }
    }

    render() {
        const { goTo } = this.props;
        const { email, errorText } = this.state;
        return (
            <div style={{position: 'relative', display: 'flex', flexFlow: 'column nowrap'}}>
                <div 
                    className={"flex-container"} 
                    style={{ 
                        position: 'absolute',
                        top: 0,
                        left: '-50px',
                        alignItems: 'center', 
                        justifyContent: 'space-between', 
                        marginBottom: '10px' 
                    }}>
                    <div className={"flex-container"} style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
                        <a onClick={() => goTo('login')} className='circleButton' ><img src={"/images/chevron-left.svg"} /></a>
                    </div>
                </div>
                <h4 style={{color: isBushnell ? '#333' : '#FFF'}} >Password Reset</h4>
                <TextField 
                    error={!!errorText}
                    label='Email' 
                    value={email} 
                    onChange={(e: any) => this.setState({...this.state, email: e.target.value})}
                    helperText={errorText || 'Enter email associated with account'} />
                <div className='orangeButton' onClick={() => this.sendEmail()} >Submit</div>
            </div>
        )
    }
}

interface In {}

const stateToProps = (state: State):In => ({});

interface Out {
    dError: (err: any) => void;
}

const dToProps = (d: Dispatch<State>): Out => ({
    dError: e => d(error(e))
})

export default connect(stateToProps, dToProps)(injectIntl(TCForgotPassword));