import * as React from 'react';
import * as _ from 'lodash';
import { Image, Glyphicon } from 'react-bootstrap';
import { injectIntl, InjectedIntlProps, defineMessages } from "react-intl";
import { Link } from 'react-router-dom'
import { History, Person, VideoLibrary } from '@material-ui/icons';
import * as request from 'superagent';
import { isBushnell } from '../../constants';

import { TCIcon } from '../Elements';

export interface ITCAccountDropdownProps {
    firstName: string,
    lastName: string,
    email: string;
    onError: (err: any) => void;
}

interface ITCAccountDropdownState {
    showDropdown: boolean,
    support: boolean;
}

const appStrings = defineMessages({
    myAccount: {
        id: "TCImagesHeader.myAccount.label",
        defaultMessage: "My Account"
    },
    dataPlans: {
        id: "TCImagesHeader.dataPlans.label",
        defaultMessage: "Data Plan Usage"
    },
    buyCredits: {
        id: "TCImagesHeader.buyCredits.label",
        defaultMessage: "Buy Credits"
    },
    notifications: {
        id: "TCImagesHeader.notifications.label",
        defaultMessage: "Notifications"
    },
    payment: {
        id: "TCImagesHeader.payment.label",
        defaultMessage: "Payment Information"
    },
    signOut: {
        id: "TCImagesHeader.signOut.label",
        defaultMessage: "Sign Out"
    },
    mySubscription: {
        id: "TCImagesHeader.mySubscription.label",
        defaultMessage: "Subscriptions"
    },
    supplementalItems: {
        id: "TCImagesHeader.supplementalItems",
        defaultMessage: "Supplemental Items",
    },
    orderHistory: {
        id: 'TCImagesHeader.orderHistory',
        defaultMessage: "Billing History"
    },
    billing: {
        id: "TCImagesHeader.billing.label",
        defaultMessage: "Billing Information"
    },
    support: {
        id: "TCImagesHeader.support.label",
        defaultMessage: "Support"
    },
    impulseManual: {
        id: "TCImageHeader.support.impulseManual",
        defaultMessage: "Impulse User Manual"
    },
    impulsePhoneVid: {
        id: "TCImageHeader.support.impulsePhoneVid",
        defaultMessage: "Setting up impulse in Phone app"
    },
    impulseWebVid: {
        id: "TCImageHeader.support.impulseWebVid",
        defaultMessage: "Setting up impulse on Website"
    }
});

class TCAccountDropdown extends React.Component<ITCAccountDropdownProps & InjectedIntlProps, ITCAccountDropdownState> {

    constructor(props: ITCAccountDropdownProps & InjectedIntlProps) {
        super(props);

        this.state = {
            showDropdown: false,
            support: false,
        }
    }

    updateState(values: Object) {
        var newState = _.assign(this.state, values);
        this.setState(newState);
    }

    toggleSupport = () => this.setState({ support: !this.state.support });

    signOut = async() => {
        try {
            const response = await request.post('/Logout');

            if ('redirectURL' in response.body) {
                window.location.href = response.body.redirectURL;
            }
        }
        catch (err) {
            this.props.onError(err);
        }
    }

    supportItemClick = (url: string) => {
        this.updateState({ showDropdown: false, support: true });
        window.open(url, '_blank');
    }

    render(): JSX.Element {
        const { 
            email,
            firstName, 
            intl: { formatMessage },
            lastName
        } = this.props;
        const { support } = this.state; 

        let displayName = (firstName.length || lastName.length) ? `${firstName} ${lastName}` : email;

        let dropDown: JSX.Element = null;
        if (this.state.showDropdown) {
            dropDown = (
                <div className={'flex-container-column accountDropdown-menu'} style={{ justifyContent: 'space-between', backgroundColor: isBushnell ? 'white' : "#62BB46" }}>
                    <Link to={'/Account'} onClick={() => this.updateState({ showDropdown: !this.state.showDropdown })} >
                        <div className={'flex-container'}>
                            <TCIcon icon={'profile'} width={16} height={16} /><div>{formatMessage(appStrings.myAccount)}</div>
                        </div>
                    </Link>
                    <Link to={'/Subscription'} onClick={() => this.updateState({ showDropdown: !this.state.showDropdown })}>
                        <div className={'flex-container'}>
                            <Glyphicon glyph='barcode'/><div>{formatMessage(appStrings.mySubscription)}</div>
                        </div>
                    </Link>
                    <Link to={'/usage'} onClick={() => this.updateState({ showDropdown: !this.state.showDropdown })}>
                        <div className={'flex-container'}>
                            <TCIcon icon={'data'} width={16} height={16} /><div>{formatMessage(appStrings.dataPlans)}</div>
                        </div>
                    </Link>
                    <Link to={'/billing-information'} onClick={() => this.updateState({ showDropdown: !this.state.showDropdown })}>
                        <div className={'flex-container'}>
                            <Glyphicon glyph='credit-card'/><div>{formatMessage(appStrings.billing)}</div>
                        </div>
                    </Link>
                    <Link to={'/order-history'} onClick={() => this.updateState({ showDropdown: !this.state.showDropdown })}>
                        <div className={'flex-container'}>
                            <History style={{ width: 16, height: 16 }} /><div>{formatMessage(appStrings.orderHistory)}</div>
                        </div>
                    </Link>
                    <Link to={'/Account/Notifications'} onClick={() => this.updateState({ showDropdown: !this.state.showDropdown })}>
                        <div className={'flex-container'}>
                            <TCIcon icon={'bell'} width={16} height={16} /><div>{formatMessage(appStrings.notifications)}</div>
                        </div>
                    </Link>
                    <a onClick={this.toggleSupport}>
                        <div className={'flex-container'} style={{ position: 'relative' }}>
                            <VideoLibrary style={{ width: 16, height: 16 }} /><div>{formatMessage(appStrings.support)}</div>
                            {support && 
                            <div
                                className={'flex-container-column accountDropdown-menu'} 
                                style={{ 
                                    position: 'absolute', 
                                    right: '110%', 
                                    bottom: 'auto',
                                    top: 0,
                                    transform: 'none',
                                    borderRadius: '8px',
                                    backgroundColor: isBushnell ? 'white' : "#62BB46" 
                                }}
                            >
                                <a
                                    onClick={() => this.supportItemClick('https://s3.amazonaws.com/tc-support-docs/119900A_119900V_ImpulseCam_1LIM_rev121018_web.pdf')
                                    }>
                                    <div className='flex-container'>
                                        {formatMessage(appStrings.impulseManual)}
                                    </div>
                                </a>
                                <a 
                                    onClick={() => this.supportItemClick('https://www.youtube.com/watch?v=gE5vJY_bSuE')
                                }>
                                    <div className='flex-container'>
                                        {formatMessage(appStrings.impulsePhoneVid)}
                                    </div>
                                </a>
                                <a
                                    onClick={() => this.supportItemClick('https://www.youtube.com/watch?v=HR_BcZopRF4')
                                    }>
                                    <div className='flex-container'>
                                        {formatMessage(appStrings.impulseWebVid)}
                                    </div>
                                </a>
                            </div>}
                        </div>
                    </a>
                    <hr style={{ width: '100%', marginTop: '0px', marginBottom: '0px' }} />
                    <a onClick={() => this.signOut()}>
                        <div className={'flex-container'}>
                            <TCIcon icon={'exit'} width={16} height={16} /><div>{formatMessage(appStrings.signOut)}</div>
                        </div>
                    </a>
                </div>
            );
        }

        return (
            <div 
                className={'flex-container-column accountDropdown center-items ' + (this.state.showDropdown ? 'accountDropdown-focus' : '')} 
                style={{ position: 'relative'}}>
                <a 
                    className='hidden-xs'
                    onClick={() => this.updateState({ showDropdown: !this.state.showDropdown })} >
                    <div className={'flex-container'} style={{alignItems: 'center', justifyContent: 'center', marginTop: 'auto', marginBottom: 'auto' }}>
                        <div className='center-items' style={{ fontWeight: 600, fontSize: '16px', marginLeft: '5px', marginRight: '5px' }}>
                            {(displayName.length) 
                                ? displayName
                                : <Person style={{width: '36px', height: '36px'}} />
                            }
                        </div>
                        <Image src="/images/down_arrow.svg"/>
                    </div>
                </a>
                <Glyphicon 
                    glyph='menu-hamburger'
                    className='hidden-sm hidden-md hidden-lg' 
                    style={{fontSize: '32px'}}
                    onClick={() => this.updateState({ showDropdown: !this.state.showDropdown })} />
                {dropDown}
            </div>
        );
    }
}

export default injectIntl(TCAccountDropdown);