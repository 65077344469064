import { put, takeLatest, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { Actions, api, setMessages, getMessages } from './index';
import { IMessageModel } from '../../Models';
import { PayloadAction } from '../../../Types';
import { error } from '../sagas';


function* onGetMessages() {
    try {
        const res = yield api.getMessages();
        yield put(setMessages(res.body));
    }
    catch(err) {
        yield put(error(err));
        console.log('Error in onGetMessages: ' + err);
    }
}

function* onReadMessage({ type, payload }: PayloadAction<IMessageModel>) {
    try {
        yield api.readMessage(payload);
        yield put(getMessages());
    }
    catch(err) {
        yield put(error(err));
        console.log('Error in onGetMessages: ' + err);
    }
}

export function* watchMessages() {
    yield takeLatest(Actions.GET_MESSAGES, onGetMessages);
    yield takeEvery(Actions.READ_MESSAGE, onReadMessage);
}